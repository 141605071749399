import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    FIND_PROMOTIONS_BY_CAMPAIGN,
    FIND_PROMOTION_BY_ID,
    CREATE_AND_ADD_CAMPAIGN_PROMOTION,
    REMOVE_CAMPAIGN_PROMOTION,
    ADD_CAMPAIGN_PROMOTION,
} from "./actionTypes"


import {
    createPromotionByCampaignSuccess,
    addCampaignPromotionSuccess,
    removeCampaignPromotionSuccess,
    findPromotionByCampaignSuccess,
    findPromotionCampaignByIdSuccess,
    apiFindPromotionsCampaignError
} from "./actions"


import {
    findPromotionByCampaignAPI,
    findPromotionCampaignByIdAPI,
    createPromotionCampaignAPI,
    addPromotionCampaignAPI,
    removePromotionCampaignAPI

} from "../../../helpers/campaign_promotion_helper"

function* findPromotionCampaignById({ payload: { params } }) {
    try {

        const response = yield call(findPromotionCampaignByIdAPI, params)
        const { data } = response;

        yield put(findPromotionCampaignByIdSuccess(data))

    } catch (error) {
        yield put(apiFindPromotionsCampaignError(error))
    }
}

function* removeCampaignPromotion({ payload: { params } }) {
    try {

        const response = yield call(removePromotionCampaignAPI, params)
        const { data } = response;

        yield put(removeCampaignPromotionSuccess(data))

    } catch (error) {
        yield put(apiFindPromotionsCampaignError(error))
    }
}

function* addCampaignPromotion({ payload: { params } }) {
    try {

        const response = yield call(addPromotionCampaignAPI, params)
        const { data } = response;

        yield put(addCampaignPromotionSuccess(data))

    } catch (error) {
        yield put(apiFindPromotionsCampaignError(error))
    }
}

function* createPromotionByCampaign({ payload: { params } }) {
    try {

        const response = yield call(createPromotionCampaignAPI, params)
        const { message, error } = response;

        yield put(createPromotionByCampaignSuccess(response))
        NotificationManager.info(message, 'Aviso');
    } catch (error) {
        yield put(apiFindPromotionsCampaignError(error))
    }
}

function* findPromotionByCampaign({ payload: { params } }) {
    try {

        const response = yield call(findPromotionByCampaignAPI, params)


        yield put(findPromotionByCampaignSuccess(response))

    } catch (error) {
        yield put(apiFindPromotionsCampaignError(error))
    }
}


function* PromotionsCampaignSaga() {
    yield takeEvery(FIND_PROMOTIONS_BY_CAMPAIGN, findPromotionByCampaign)
    yield takeEvery(CREATE_AND_ADD_CAMPAIGN_PROMOTION, createPromotionByCampaign)
    yield takeEvery(ADD_CAMPAIGN_PROMOTION, addCampaignPromotion)
    yield takeEvery(REMOVE_CAMPAIGN_PROMOTION, removeCampaignPromotion)
    yield takeEvery(FIND_PROMOTION_BY_ID, findPromotionCampaignById)
}

export default PromotionsCampaignSaga
