import {
    FIND_ROUTES,
    FIND_ROUTES_SUCCESS,
    FIND_ROUTES_BY_NUMBER,
    FIND_ROUTES_BY_NUMBER_SUCCESS,
    UPDATE_STATUS_ROUTES,
    UPDATE_STATUS_ROUTES_SUCCESS,
    PRINTER_ROUTES,
    PRINTER_ROUTES_SUCCESS,
    ROUTES_API_ERROR
} from "./actionTypes"

export const printerRoutes = params => {

    return {
        type: PRINTER_ROUTES,
        payload: {params},
    }
}

export const printerRoutesSuccess = params => {

    return {
        type: PRINTER_ROUTES_SUCCESS,
        payload: params,
    }
}

export const findRoutes = params => {

    return {
        type: FIND_ROUTES,
        payload: {params},
    }
}

export const findRoutesSuccess = params => {

    return {
        type: FIND_ROUTES_SUCCESS,
        payload: params,
    }
}

export const findRoutesNumber = params => {
    return {
        type: FIND_ROUTES_BY_NUMBER,
        payload: {params},
    }
}

export const findRoutesNumberSuccess = params => {
    return {
        type: FIND_ROUTES_BY_NUMBER_SUCCESS,
        payload: params,
    }
}

export const updateStatusRoutes = params => {
    return {
        type: UPDATE_STATUS_ROUTES,
        payload: {params},
    }
}

export const updateStatusRoutesSuccess = params => {
    return {
        type: UPDATE_STATUS_ROUTES_SUCCESS,
        payload: params,
    }
}

export const apiCreateOrEditRouteError = error => {
    return {
        type: ROUTES_API_ERROR,
        payload: error,
    }
}

