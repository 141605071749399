import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    FIND_PRODUCTS_BY_ID_CATEGORY,
} from "./actionTypes"


import {
    findProductsByCategorySuccess,
    apiFindProductsByCategoryError
} from "./actions"


import {
    findProductsGroupByCategory 
} from "../../../helpers/catalog_helper"

function* findProductsByCategory({payload: {query}}) {
    try {
        console.log('chamnou ', query)
        const response = yield call(findProductsGroupByCategory , query)
        const {results} = response;

        console.log('dtalhe ', results)
        yield put(findProductsByCategorySuccess(results))

    } catch (error) {
        yield put(apiFindProductsByCategoryError(error))
    }
}


function* CatalogProductsByCategorySaga() {
    yield takeEvery(FIND_PRODUCTS_BY_ID_CATEGORY, findProductsByCategory)
}

export default CatalogProductsByCategorySaga
