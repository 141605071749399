import {
    FIND_ORDERS,
    FIND_ORDERS_SUCCESS,
    FIND_ORDERS_BY_NUMBER,
    FIND_ORDERS_BY_NUMBER_SUCCESS,
    UPDATE_STATUS_ORDERS,
    UPDATE_STATUS_ORDERS_SUCCESS,
    PRINTER_ORDERS,
    PRINTER_ORDERS_SUCCESS,
    ORDERS_API_ERROR
} from "./actionTypes"



const initialState = {
    loading: false,
    loading_detail: false,
    loading_delete: false,
    rows: [],
    current_page: 0,
    last_page: 0,
    total: 0,
    from: 0,
    to: 0,
    order: null,
    id: 0,
    order_created_at: null,
    status: 0,
    group:  null,
    customer:  null,
    payment_method: null,
    delivery_type: null,
    delivery_time: null,
}

const createOrEditOrders = (state = initialState, action) => {

    switch (action.type) {
        case PRINTER_ORDERS:
            state = {
                ...state,
                loading: true
            }
            break
        case PRINTER_ORDERS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                loading: false
            }
            break
        case UPDATE_STATUS_ORDERS:
            state = {
                ...state,
                loading: true
            }
            break
        case UPDATE_STATUS_ORDERS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                loading: false
            }
            break
        case FIND_ORDERS_BY_NUMBER:
            state = {
                ...state,
                loading_detail: true
            }
            break
        case FIND_ORDERS_BY_NUMBER_SUCCESS:
            state = {
                ...state,
                id: action.payload.data.id,
                order_created_at: action.payload.data.order_created_at,
                status: action.payload.data.status,
                group: action.payload.data.group,
                customer: action.payload.data.customer,
                payment_method: action.payload.data.payment_method,
                address_delivery: action.payload.data.address_delivery,
                delivery_type: action.payload.data.delivery_type,
                delivery_time: action.payload.data.delivery_time,
                loading: false,
                loading_detail: false
            }
            break
        case FIND_ORDERS:
            state = {
                ...state,
                loading: true
            }
            break
        case FIND_ORDERS_SUCCESS:
            state = {
                ...state,
                current_page: action.payload.current_page,
                last_page: action.payload.last_page,
                total: action.payload.total,
                from: action.payload.from,
                to: action.payload.to,
                rows: action.payload.data,
                loading: false
            }
            break
        case ORDERS_API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditOrders
