import {
    FIND_PRODUCT_BY_CAMPAIGN,
    FIND_PRODUCT_BY_CAMPAIGN_SUCCESS,
    FIND_PRODUCT_BY_ID,
    FIND_PRODUCT_BY_ID_SUCCESS,
    PAGINATE_PRODUCT_CAMPAIGN,
    PAGINATE_PRODUCT_CAMPAIGN_SUCCESS,
    CREATE_AND_ADD_PRODUCT_CAMPAIGN,
    CREATE_AND_ADD_PRODUCT_CAMPAIGN_SUCCESS,
    REMOVE_PRODUCT_CAMPAIGN,
    REMOVE_PRODUCT_CAMPAIGN_SUCCESS,
    ADD_PRODUCT_CAMPAIGN,
    ADD_PRODUCT_CAMPAIGN_SUCCESS,
    PRODUCT_CAMPAIGN_API_ERROR
} from "./actionTypes"

export const allProductCampaignPaginate = params => {
    return {
        type: PAGINATE_PRODUCT_CAMPAIGN,
        payload: { params },
    }
}

export const allProductCampaignPaginateSuccess = result => {
    return {
        type: PAGINATE_PRODUCT_CAMPAIGN_SUCCESS,
        payload: { result },
    }
}


export const createProductCampaign = (params, renderPage) => {
    return {
        type: CREATE_AND_ADD_PRODUCT_CAMPAIGN,
        payload: { params, renderPage },
    }
}

export const createProductCampaignSuccess = params => {
    return {
        type: CREATE_AND_ADD_PRODUCT_CAMPAIGN_SUCCESS,
        payload: params,
    }
}

export const addProductCampaign = params => {
    return {
        type: ADD_PRODUCT_CAMPAIGN,
        payload: { params },
    }
}

export const addProductCampaignSuccess = params => {
    return {
        type: ADD_PRODUCT_CAMPAIGN_SUCCESS,
        payload: { params }
    }
}

export const removeProductCampaign = params => {
    return {
        type: REMOVE_PRODUCT_CAMPAIGN,
        payload: { params },
    }
}

export const removeProductCampaignSuccess = params => {
    return {
        type: REMOVE_PRODUCT_CAMPAIGN_SUCCESS,
        payload: { params }
    }
}

export const findProductByCampaign = params => {
    return {
        type: FIND_PRODUCT_BY_CAMPAIGN,
        payload: { params },
    }
}

export const findProductByCampaignSuccess = params => {
    return {
        type: FIND_PRODUCT_BY_CAMPAIGN_SUCCESS,
        payload: params
    }
}

export const findProductCampaignById = params => {
    return {
        type: FIND_PRODUCT_BY_ID,
        payload: { params },
    }
}

export const findProductCampaignByIdSuccess = params => {
    return {
        type: FIND_PRODUCT_BY_ID_SUCCESS,
        payload: params
    }
}

export const apiFindProductCampaignError = error => {
    return {
        type: PRODUCT_CAMPAIGN_API_ERROR,
        payload: { error },
    }
}

