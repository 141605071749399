import {
    LISTENER_WHATSAPP_CHANNEL_BY_ID_COMPANY,
    LISTENER_WHATSAPP_CHANNEL_BY_ID_COMPANY_SUCCESS,
    WHATSAPP_CHANNEL_ERROR
} from "./actionTypes"


export const findWhatsAppIdChannelListener = params => {
    return {
        type: LISTENER_WHATSAPP_CHANNEL_BY_ID_COMPANY,
        payload: {params}
    }
}

export const findWhatsAppIdChannelListenerSuccess = params => {
    return {
        type: LISTENER_WHATSAPP_CHANNEL_BY_ID_COMPANY_SUCCESS,
        payload: {params}
    }
}

export const channelCreateOrEditWhatsAppError = error => {
    return {
        type: WHATSAPP_CHANNEL_ERROR,
        payload: error,
    }
}

