import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    GET_COUPOM_DISCOUNT_BY_TYPE,
    FIND_COUPOM_DISCOUNT,
} from "./actionTypes"


import {
    getDiscountCouponsByTypeSuccess,
    findDiscountCouponsSuccess,
    apiFindDiscountCouponsError
} from "./actions"


import {
    getCoupomByTypeAPI,
    findDiscountCouponsAPI
} from "../../helpers/discount_coupom_helper"

function* getDiscountCouponsByType({ payload: { params } }) {
    try {

        const response = yield call(getCoupomByTypeAPI,params)
        const {data} = response;

        yield put(getDiscountCouponsByTypeSuccess(data))

    } catch (error) {
        yield put(apiFindDiscountCouponsError(error))
    }
}
function* findDiscountCoupons() {
    try {

        const response = yield call(findDiscountCouponsAPI)
        const {data} = response;

        yield put(findDiscountCouponsSuccess(data))

    } catch (error) {
        yield put(apiFindDiscountCouponsError(error))
    }
}


function* discountCouponsSaga() {
    yield takeEvery(FIND_COUPOM_DISCOUNT, findDiscountCoupons)
    yield takeEvery(GET_COUPOM_DISCOUNT_BY_TYPE, getDiscountCouponsByType)
}

export default discountCouponsSaga
