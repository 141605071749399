import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import PropTypes from "prop-types";
import DropZoneImageSingle from "./../../../../components/SingleIMGDropZone/index"


class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {


        }
    }


    render() {
        const { getFieldsForm, handleFileImgDropUpdate, is_edit, name_product, url_image, type_dir_file, image, id } = this.props

        return (
            <Row>
                <Col>
                    <CardTitle><small>Leia-me:</small></CardTitle>
                    <p>Imagem para os feed, deve respeitar as politicas do google shopping e meta facebook catalogo, tamanho padrão 500x500</p>

                    <li>PNG em alta qualidade</li>
                    <li>Não ter referencia ou apologia ao crime</li>
                    <li>Não induzir menores ao consumo de bebidas alcoolicas</li>
                    <li>Todas imagem deve ter a marca  da pizzaria fontanella.</li>
                    <li>Tamanho inicial minimo 500x500</li>
                </Col>

                <Col>
                    <div className='size_box_upload_img500'>
                        <CardTitle><small>500x500 imagem do produto deve respeitar o tamanho informado.</small></CardTitle>
                        <DropZoneImageSingle
                            is_edit_img={is_edit}
                            handleFileImgDrop={(v) => getFieldsForm(v)}
                            handleFileImgDropUpdate={(v) => {
                                handleFileImgDropUpdate(v)
                            }}
                            name_product={is_edit ? name_product : ''}
                            url_img={url_image}
                            before_file={image}
                            file_dir_name={type_dir_file}
                            entity_id={id} />
                    </div>
                </Col>
            </Row>

        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    getFieldsForm: PropTypes.func,
    handleFileImgDropUpdate: PropTypes.func,
    url_image: PropTypes.any,
    type_dir_file: PropTypes.any,
    image: PropTypes.any,
    id: PropTypes.any,
    is_edit: PropTypes.any,
    name_product: PropTypes.any,

}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
