export const FIND_LOG_SNIPER_BOT = "FIND_LOG_SNIPER_BOT"
export const FIND_LOG_SNIPER_BOT_SUCCESS = "FIND_LOG_SNIPER_BOT_SUCCESS"

export const RESTART_CMD_SNIPER_BOT = "RESTART_CMD_SNIPER_BOT"
export const RESTART_CMD_SNIPER_BOT_SUCCESS = "RESTART_CMD_SNIPER_BOT_SUCCESS"

export const STOP_CMD_SNIPER_BOT = "STOP_CMD_SNIPER_BO"
export const STOP_CMD_SNIPER_BOT_SUCCESS = "STOP_CMD_SNIPER_BOT_SUCCESS"

export const FIND_LOG_SNIPER_BOT_API_ERROR = "FIND_LOG_SNIPER_BOT_API_ERROR"
