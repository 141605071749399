import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Form, Row, Stack, InputGroup } from "react-bootstrap"
import { CardBody, Card, CardTitle, CardText } from "reactstrap"
import ImageURI from 'components/ImageURI';
import NumberFormat from 'components/NumberFormat';
import UIRadioDinamList from 'components/UIRadioDinamList';

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            qtd: 1,
            products_list: [],

            product: null,
            size: null,
            edge: null,
            paste: null
        }
        this.getRadioType = this.getRadioType.bind(this)
        this.handleAddProduct = this.handleAddProduct.bind(this)
    }

    componentDidMount() {


    }

    async handleAddProduct(data) {
        // const { products_list } = this.state
        let item = null;
        // regra para pizzaria
        if (data.category.model_menu_id == 1) {
            item = {
                id: data.category.id,
                idGroup: data.category.id,
                groupName: data.category.name,
                items: [
                    {
                        id: data.id,
                        name: this.state.size.name + `( ${this.state.size.qtd_flavors} SABORES ${this.state.size.qtd_pieces} PEDAÇOS)`,
                        description: data.type == 1 ? `1/2 ${data.name}` : `${this.state.qtd}x ${data.name}`,
                        prod_type: data.type,
                        qtd: this.state.qtd,
                        price: this.state.size.price,
                        size: this.state.size,
                        edge: this.state.edge,
                        pasta: this.state.pasta
                    }
                ]
            }
        }
        // seguimento de itens comuns
        if (data.category.model_menu_id == 2) {
            item = {
                id: data.category.id,
                idGroup: data.category.id,
                groupName: data.category.name,
                items: [
                    {
                        id: data.id,
                        name: data.name,
                        description: `${this.state.qtd}x ${data.name}`,
                        prod_type: data.type,
                        qtd: this.state.qtd,
                        price: data.price,

                    }
                ]
            }
        }
        if (item != null) {
            const { products_list } = this.state
            products_list.push(item)
            //this.props.getProducts(this.state.products_list)
            this.setState({ product: item, products_list })
            localStorage.setItem('pdv', btoa(JSON.stringify(this.state.products_list)));

        }

    }

    getRadioType(e) {
        const { id, name, value } = e.target
        console.log('PDV RADIO ', name)
        console.log('PDV RADIO ', JSON.parse(value))
        console.log('PDV PROD ', this.props.row)

        let newState = {}


        // newState[name] = value
        // newState[name_entity] = code
        newState[name] = JSON.parse(value)
        this.setState(newState)
    }
    render() {
        console.log('PROD 22 ', this.state.products_list)

        return (
            <Col sm={12}>
                {this.props.row &&
                    <Row>
                        <Col sm={2}>
                            <div className="p-2 align-baseline">
                                <ImageURI url={this.props.row.url_image} alt={this.props.row.text} />
                            </div>
                        </Col>
                        <Col>
                            <div className="p-2">
                                <Stack gap={3}>
                                    <div className="p-2 text-start">
                                        <span className="font-got-blk-12-400h">{this.props.row.text}</span>
                                        <p><small className="text-muted ">{this.props.row.description}</small></p>
                                    </div>
                                    <div>
                                        {
                                            this.props.row.category.model_menu_id == 1 &&
                                            <>
                                                <UIRadioDinamList getRadioType={this.getRadioType} name={'size'} rows={this.props.row.sizes} type={1} title={'Escolha o tamanho'} />
                                                <hr />
                                                <UIRadioDinamList getRadioType={this.getRadioType} name={'pasta'} rows={this.props.row.pastas} type={2} title={'Escolha o tipo de massa'} />
                                                <hr />
                                                <UIRadioDinamList getRadioType={this.getRadioType} name={'edge'} rows={this.props.row.edges} type={3} title={'Escolha o tipo de borda'} />

                                            </>
                                        }
                                    </div>
                                    <div>
                                        <Stack gap={3}>

                                            <div>
                                                {
                                                    this.props.row.category.model_menu_id == 1 &&
                                                    <>
                                                        <p className=" text-danger">*Caso seja 1/2 á 1/2 quantidade 2 torna uma inteira do mesmo sabor. <br />
                                                            *No caso de 1/2 o tipo de passa prevalece inteira.<br />
                                                            *No caso de 1/2 o tipo de borda prevalece inteira.</p>
                                                    </>
                                                }
                                                <p>Defina a quantidade para calcular o valor</p>

                                                <Stack direction="horizontal" gap={3}>
                                                    <div className='w-25'>
                                                        <InputGroup className="mb-2">
                                                            <InputGroup.Text id="basic-qtd">Qtd.</InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="0"
                                                                aria-label="0"
                                                                type="number"
                                                                aria-describedby="basic-qtd"
                                                                value={this.state.qtd}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                    <div><span className="font-got-blk-12-400h">X</span></div>
                                                    <div className='w-25'>
                                                        <InputGroup className="mb-2">
                                                            <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Username"
                                                                aria-label="Username"
                                                                disabled={true}
                                                                aria-describedby="basic-addon1"
                                                                value={this.props.row.price}
                                                            />
                                                        </InputGroup>
                                                    </div>

                                                </Stack>

                                            </div>
                                        </Stack>

                                    </div>
                                    <div>
                                        <Stack direction="horizontal" gap={this.props.row.category.model_menu_id == 1 ? 3 : 1}>
                                            {
                                                this.props.row.category.model_menu_id == 2 &&
                                                <>
                                                    <div>
                                                        <span className="font-got-blk-20-400h">Total:</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger font-got-blk-20-400h"><NumberFormat value={this.props.row.price} /></span>
                                                    </div>
                                                </>
                                            }

                                            <div className="text-md-right ms-auto mb-2">
                                                <a onClick={async () => {
                                                    const { row } = this.props
                                                    if (row != null) {

                                                        this.handleAddProduct(row)


                                                    }
                                                }} className="btn btn-dark btn-sm text-light">
                                                    + Adicionar
                                                </a>
                                            </div>
                                        </Stack>
                                    </div>

                                </Stack>
                            </div>
                        </Col>
                    </Row>

                }

            </Col >


        )
    }


}

Index.propTypes = {
    error: PropTypes.any,
    loading: PropTypes.any,
    refresh: PropTypes.any,
    loading_delete: PropTypes.any,
    row: PropTypes.any,
    getProducts: PropTypes.func,
}

const mapStateToProps = state => {

    return {}
}

export default withRouter(
    connect(mapStateToProps, {


    })(Index)
)
