import {
    LISTENER_WHATSAPP_CHANNEL_BY_ID_COMPANY,
    LISTENER_WHATSAPP_CHANNEL_BY_ID_COMPANY_SUCCESS,
    WHATSAPP_CHANNEL_ERROR
} from "./actionTypes"

const initialState = {
    sniper_shot: null,
    logs_msg: [{message: 'SNIPER-BOT Conectando...'}]
}

const createOrEditWhatsAppChannelListeners = (state = initialState, action) => {

    switch (action.type) {
        case LISTENER_WHATSAPP_CHANNEL_BY_ID_COMPANY:
            state = {
                ...state,
                logs_msg: [...state.logs_msg, {message: action.payload.message}]
            }
            break
        case LISTENER_WHATSAPP_CHANNEL_BY_ID_COMPANY_SUCCESS:

            state = {
                ...state,
                sniper_shot: {
                    type: action.payload.type,
                    message: action.payload.message,
                    qrcode: action.payload.qrcode

                },
               logs_msg: [...state.logs_msg, {message: action.payload.message}]
            }
            break
        case WHATSAPP_CHANNEL_ERROR:
            state = {...state, error: action.payload}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditWhatsAppChannelListeners
