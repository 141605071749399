import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"


import {postForgetPwd} from "../../../helpers/forget_pwd_helper"


function* forgetUser({ payload: { user, history } }) {
  try {

      const response = yield call(postForgetPwd, {
          email: user.email,
      })
      yield put(userForgetPasswordSuccess(response))
      history.push("/login")
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

export default forgetPasswordSaga
