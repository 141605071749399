export const FIND_PRODUCT_BY_CAMPAIGN = "FIND_PRODUCT_BY_CAMPAIGN"
export const FIND_PRODUCT_BY_CAMPAIGN_SUCCESS = "FIND_PRODUCT_BY_CAMPAIGN_SUCCESS"

export const FIND_PRODUCT_BY_ID = "FIND_PRODUCT_BY_ID"
export const FIND_PRODUCT_BY_ID_SUCCESS = "FIND_PRODUCT_BY_ID_SUCCESS"

export const CREATE_AND_ADD_PRODUCT_CAMPAIGN = "CREATE_AND_ADD_PRODUCT_CAMPAIGN"
export const CREATE_AND_ADD_PRODUCT_CAMPAIGN_SUCCESS = "CREATE_AND_ADD_PRODUCT_CAMPAIGN_SUCCESS"

export const REMOVE_PRODUCT_CAMPAIGN = "REMOVE_PRODUCT_CAMPAIGN"
export const REMOVE_PRODUCT_CAMPAIGN_SUCCESS = "REMOVE_PRODUCT_CAMPAIGN_SUCCESS"

export const ADD_PRODUCT_CAMPAIGN = "ADD_PRODUCT_CAMPAIGN"
export const ADD_PRODUCT_CAMPAIGN_SUCCESS = "ADD_PRODUC_CAMPAIGN_SUCCESS"

export const PAGINATE_PRODUCT_CAMPAIGN = "PAGINATE_PRODUCT_CAMPAIGN"
export const PAGINATE_PRODUCT_CAMPAIGN_SUCCESS = "PAGINATE_PRODUCT_CAMPAIGN_SUCCESS"


export const PRODUCT_CAMPAIGN_API_ERROR = "PRODUCT_CAMPAIGN_API_ERROR"


