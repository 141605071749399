import React from 'react'
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class NumberFormat extends React.Component {

    render() {
        const { value } = this.props
        return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

}
NumberFormat.propTypes = {
    value: PropTypes.any,


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (NumberFormat)
)))
