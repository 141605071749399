import React from 'react'
import { CardBody } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Card, Col, Row, Stack } from "react-bootstrap"
import { getCategorySectionPromotion } from "../../../../store/category/actions"

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.getCategorySectionPromotion(this.props.company_id)

    }


    render() {
        const { rows } = this.props

        return (
            <>

                <div className='w-75'>
                    <span
                        className="h4 card-title">Desconto em produtos</span>
                    <p className="card-title-desc mt-2">
                        Escolha em qual categoria de sessão, será exibido os produtos promocionais.
                    </p>
                </div>
                <Row>
                    {
                        rows.map((item, index) => {
                            return (
                                <Col key={index} sm={12} className="border rounded mb-2 p-3">
                                    <a onClick={() => this.props.getCategorySelected(item)}>
                                        <span
                                            className="h4 card-title">{item.name}</span>
                                        <p className="card-title-desc mt-2">
                                            {item.description}
                                        </p>
                                    </a>
                                </Col>
                            )
                        })
                    }

                </Row>




            </>

        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.any,

    getCategorySelected: PropTypes.func,
    getCategorySectionPromotion: PropTypes.func,
    rows: PropTypes.any,
    company_id: PropTypes.any,
    promotion_id: PropTypes.any,
    type: PropTypes.any,


}
const mapStateToProps = state => {
    const {
        loading,
        rows,
        id
    } = state.Category

    return {
        loading,
        rows,
        id
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    getCategorySectionPromotion,

})(Index)
))
