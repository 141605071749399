export const GET_BY_ID_SOCIAL_MIDIA_PRODUCT = "GET_BY_ID_SOCIAL_MIDIA_PRODUCT"
export const GET_BY_ID_SOCIAL_MIDIA_PRODUCT_SUCCESS = "GET_BY_ID_SOCIAL_MIDIA_PRODUCT_SUCCESS"

export const UPLOAD_IMAGE_MIDIA_PRODUCT = "UPLOAD_IMAGE_MIDIA_PRODUCT"
export const UPLOAD_IMAGE_MIDIA_PRODUCT_SUCCESS = "UPLOAD_IMAGE_MIDIA_PRODUCT_SUCCESS"

export const UPDATE_MIDIA_PRODUCT = "UPDATE_MIDIA_PRODUCT"
export const UPDATE_MIDIA_PRODUCT_SUCCESS = "UPDATE_MIDIA_PRODUCT_SUCCESS"


export const FIND_SOCIAL_MIDIA_PRODUCT = "FIND_SOCIAL_MIDIA_PRODUCT"
export const FIND_SOCIAL_MIDIA_PRODUCT_SUCCESS = "FIND_SOCIAL_MIDIA_PRODUCT_SUCCESS"

export const CREATE_SOCIAL_MIDIA_PRODUCT = "CREATE_SOCIAL_MIDIA_PRODUCT"
export const CREATE_SOCIAL_MIDIA_PRODUCT_SUCCESS = "CREATE_SOCIAL_MIDIA_PRODUCT_SUCCESS"

export const PAGINATE_SOCIAL_MIDIA_PRODUCT = "PAGINATE_SOCIAL_MIDIA_PRODUCT"
export const PAGINATE_SOCIAL_MIDIA_PRODUCT_SUCCESS = "PAGINATE_SOCIAL_MIDIA_PRODUCT_SUCCESS"

export const FIND_SOCIAL_MIDIA_PRODUCT_API_ERROR = "FIND_SOCIAL_MIDIA_PRODUCT_API_ERROR"
 