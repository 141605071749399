import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    PAGINATE_SOCIAL_MIDIA_PROMOTION,
    FIND_SOCIAL_MIDIA_PROMOTION,
    CREATE_SOCIAL_MIDIA_PROMOTION,
    GET_BY_ID_SOCIAL_MIDIA_PROMOTION,
    UPLOAD_IMAGE_MIDIA_PROMOTION,
    UPDATE_MIDIA_PROMOTION
} from "./actionTypes"


import {
    allSMPromotionsPaginateSuccess,
    createSMPromotionsSuccess,
    findSMPromotionsSuccess,
    findSMPromotionsIdSuccess,
    uploadSMPromotionsSuccess,
    editSMPromotionsSuccess,
    apiFindSMPromotionsError
} from "./actions"


import {
    findSMPromotionsAPI,
    createSMPromotionsAPI,
    getSMPromotionsById,
    postUploadSMPromotions,
    putEditSMPromotions,
    getSMPromotionsPaginate
} from "../../../helpers/sm_promotions_helper"

function* editSMPromotions({payload: {params, goBack}}) {
    try {

        const response = yield call(putEditSMPromotions, params)

        yield put(editSMPromotionsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        goBack(0)

    } catch (error) {
        yield put(apiFindSMPromotionsError(error))
    }
}

function* paginateSMPromotions({payload: {params}}) {
    try { 

        const response = yield call(getSMPromotionsPaginate, params)

        yield put(allSMPromotionsPaginateSuccess(response))

    } catch (error) {
        yield put(apiFindSMPromotionsError(error))
    }
}


function* createSMPromotions({ payload: { params,  history } }) {
    try {

        const response = yield call(createSMPromotionsAPI, params)
        const { message,  error } = response;

        yield put(createSMPromotionsSuccess(response))


        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiFindSMPromotionsError(error))
    }
}

function* findByIdSMPromotions({payload: {id}}) {
    try {

        const response = yield call(getSMPromotionsById, id)

        yield put(findSMPromotionsIdSuccess(response))

    } catch (error) {
        yield put(apiFindSMPromotionsError(error))
    }
}

function* findSMPromotions() {
    try {

        const response = yield call(findSMPromotionsAPI)
        const {data} = response;

        console.log('SAGA ',data)
        yield put(findSMPromotionsSuccess(data))

    } catch (error) {
        yield put(apiFindSMPromotionsError(error))
    }
}
function* uploadFindSMPromotions({payload: {dataForm}}) {
    try {

        const response = yield call(postUploadSMPromotions, dataForm)

        yield put(uploadSMPromotionsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiFindSMPromotionsError(error))
    }
}


function* CatalogSMPromotionsSaga() {
    yield takeEvery(GET_BY_ID_SOCIAL_MIDIA_PROMOTION, findByIdSMPromotions)
    yield takeEvery(UPLOAD_IMAGE_MIDIA_PROMOTION, uploadFindSMPromotions)
    yield takeEvery(FIND_SOCIAL_MIDIA_PROMOTION, findSMPromotions)
    yield takeEvery(CREATE_SOCIAL_MIDIA_PROMOTION, createSMPromotions)
    yield takeEvery(PAGINATE_SOCIAL_MIDIA_PROMOTION, paginateSMPromotions)
    yield takeEvery(UPDATE_MIDIA_PROMOTION, editSMPromotions)
}

export default CatalogSMPromotionsSaga
