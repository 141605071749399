import {
    FIND_PROMOTIONS_BY_COMPANIES,
    FIND_PROMOTIONS_BY_COMPANIES_SUCCESS,
    FIND_PROMOTION_BY_ID,
    FIND_PROMOTION_BY_ID_SUCCESS,
    CREATE_AND_ADD_PRODUCT_PROMOTION,
    CREATE_AND_ADD_PRODUCT_PROMOTION_SUCCESS,
    REMOVE_PRODUCT_PROMOTION,
    REMOVE_PRODUCT_SUCCESS,
    ADD_PRODUCT_PROMOTION,
    ADD_PRODUCT_PROMOTION_SUCCESS,
    FIND_PROMOTIONS_BY_COMPANIES_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    rows: [],
    id: 0,
}

const promotionsCompanies = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_AND_ADD_PRODUCT_PROMOTION:
        case REMOVE_PRODUCT_PROMOTION:
        case ADD_PRODUCT_PROMOTION:
        case FIND_PROMOTIONS_BY_COMPANIES:
        case FIND_PROMOTION_BY_ID:
            state = {
                ...state,
                loading: true
            }
            break
        case FIND_PROMOTIONS_BY_COMPANIES_SUCCESS:
        case FIND_PROMOTION_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload,
                loading: false
            }
            break
        case CREATE_AND_ADD_PRODUCT_PROMOTION_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                loading: false
            }
            break
        case REMOVE_PRODUCT_SUCCESS:
        case ADD_PRODUCT_PROMOTION_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_PROMOTIONS_BY_COMPANIES_API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break

        default:
            state = { ...state }
            break
    }

    return state
}
export default promotionsCompanies
