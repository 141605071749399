import {takeEvery, put, call} from "redux-saga/effects"

import {
    LISTENER_ROUTES_CARD_ID_CHANNEL,
    LISTENER_ROUTES_CHANNEL_BY_ID_COMPANY
} from "./actionTypes"


import {
    findRouteIdChannelListenerSuccess,
    findRouteCompanyIdChannelListenerSuccess,
    channelCreateOrEditRouteError
} from "./actions"


import {
    findRouteIdChannelListener,
    findRouteCompanyIdChannelListener
} from "../../../helpers/channels/routes_listeners"

function* findRouteIdChannel({payload: {params}}) {
    try {

        const {e} = yield call(findRouteIdChannelListener, params)
        console.log('ECHo ', e)
        yield put(findRouteIdChannelListenerSuccess(e))


    } catch (error) {
        yield put(channelCreateOrEditRouteError(error))
    }
}

function* findRouteCompanyIdChannel({payload: {params}}) {
   try {

       const {e} = yield call(findRouteCompanyIdChannelListener, params)

       yield put(findRouteCompanyIdChannelListenerSuccess(e))

   } catch (error) {
        yield put(channelCreateOrEditRouteError(error))
    }
}


function* createOrEditRoutesChannelSaga() {
    yield takeEvery(LISTENER_ROUTES_CARD_ID_CHANNEL, findRouteIdChannel)
    yield takeEvery(LISTENER_ROUTES_CHANNEL_BY_ID_COMPANY, findRouteCompanyIdChannel)
}

export default createOrEditRoutesChannelSaga
