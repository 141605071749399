import {
    FIND_PROMOTIONS_BY_COMPANIES,
    FIND_PROMOTIONS_BY_COMPANIES_SUCCESS,
    FIND_PROMOTION_BY_ID,
    FIND_PROMOTION_BY_ID_SUCCESS,
    CREATE_AND_ADD_PRODUCT_PROMOTION,
    CREATE_AND_ADD_PRODUCT_PROMOTION_SUCCESS,
    REMOVE_PRODUCT_PROMOTION,
    REMOVE_PRODUCT_SUCCESS,
    ADD_PRODUCT_PROMOTION,
    ADD_PRODUCT_PROMOTION_SUCCESS,
    FIND_PROMOTIONS_BY_COMPANIES_API_ERROR
} from "./actionTypes"


export const createPromotionByCompanies = params => {
    return {
        type: CREATE_AND_ADD_PRODUCT_PROMOTION,
        payload: { params },
    }
}

export const createPromotionByCompaniesSuccess = params => {
    return {
        type: CREATE_AND_ADD_PRODUCT_PROMOTION_SUCCESS,
        payload: params,
    }
}

export const addProductPromotion = params => {
    return {
        type: ADD_PRODUCT_PROMOTION,
        payload: { params },
    }
}

export const addProductPromotionSuccess = params => {
    return {
        type: ADD_PRODUCT_PROMOTION_SUCCESS,
        payload: { params }
    }
}

export const removeProductPromotion = params => {
    return {
        type: REMOVE_PRODUCT_PROMOTION,
        payload: { params },
    }
}

export const removeProductPromotionSuccess = params => {
    return {
        type: REMOVE_PRODUCT_SUCCESS,
        payload: { params }
    }
}

export const findPromotionByCompanies = params => {
    return {
        type: FIND_PROMOTIONS_BY_COMPANIES,
        payload: { params },
    }
}

export const findPromotionByCompaniesSuccess = params => {
    return {
        type: FIND_PROMOTIONS_BY_COMPANIES_SUCCESS,
        payload: params
    }
}

export const findPromotionById = params => {
    return {
        type: FIND_PROMOTION_BY_ID,
        payload: { params },
    }
}

export const findPromotionByIdSuccess = params => {
    return {
        type: FIND_PROMOTION_BY_ID_SUCCESS,
        payload: params
    }
}

export const apiFindPromotionsCompaniesError = error => {
    return {
        type: FIND_PROMOTIONS_BY_COMPANIES_API_ERROR,
        payload: { error },
    }
}

