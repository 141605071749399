import {
    FIND_PRODUCTS_BY_ID_CATEGORY,
    FIND_PRODUCTS_BY_ID_CATEGORY_SUCCESS,
    FIND_PRODUCTS_BY_ID_CATEGORY_API_ERROR,
} from "./actionTypes"


const initialState = {
    loading: true,
    loading_form: false,
    error: false,
    message: '',

    rows: [],
    id: 0,
}

const productsCategory = (state = initialState, action) => {

    switch (action.type) {
        case FIND_PRODUCTS_BY_ID_CATEGORY:
            state = {
                ...state,
                loading: true,
                rows: [],
            }
            break
        case FIND_PRODUCTS_BY_ID_CATEGORY_SUCCESS:
            console.log('data ac ', action.payload)
        state = {
                ...state,
                message: '',
                error: false,
                rows: action.payload,
                loading: false
            }
            break
        case FIND_PRODUCTS_BY_ID_CATEGORY_API_ERROR:
            state = {...state, error: action.payload, rows: [],loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default productsCategory
