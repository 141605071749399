import {
    FIND_SOCIAL_MIDIA_PROMOTION,
    FIND_SOCIAL_MIDIA_PROMOTION_SUCCESS,
    CREATE_SOCIAL_MIDIA_PROMOTION,
    CREATE_SOCIAL_MIDIA_PROMOTION_SUCCESS,
    PAGINATE_SOCIAL_MIDIA_PROMOTION,
    PAGINATE_SOCIAL_MIDIA_PROMOTION_SUCCESS,
    GET_BY_ID_SOCIAL_MIDIA_PROMOTION,
    GET_BY_ID_SOCIAL_MIDIA_PROMOTION_SUCCESS,
    UPLOAD_IMAGE_MIDIA_PROMOTION,
    UPLOAD_IMAGE_MIDIA_PROMOTION_SUCCESS,
    UPDATE_MIDIA_PROMOTION,
    UPDATE_MIDIA_PROMOTION_SUCCESS,
    FIND_SOCIAL_MIDIA_PROMOTION_API_ERROR,
} from "./actionTypes"

export const editSMPromotions = (params, goBack) => {
    return {
        type: UPDATE_MIDIA_PROMOTION,
        payload: {params, goBack},
    }
}

export const editSMPromotionsSuccess = result => {
    return {
        type: UPDATE_MIDIA_PROMOTION_SUCCESS,
        payload: {result},
    }
}


export const findByIdSMPromotions = id => {
    return {
        type: GET_BY_ID_SOCIAL_MIDIA_PROMOTION,
        payload: {id},
    }
}

export const findSMPromotionsIdSuccess = result => {
    return {
        type: GET_BY_ID_SOCIAL_MIDIA_PROMOTION_SUCCESS,
        payload: result,
    }
}

export const uploadSMPromotions = ( dataForm) => {
    return {
        type: UPLOAD_IMAGE_MIDIA_PROMOTION,
        payload: {dataForm},
    }
}

export const uploadSMPromotionsSuccess = result => {
    return {
        type: UPLOAD_IMAGE_MIDIA_PROMOTION_SUCCESS,
        payload: result,
    }
}


export const allSMPromotionsPaginate = params => {
    return {
        type: PAGINATE_SOCIAL_MIDIA_PROMOTION,
        payload: { params },
    }
}

export const allSMPromotionsPaginateSuccess = result => {
    return {
        type: PAGINATE_SOCIAL_MIDIA_PROMOTION_SUCCESS,
        payload: { result },
    }
}

export const createSMPromotions = (params, history) => {
    return {
        type: CREATE_SOCIAL_MIDIA_PROMOTION,
        payload: { params, history },
    }
}

export const createSMPromotionsSuccess = params => {
    return {
        type: CREATE_SOCIAL_MIDIA_PROMOTION_SUCCESS,
        payload: params,
    }
}

export const findSMPromotions = query => {
    return {
        type: FIND_SOCIAL_MIDIA_PROMOTION,
        payload: { query },
    }
}

export const findSMPromotionsSuccess = data => {
    return {
        type: FIND_SOCIAL_MIDIA_PROMOTION_SUCCESS,
        payload: { data }
    }
}

export const apiFindSMPromotionsError = error => {
    return {
        type: FIND_SOCIAL_MIDIA_PROMOTION_API_ERROR,
        payload: { error },
    }
}

