import {del, get, post, put} from "./api_helper"
import * as url from "./url_helper"
import toast from "react-hot-toast"


const getLogSniperBotAPI = ({company_id}) => get(url.GET_LOG_SNIPER_BOT, {params: {company_id: company_id}})
    .catch(err => {
        const {title, message} = err.response.data
        toast.error(message)
        throw err.response.data
    })

const postRestartSniperBotAPI = data => post(url.RESTART_SNIPER_BOT, data)
    .catch(err => {
        const {title, message} = err.response.data
        toast.error(message)
        throw err.response.data
    })

const postStopSniperBotAPI = data => get(url.STOP_SNIPER_BOT, data)
    .catch(err => {
        const {title, message} = err.response.data
        toast.error(message)
        throw err.response.data
    })


export {
    getLogSniperBotAPI,
    postRestartSniperBotAPI,
    postStopSniperBotAPI
}
