import React from 'react'
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class CellPhoneFormat extends React.Component {

    render() {
        const { value } = this.props
        return value.replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '($1) $2')
                .replace(/(\d{5})(\d)/, '$1-$2')
                .replace(/(-\d{4})\d+?$/, '$1');
    }

}
CellPhoneFormat.propTypes = {
    value: PropTypes.any,


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (CellPhoneFormat)
)))
