import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap"
import ImageURI from 'components/ImageURI'
import NumberFormat from 'components/NumberFormat'
import { Stack } from "react-bootstrap"

class UICardProducts_type_1 extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        return (
            <React.Fragment>
                <Col key={this.props.key} sm={12} className="mb-1">

                    <Stack gap={2} direction="horizontal" className=" border border-1 p-2">

                        <div >
                            <ImageURI url={this.props.product.url_image} alt={this.props.product.name} />
                        </div>

                        <div>
                            <Stack gap={this.props.visible_button ? 1 : 2} className=" p-2">

                                <div>
                                    <h2 className=' card-title'>{this.props.product.name} <br />
                                        <small className='text-muted'>{this.props.product.description}</small>
                                    </h2>
                                    {
                                        this.props.type === 1 &&
                                        this.props.sizes.length &&
                                        this.props.sizes.map((size, idx) => {
                                            return (
                                                <Stack key={idx} direction="horizontal" gap={this.props.sizes.length}>
                                                    <div><span className='text-danger card-title h4'><NumberFormat value={size.price} /></span> {size.name} (até {size.qtd_flavors} sabores) - ({size.qtd_pieces} pedaços)</div>
                                                </Stack>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    this.props.visible_button &&
                                    <div>
                                        <a
                                            onClick={() => {
                                                this.props.addProductList(this.props.product, 1)
                                            }}
                                            className="btn btn-danger btn-sm text-light"
                                        >
                                            <span> Add. produto na lista de desconto</span>
                                        </a>
                                    </div>
                                }


                            </Stack>
                        </div>

                    </Stack>

                </Col>
            </React.Fragment>
        )
    }
}

UICardProducts_type_1.propTypes = {
    key: PropTypes.any,
    product: PropTypes.any,
    sizes: PropTypes.array,
    type: PropTypes.any,
    visible_button: PropTypes.any,
    addProductList: PropTypes.func,
}

export default UICardProducts_type_1
