import React, { Component } from "react"
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import PerfectScrollbar from "react-perfect-scrollbar";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react"
import { google_maps } from "../../../config";
import Autocomplete from "../../../components/Autocomplete";
import { GET_GEO_SEARCH_ADDRESS } from "../../../helpers/url_helper"
import toast from "react-hot-toast"
const LoadingContainer = () => <div>Loading...</div>

class UIAddressSearchPickupDelivery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            coordinates: { lat: -22.5625776, lng: -47.3862292 },
            makerTitle: 'Pizzaria Fontanella',
            address: "",
            number: "",
            complement: '',
            postal_code: ''
        }

        this.handleFields = this.handleFields.bind(this);
        this.handleAutocomplete = this.handleAutocomplete.bind(this);

    }

    componentDidMount() {
        if (this.props.data) {
            const { address, number, complement } = this.props.data;
            this.setState({ address, number, complement })
        }

    }

    handleAutocomplete(_data) {

        if (_data) {
            const { value, name, coordinates, postal_code } = _data

            this.setState({ coordinates, makerTitle: 'Seu Endereço', postal_code })

            let newState = {}

            newState[name] = value

            this.setState(newState)
        }
    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    render() {


        return (
            <Col sm={12}>
                <PerfectScrollbar style={{ height: "540px" }}>

                    <Row>
                        <Col sm={12}>
                            <div className="text-start mb-3">
                                <a onClick={this.props.previousPage}>
                                    <b>
                                        <h4 className="card-title font-bl-12-400h text-danger">
                                            <i className="mdi mdi-arrow-left mdi-16px" /> Voltar
                                        </h4>
                                    </b>
                                </a>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="text-center  mb-4">
                                <h4 className="card-title">Nos informe em qual endereço iremos fazer a entrega.</h4>
                                <small>em Pizzaria Fontanella</small>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <p className="card-text font-bl-12-400h">É muito importante que todas informações preenchidas, são verdadeiras, para segurança durante a entrega de sua encomenda.</p>
                        </Col>

                        <Col sm={12} className="mt-4">
                            <Autocomplete
                                onSourceSelect={this.handleAutocomplete}
                                inputProps={{
                                    id: 'address',
                                    label: "Endereço de entrega",
                                    placeholder: "Pesquisar pelo endereço de entrega",
                                    value: this.state.address,
                                    url: GET_GEO_SEARCH_ADDRESS
                                }} />
                        </Col>
                        <Col sm={4} className="mt-2">
                            <div className="form-group">
                                <label htmlFor="number" className="control-label">Nº</label>
                                <input className="form-control" type="text" id="number" name="number" onChange={this.handleFields} value={this.state.number} />
                            </div>
                        </Col>
                        <Col className="mt-2">
                            <div className="form-group">
                                <label htmlFor="complement" className="control-label">Complemento</label>
                                <input className="form-control" type="text" id="complement" name="complement" onChange={this.handleFields} value={this.state.complement} />
                            </div>
                        </Col>
                        {/*<Col sm={12} className="mt-3">
                                <div id="gmaps-makers" className="gmaps"
                                     style={{position: 'relative'}}>
                                        <Map
                                            google={this.props.google}
                                            style={{width: '100%', height: 300}}
                                            zoom={14}
                                            loaded={true}
                                            center={this.state.coordinates}
                                            initialCenter={this.state.coordinates}
                                        >
                                            <Marker
                                                position={this.state.coordinates}
                                                name={this.state.makerTitle}
                                            />
                                        </Map>
                                </div>
                            </Col>*/}
                        <Col sm={12} className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-sm btn-block btn-dark mt-1"
                                onClick={() => {
                                    if (this.state.number.length !== 0) {
                                        this.props.funcParamAddress({
                                            number: this.state.number,
                                            complement: this.state.complement,
                                            coordinates: this.state.coordinates,
                                            address: this.state.address,
                                            postal_code: this.state.postal_code
                                        })
                                    } else {
                                        toast.error('Informe o número residencial')
                                    }
                                }
                                }>
                                <h4 className="font-bl-13-700h text-center pt-1 text-light">Continuar</h4>
                            </button>
                        </Col>

                    </Row>

                </PerfectScrollbar>
            </Col>
        )
    }
}

UIAddressSearchPickupDelivery.propTypes = {
    google: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.any,
    loading_map: PropTypes.any,
    coordinates: PropTypes.object,
    address: PropTypes.any,
    id: PropTypes.number,
    lat: PropTypes.any,
    lng: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    getParamsModal: PropTypes.func,
    rows: PropTypes.array,
    funcParamResponsible: PropTypes.func,
    previousPage: PropTypes.func,
    searchAddress: PropTypes.func,
    reverseCoordinates: PropTypes.func,
    funcParamAddress: PropTypes.func,
    data: PropTypes.object


}
const mapStateToProps = state => {
    const { loading_map, address, coordinates } = state.GeoLocation
    return { loading_map, address, coordinates }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
  
})(
    GoogleApiWrapper({
        apiKey: google_maps.token,
        LoadingContainer: LoadingContainer,
        v: "3",
    })(UIAddressSearchPickupDelivery)
)))
