import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const findSMProductsAPI = data => get(url.GET_SOCIAL_MIDIA_PRODUCT)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const getSMProductsPaginate = ({ company_id, page, limit }) => get(url.GET_SOCIAL_MIDIA_PRODUCT_PAGINATE, { params: { company_id, page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const createSMProductsAPI = data => post(url.POST_CREATE_SOCIAL_MIDIA_PRODUCT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })


const getSMProductsById = data => get(url.GET_SOCIAL_MIDIA_PRODUCT_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postUploadSMProducts = data => post(url.POST_UPLOAD_SOCIAL_MIDIA_PRODUCT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const putEditSMProducts = data => put(url.PUT_EDIT_SOCIAL_MIDIA_PRODUCT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })


export {
    findSMProductsAPI,
    getSMProductsPaginate,
    createSMProductsAPI,
    getSMProductsById,
    postUploadSMProducts,
    putEditSMProducts
}
