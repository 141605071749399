import {
    FIND_LOG_SNIPER_BOT,
    FIND_LOG_SNIPER_BOT_SUCCESS,
    RESTART_CMD_SNIPER_BOT,
    RESTART_CMD_SNIPER_BOT_SUCCESS,
    STOP_CMD_SNIPER_BOT,
    STOP_CMD_SNIPER_BOT_SUCCESS,
    FIND_LOG_SNIPER_BOT_API_ERROR,
} from "./actionTypes"

export const restartSniperBot = params => {
    return {
        type: RESTART_CMD_SNIPER_BOT,
        payload: {params},
    }
}

export const restartSniperBotSuccess = data => {
    return {
        type: RESTART_CMD_SNIPER_BOT_SUCCESS,
        payload: {data}
    }
}
export const stopSniperBot = params => {
    return {
        type: STOP_CMD_SNIPER_BOT,
        payload: {params},
    }
}

export const stopSniperBotSuccess = data => {
    return {
        type: STOP_CMD_SNIPER_BOT_SUCCESS,
        payload: {data}
    }
}
export const findLogSniperBot = ({params}) => {
    return {
        type: FIND_LOG_SNIPER_BOT,
        payload: {params}
    }
}

export const findLogSniperBotSuccess = data => {
    return {
        type: FIND_LOG_SNIPER_BOT_SUCCESS,
        payload: {data}
    }
}

export const apiFindLogSniperBotError = error => {
    return {
        type: FIND_LOG_SNIPER_BOT_API_ERROR,
        payload: {error},
    }
}

