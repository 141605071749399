import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import PropTypes from "prop-types";
import { MetaTags } from 'react-meta-tags'


class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            rows: [
                {
                    id: 1,
                    title: 'Feed de Produtos',
                    description: 'Esta opção personaliza os produtos, para o feed de produtos no instagram/facebook e google shopping.'
                },
                {
                    id: 2,
                    title: 'Feed Meta Facebook',
                    description: 'Esta opção permite customizar a lista de produtos, para disponibilizar no catalogo do meta business facebook e instagram.'
                },
                {
                    id: 3,
                    title: 'Feed Universal',
                    description: 'Esta opção permite customizar a lista de produtos, para disponibilizar no catalogo do meta business facebook e instagram e, Google Merchant.'
                },
            ]
        }

    }



    render() {
        const { sendForm, activeTab, getStepSelected } = this.props

        return (
            <div className="actions clearfix mt-2">
                <ul>
                    <li
                        className={
                            activeTab === 0 ? "previous disabled" : "previous"
                        }
                    >
                        <Link
                            to="#"
                            onClick={() => {
                                if (activeTab > 0) {
                                    getStepSelected(activeTab - 1)
                                }

                            }}
                        >
                            Anterior
                        </Link>
                    </li>
                    <li
                        className={"next"}
                    >
                        <Link
                            to="#"
                            onClick={() => {
                                if (activeTab < 1) {
                                    getStepSelected(activeTab + 1)
                                }
                                if (activeTab == 1) {
                                    sendForm()
                                }

                            }}
                        >
                            {activeTab < 1 ? 'Próximo' : 'Cadastrar'}
                        </Link>
                    </li>

                </ul>
            </div>
        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    activeTab: PropTypes.any,
    getStepSelected: PropTypes.func,
    sendForm: PropTypes.func,

    


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
