import React, { Component } from "react"
import {Card, CardBody, CardText, CardTitle, Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Delivery from "../../../assets/img/img_delivery.png";
import Crossing from "../../../assets/img/crossing.png";


class UITypePickupDelivery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [
                {
                    id: 1,
                    name: 'Quero que seja entregue!',
                    description: 'Esta opção permite o uso da frota de entregadores da pizzaria fontanella.',
                    image: Delivery
                },
                {
                    id: 2,
                    name: 'Deixa comigo, eu irei buscar!',
                    description: 'Esta opção permite a retirada do produto no balcão da pizzaria fontanella.',
                    image: Crossing
                },
            ],
        }

        this.handleNext = this.handleNext.bind(this);

    }

    componentDidMount() {
    }

    handleNext(){


    }


    render() {

        return (
            <Col sm={12}>
                <Row>
                    <Col sm={12}>
                        <div className="text-center  mb-4">
                            <h4 className="card-title">Nos Informe a forma de entrega</h4>
                            <small>em Pizzaria Fontanella</small>
                        </div>
                    </Col>
                    {
                        this.state.rows && this.state.rows.length > 0 &&
                            this.state.rows.map((item, index) => {
                                return (
                                    <Col sm={12} key={index}>
                                        <a onClick={() => this.props.funcParamPickup(item)}>
                                            <Card className="border-2">
                                                <div className="card-horizontal">
                                                    <div className="img-square-wrapper m-1">
                                                        <img className="rounded" src={item.image} alt=" " />
                                                    </div>
                                                    <Row>
                                                        <Col sm={12} className="m-1 ">
                                                            <p className="card-title font-bl-13-700h">{item.name}</p>
                                                            <p className="card-text font-bl-12-400h m-3">{item.description}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                        </a>
                                    </Col>
                                )
                            })
                    }

                </Row>
            </Col>
        )
    }
}

UITypePickupDelivery.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    getParamsModal: PropTypes.func,
    rows: PropTypes.array,
    funcParamPickup: PropTypes.func


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (UITypePickupDelivery)
)))
