import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"


const getReverseCoordinates = ({coordinates}) => get(url.GET_GEO_REVERSE, { params: coordinates })
    .catch(err => {
        throw err.response.data
    })

const getSearchAddress = params => get(url.GET_GEO_SEARCH_ADDRESS, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getReverseCoordinates,
    getSearchAddress,
}
