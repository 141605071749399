import {post} from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const postForgetPwd = data => post(url.POST_PASSWORD_FORGET, data)
    .then(response => {
        const {message} = response;
        NotificationManager.info(message, 'Aviso!');
        if (response.status >= 200 || response.status <= 299) return response.data
        throw response.data
    })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    postForgetPwd,
}
