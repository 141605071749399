export const GET_BY_ID_SOCIAL_MIDIA_PROMOTION = "GET_BY_ID_SOCIAL_MIDIA_PROMOTION"
export const GET_BY_ID_SOCIAL_MIDIA_PROMOTION_SUCCESS = "GET_BY_ID_SOCIAL_MIDIA_PROMOTION_SUCCESS"

export const UPLOAD_IMAGE_MIDIA_PROMOTION = "UPLOAD_IMAGE_MIDIA_PROMOTION"
export const UPLOAD_IMAGE_MIDIA_PROMOTION_SUCCESS = "UPLOAD_IMAGE_MIDIA_PROMOTION_SUCCESS"

export const UPDATE_MIDIA_PROMOTION = "UPDATE_MIDIA_PROMOTION"
export const UPDATE_MIDIA_PROMOTION_SUCCESS = "UPDATE_MIDIA_PROMOTION_SUCCESS"

export const FIND_SOCIAL_MIDIA_PROMOTION = "FIND_SOCIAL_MIDIA_PROMOTION"
export const FIND_SOCIAL_MIDIA_PROMOTION_SUCCESS = "FIND_SOCIAL_MIDIA_PROMOTION_SUCCESS"

export const CREATE_SOCIAL_MIDIA_PROMOTION = "CREATE_SOCIAL_MIDIA_PROMOTION"
export const CREATE_SOCIAL_MIDIA_PROMOTION_SUCCESS = "CREATE_SOCIAL_MIDIA_PROMOTION_SUCCESS"

export const PAGINATE_SOCIAL_MIDIA_PROMOTION = "PAGINATE_SOCIAL_MIDIA_PROMOTION"
export const PAGINATE_SOCIAL_MIDIA_PROMOTION_SUCCESS = "PAGINATE_SOCIAL_MIDIA_PROMOTION_SUCCESS"

export const FIND_SOCIAL_MIDIA_PROMOTION_API_ERROR = "FIND_SOCIAL_MIDIA_PROMOTION_API_ERROR"
 