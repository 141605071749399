export const FIND_PROMOTIONS_BY_CAMPAIGN = "FIND_PROMOTIONS_BY_CAMPAIGN"
export const FIND_PROMOTIONS_BY_CAMPAIGN_SUCCESS = "FIND_PROMOTIONS_BY_CAMPAIGN_SUCCESS"

export const FIND_PROMOTION_BY_ID = "FIND_PROMOTION_BY_ID"
export const FIND_PROMOTION_BY_ID_SUCCESS = "FIND_PROMOTION_BY_ID_SUCCESS"

export const CREATE_AND_ADD_CAMPAIGN_PROMOTION = "CREATE_AND_ADD_CAMPAIGN_PROMOTION"
export const CREATE_AND_ADD_CAMPAIGN_PROMOTION_SUCCESS = "CREATE_AND_ADD_CAMPAIGN_PROMOTION_SUCCESS"

export const REMOVE_CAMPAIGN_PROMOTION = "REMOVE_CAMPAIGN_PROMOTION"
export const REMOVE_CAMPAIGN_SUCCESS = "REMOVE_CAMPAIGN_SUCCESS"

export const ADD_CAMPAIGN_PROMOTION = "ADD_CAMPAIGN_PROMOTION"
export const ADD_CAMPAIGN_PROMOTION_SUCCESS = "ADD_CAMPAIGN_PROMOTION_SUCCESS"


export const FIND_PROMOTIONS_BY_CAMPAIGN_API_ERROR = "FIND_PROMOTIONS_BY_CAMPAIGN_API_ERROR"
