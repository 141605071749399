import React, { Component } from "react"
import {Card, CardBody, Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import EmptyCart from "../../../assets/img/empty_buy_cart.png";



class UICardEmptyCart extends Component {
    constructor(props) {
        super(props)
        this.state = { }

    }

    render() {

        return (
            <Col sm={12} className="px-5 text-center mt-5">
                <Row>
                    <Col sm={12}>
                        <div style={{height: "100% !important;"}}>
                            <img src={EmptyCart} className="img-fluid px-5 d-block mt-5" alt="Empty Cart" />
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="text-center  mb-4 mt-3">
                            <h1 className="card-title">Ops!</h1>
                            <h4 className="card-title">Carrinho vazio</h4>
                            <small>adicione ao menos um produto em seu carrinho de compra, para continuar com o pedido.</small>
                        </div>
                    </Col>
                </Row>

            </Col>
        )
    }
}

UICardEmptyCart.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    getParamsModal: PropTypes.func,


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (UICardEmptyCart)
)))
