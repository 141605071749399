import React from 'react'
import { CardBody } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Card, Col, Row, Stack } from "react-bootstrap"

import { createProductCampaign } from "../../../store/campaign/products/actions"


import UIFormCA_01 from './form_01/index'
import { toast } from 'react-hot-toast'


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            open: false,
            position: 'right',
            noOverlay: false,
            days_week: [],
            page: 0,

            description: null,
            title: null,
            time_scheduler: null,

            dispacher_meta_wa: 1,
            dispacher_meta_instagram: 1,
            dispacher_meta_messenger: 1,
            dispacher_sms: 1,
            dispacher_google_rcs: 1,
            dispacher_google_rbm: 1,
            days_week: [],
            products: [],
            category: null,
            category_id: null,

        }
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.getDayWeeks = this.getDayWeeks.bind(this);
        this.removeDayWeeks = this.removeDayWeeks.bind(this);
        this.check_duplicates_weeks = this.check_duplicates_weeks.bind(this)
        this.getTime = this.getTime.bind(this)
        this.handlerCreate = this.handlerCreate.bind(this)
        this.getFieldsForm = this.getFieldsForm.bind(this)
        this.getFormSuccess = this.getFormSuccess.bind(this)
    }
    setPosition(e) {
        this.setState({ position: e.target.value });
    }

    setNoOverlay(e) {
        this.setState({ noOverlay: e.target.checked });
    }

    toggleDrawer() {
        this.setState({ open: !this.state.open, isEdit: false });
    }

    closeDrawer() {
        this.setState({ open: false, openEdit: false, isEdit: false });
    }

    onDrawerClose() {
        this.setState({ open: false, isEdit: false });
    }


    componentDidMount() {


    }


    removeDayWeeks(item) {

        if (this.state.days_week.length >= 1) {
            this.state.days_week.map((d, index) => {
                if (d.id === item.id) {
                    this.state.days_week.splice(index, 1);
                }
            })
            this.setState({ days_week: this.state.days_week })

        } else {
            this.state.days_week.splice(0, this.state.days_week.length);
            this.setState({ days_week: this.state.days_week })

        }


    }
    getDayWeeks(item) {

        if (this.state.days_week.length > 0) {
            this.state.days_week.map((d, index) => {
                if (d.id !== item.id) {
                    this.state.days_week.push(item)
                }
            })
        }
        if (this.state.days_week.length === 0) {
            this.state.days_week.push(item)
        }

        this.setState({ days_week: this.state.days_week })
    }
    check_duplicates_weeks(days) {
        const { days_week } = this.state;
        days.map((day, index) => {
            if (this.state.days_week.length > 0) {
                this.state.days_week.map((day_selected, index_selected) => {
                    if (day.id == day_selected.id) {
                        if (!day.is_selected) {
                            days_week.splice(index_selected, 1)
                        }
                    }
                })
            } else {
                this.setState({ days_week: [] })

            }

        })
    }

    getTime(e) {
        const { id, name, value } = e.target
        let tempState = {}
        tempState[name] = value
        this.setState(tempState)

    }

    handlerCreate() {

        const {
            products,
            days_week,
            dispacher_meta_wa,
            dispacher_meta_instagram,
            dispacher_meta_messenger,
            dispacher_sms,
            dispacher_google_rcs,
            dispacher_google_rbm,
            description,
            title,
            category_id,
            time_scheduler } = this.state;


        this.props.createProductCampaign({
            promotions: [],
            products: [{ id: 0 }],
            category_id,
            days_week,
            dispacher_meta_wa,
            dispacher_meta_instagram,
            dispacher_meta_messenger,
            dispacher_sms,
            dispacher_google_rcs,
            dispacher_google_rbm,
            description,
            title,
            time_scheduler,
            type: 1,
            company_id: 1
        }, this.getFormSuccess)


    }
    getFieldsForm(values) {
        this.setState(values)

    }
    getFormSuccess(page) {
        this.setState({ page: page })

    }

    renderPage(page) {
        const { products, category_id } = this.state

        const { company_id } = this.props

        switch (page) {
            case 0:
            case 1:
                return <>
                    <UIFormCA_01
                        company_id={1}
                        getFieldsForm={this.getFieldsForm}
                        getTime={this.getTime}
                        getDayWeeks={this.getDayWeeks}
                        removeDayWeeks={this.removeDayWeeks}
                        check_duplicates_weeks={this.check_duplicates_weeks}
                        onCreate={this.handlerCreate} />

                    <div className="text-md-right ms-auto text-end" >
                        <button
                            onClick={() => {
                                this.handlerCreate()
                            }}
                            className="btn btn-dark btn-sm text-light"
                        >
                            <i className="bx bx-save" />
                            <span> Cadastrar</span>
                        </button>
                    </div>

                </>
            case 2:
            case 3:
                return <>Campanha Cadastrada com sucesso.</>
        }

    }

    render() {
        const { page } = this.state
        return <Card>
            <CardBody>
                {this.renderPage(page)}
            </CardBody>
        </Card>

    }


}

Index.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.any,

    createProductCampaign: PropTypes.func,
    rows: PropTypes.any,
    company_id: PropTypes.any,
    promotion_id: PropTypes.any,
    type: PropTypes.any,


}
const mapStateToProps = state => {
    const {
        loading,
        rows,
        id
    } = state.ProductsCategory

    return {
        loading,
        rows,
        id
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

    createProductCampaign
})(Index)
))
