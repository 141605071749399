import toast from "react-hot-toast"
import {beep_play} from "../../components/BeepAlert"

/***
 * canal responsável por ouvir atualizações da ordem de serviço por id.
 *
 * @param order_id
 * @returns {Promise<unknown>}
 */
const findOrderIdChannelListener = ({order_id}) => new Promise((resolve, reject) => {
 /*   let echo_chan = window.Echo
    echo_chan.disconnect()
    echo_chan.connect()
*/

    window.Echo.channel('chan-order-'.concat(order_id))
        .listen('.order.updated', (e) => {
            console.log("ORDER UPDT ", e)
            toast.success('Um novo pedido foi atualizado, verifique!')
            beep_play()
            resolve({e})
        })
        .error(error => {
            console.log('error ', error)
            toast.error('Ocorreu um erro no canal de ordem por ID')
            reject({error})
        })
})
const findCompanyIdChannelListener = ({company_id}) => new Promise((resolve, reject) => {
    /*let echo_chan = window.Echo
    echo_chan.disconnect()
    echo_chan.connect()*/
    window.Echo.channel('chan-orders-company-'.concat(company_id))
        .listen('.orders.list.by.company', (e) => {//'.orders.list.by.company'
            console.log("ORDER NEW ", e)
            toast.success('Surgiu um novo pedido.')
            beep_play()

            resolve({e})
        })
        .error(error => {
            console.log('error ', error)
            toast.error('Ocorreu um erro no canal de ordem por empresa')
            reject({error})
        })

})

export {
    findOrderIdChannelListener,
    findCompanyIdChannelListener
}
