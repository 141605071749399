import {
    FIND_ROUTES,
    FIND_ROUTES_SUCCESS,
    FIND_ROUTES_BY_NUMBER,
    FIND_ROUTES_BY_NUMBER_SUCCESS,
    UPDATE_STATUS_ROUTES,
    UPDATE_STATUS_ROUTES_SUCCESS,
    PRINTER_ROUTES,
    PRINTER_ROUTES_SUCCESS,
    ROUTES_API_ERROR
} from "./actionTypes"



const initialState = {
    loading: false,
    loading_detail: false,
    loading_delete: false,
    rows: [],
    current_page: 0,
    last_page: 0,
    total: 0,
    from: 0,
    to: 0,
    order: null,
    drivers: [],
    geojson: null,
    id: 0,
    order_created_at: null,
    status: 0,
    qtd_points:  null,
    time_route:  null,
    group_index: null,
    points: [],
}

const createOrEditRoutes = (state = initialState, action) => {

    switch (action.type) {
        case PRINTER_ROUTES:
            state = {
                ...state,
                loading: true
            }
            break
        case PRINTER_ROUTES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                loading: false
            }
            break
        case UPDATE_STATUS_ROUTES:
            state = {
                ...state,
                loading: true
            }
            break
        case UPDATE_STATUS_ROUTES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                loading: false
            }
            break
        case FIND_ROUTES_BY_NUMBER:
            state = {
                ...state,
                loading_detail: true
            }
            break
        case FIND_ROUTES_BY_NUMBER_SUCCESS:
            console.log('data ',action.payload)
            state = {
                ...state,
                id: action.payload.result.id,
                route_created_at: action.payload.result.route_created_at,
                status: action.payload.result.status,
                qtd_points: action.payload.result.qtd_points,
                time_route: action.payload.result.time_route,
                group_index: action.payload.result.group_index,
                points: action.payload.result.points,
                geojson: action.payload.result.linegeojson,
                drivers: action.payload.result.drivers,
                loading: false,
                loading_detail: false
            }
            break
        case FIND_ROUTES:
            state = {
                ...state,
                loading: true
            }
            break
        case FIND_ROUTES_SUCCESS:
            state = {
                ...state,
                current_page: action.payload.current_page,
                last_page: action.payload.last_page,
                total: action.payload.total,
                from: action.payload.from,
                to: action.payload.to,
                rows: action.payload.data,
                loading: false
            }
            break
        case ROUTES_API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditRoutes
