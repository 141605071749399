import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    PAGINATE_SOCIAL_MIDIA_PRODUCT,
    FIND_SOCIAL_MIDIA_PRODUCT,
    CREATE_SOCIAL_MIDIA_PRODUCT,
    GET_BY_ID_SOCIAL_MIDIA_PRODUCT,
    UPLOAD_IMAGE_MIDIA_PRODUCT,
    UPDATE_MIDIA_PRODUCT
} from "./actionTypes"


import {
    allSMProductsPaginateSuccess,
    createSMProductsSuccess,
    findSMProductsSuccess,
    findSMProductsIdSuccess,
    uploadSMProductsSuccess,
    editSMProductsSuccess,
    apiFindSMProductsError
} from "./actions"


import {
    findSMProductsAPI,
    createSMProductsAPI,
    getSMProductsById,
    postUploadSMProducts,
    putEditSMProducts,
    getSMProductsPaginate
} from "../../../helpers/sm_products_helper"

function* editSMProducts({payload: {params, goBack}}) {
    try {

        const response = yield call(putEditSMProducts, params)

        yield put(editSMProductsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        goBack(0)

    } catch (error) {
        yield put(apiFindSMProductsError(error))
    }
}

function* paginateSMProducts({payload: {params}}) {
    try { 

        const response = yield call(getSMProductsPaginate, params)

        yield put(allSMProductsPaginateSuccess(response))

    } catch (error) {
        yield put(apiFindSMProductsError(error))
    }
}


function* createSMProducts({ payload: { params,  history } }) {
    try {

        const response = yield call(createSMProductsAPI, params)
        const { message,  error } = response;

        yield put(createSMProductsSuccess(response))


        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiFindSMProductsError(error))
    }
}

function* findByIdSMProducts({payload: {id}}) {
    try {

        const response = yield call(getSMProductsById, id)

        yield put(findSMProductsIdSuccess(response))

    } catch (error) {
        yield put(apiFindSMProductsError(error))
    }
}

function* findSMProducts() {
    try {

        const response = yield call(findSMProductsAPI)
        const {data} = response;

        console.log('SAGA ',data)
        yield put(findSMProductsSuccess(data))

    } catch (error) {
        yield put(apiFindSMProductsError(error))
    }
}
function* uploadFindSMProducts({payload: {dataForm}}) {
    try {

        const response = yield call(postUploadSMProducts, dataForm)

        yield put(uploadSMProductsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiFindSMProductsError(error))
    }
}


function* CatalogSMProductsSaga() {
    yield takeEvery(GET_BY_ID_SOCIAL_MIDIA_PRODUCT, findByIdSMProducts)
    yield takeEvery(UPLOAD_IMAGE_MIDIA_PRODUCT, uploadFindSMProducts)
    yield takeEvery(FIND_SOCIAL_MIDIA_PRODUCT, findSMProducts)
    yield takeEvery(CREATE_SOCIAL_MIDIA_PRODUCT, createSMProducts)
    yield takeEvery(PAGINATE_SOCIAL_MIDIA_PRODUCT, paginateSMProducts)
    yield takeEvery(UPDATE_MIDIA_PRODUCT, editSMProducts)
}

export default CatalogSMProductsSaga
