import {
    FIND_HOME_BY_HASH_COMPANY,
    FIND_HOME_BY_HASH_COMPANY_SUCCESS,
    FIND_HOME_BY_QUERY_COMPANY,
    FIND_HOME_BY_QUERY_COMPANY_API_ERROR,
    FIND_HOME_BY_QUERY_COMPANY_SUCCESS
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    rows: [],
    id: 0,
}

const catalog = (state = initialState, action) => {

    switch (action.type) {
        case FIND_HOME_BY_HASH_COMPANY:
        case FIND_HOME_BY_QUERY_COMPANY:
            state = {
                ...state,
                rows: [],
                loading: true
            }
            break
        case FIND_HOME_BY_HASH_COMPANY_SUCCESS:
        case FIND_HOME_BY_QUERY_COMPANY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload.results,
                loading: false
            }
            break
        case FIND_HOME_BY_QUERY_COMPANY_API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default catalog
