import {
    LISTENER_ORDERS_CARD_ID_CHANNEL,
    LISTENER_ORDERS_CARD_ID_CHANNEL_SUCCESS,
    LISTENER_ORDERS_CHANNEL_BY_ID_COMPANY,
    LISTENER_ORDERS_CHANNEL_BY_ID_COMPANY_SUCCESS,
    ORDERS_CHANNEL_ERROR
} from "./actionTypes"



const initialState = {
    listener_order: false,
    listener_update_order: false,
    order: null

}

const createOrEditOrdersChannelListeners = (state = initialState, action) => {

    switch (action.type) {
        case LISTENER_ORDERS_CARD_ID_CHANNEL:
            state = {
                ...state,
                listener_order: false,
                listener_update_order: false,
            }
            break
        case LISTENER_ORDERS_CARD_ID_CHANNEL_SUCCESS:
            console.log(    'action.payload ', action.payload)
            state = {
                ...state,
                order: {
                    id: action.payload.id,
                    order_created_at: action.payload.order_created_at,
                    status: action.payload.status,
                    customer: action.payload.customer,
                    delivery_type: action.payload.delivery_type,
                    delivery_time: action.payload.delivery_time,
                },
                listener_order: false,
                listener_update_order: true
            }
            break
        case LISTENER_ORDERS_CHANNEL_BY_ID_COMPANY:
            state = {
                ...state,
                listener_order: true,
                listener_update_order: false
            }
            break
        case LISTENER_ORDERS_CHANNEL_BY_ID_COMPANY_SUCCESS:

            state = {
                ...state,
                order:{
                    id: action.payload.id,
                    order_created_at: action.payload.order_created_at,
                    status: action.payload.status,
                    customer: action.payload.customer,
                    delivery_type: action.payload.delivery_type,
                    delivery_time: action.payload.delivery_time,
                },
                listener_order: false,
                listener_update_order: false
            }
            break
        case ORDERS_CHANNEL_ERROR:
            state = {...state, error: action.payload, listener_order: false,  listener_update_order:false}
            break
        default:
            state = {...state, listener_order: false, listener_update_order: false}
            break
    }

    return state
}
export default createOrEditOrdersChannelListeners
