import toast from "react-hot-toast"
import {beep_play} from "../../components/BeepAlert"

/***
 * canal responsável por ouvir atualizações da sniper bot.
 *
 * @param order_id
 * @returns {Promise<unknown>}
 */
const findWhatsappIdChannelListener = ({company_id}) => new Promise(async(resolve, reject) => {

    await window.Echo.channel('chan-sniper-bot')
        .listen('.sniper.bot.options.log', response => {

            /* toast.success('QRCode SNIPER BOT Atualizado com sucesso')
              beep_play()*/
            resolve({response})
        })
        .error(error => {
            console.log('error ', error)
            toast.error('Ocorreu um erro no canal do sniper bot')
            reject({error})
        })
})


export {
    findWhatsappIdChannelListener
}
