import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import UICardEmptyCart from "../../components/UICardEmptyCart"
import UIBuyCartViewOrder from "../../components/UIBuyCartViewOrder"
import UITypePickupDelivery from "../../components/UITypePickupDelivery"
import UIResponsiblePickupDelivery from "../../components/UIResponsiblePickupDelivery"
import { NotificationManager } from "react-notifications"
import UIAddressSearchPickupDelivery from "../../components/UIAddressSearchPickupDelivery"
import UICoupomDiscountList from "../../components/UICoupomDiscountList"
import UIPaymentMethodsList from "../../components/UIPaymentMethodsList"
import UIStatusOrderProgress from "../../components/UIStatusOrderProgress"
import PropTypes from "prop-types";
/*import {
    apiCreateOrEditOrderError,
    createOrder,
    searchFeeDelivery,
    distanceCoordinates
} from "../../store/actions"*/

class FormBuyCart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            responsible: null,
            deliveryAddress: null,
            coupomDiscount: null,
            paymentMethod: null,
            typeDelivery: 0,
            rows: [],
            page: 1,
            channel_id: 8,
            valueSubTot: 0.0,
            valueTot: 0.0,
            rateDelivery: 0.0,
            discountValue: 0.0,
            shipping_fee: 0.0,
            load_get_fee_delivery: false,
            createOrder: false
        }

        this.paramPickupDelivery = this.paramPickupDelivery.bind(this)
        this.paramResponsibleDelivery = this.paramResponsibleDelivery.bind(this)
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.cartPage = this.cartPage.bind(this)
        this.paramAddressPickupDelivery = this.paramAddressPickupDelivery.bind(this)
        this.paramCouponsDiscounts = this.paramCouponsDiscounts.bind(this)
        this.paymentMethodsPage = this.paymentMethodsPage.bind(this)
        this.paramPaymentMethods = this.paramPaymentMethods.bind(this)
        this.paramTotalization = this.paramTotalization.bind(this)
        this.mountCartPreSubmission = this.mountCartPreSubmission.bind(this)
        this.nextPageSelected = this.nextPageSelected.bind(this)
        
    }


    componentDidMount() {
     
     
      

    }

    async mountCartPreSubmission() {

        let params_cart = {
            company_id: 1,
            customer: this.state.responsible,
            channel_id: this.state.channel_id,
            delivery_address: parseInt(this.state.typeDelivery) === 1 ? this.state.deliveryAddress : null,
            type_delivery: parseInt(this.state.typeDelivery),
            delivery_time: this.props.fee_delivery.time_wait,
            products: this.state.rows,
            payment: {
                method: this.state.paymentMethod,
                coupom_discount: this.state.coupomDiscount,
                subtotal: this.state.valueSubTot,
                total: this.state.valueTot,
                rate: this.state.rateDelivery,
                shipping_fee: this.props.fee_delivery.price,
                discount: this.state.discountValue,
                shipping_fee_list: this.props.fee_delivery


            }

        }


        this.props.createOrder(params_cart, this.props.history)
        //this.nextPageSelected(9)
        this.props.closeLayoutDrawer()

    }

    paymentMethodsPage() {
        if (this.state.page >= 1 && this.state.page <= 5) {
            this.setState({ page: 6 })
        }
    }

    cartPage() {

        if (this.state.page === 6) {
            this.setState({ page: 4 })
        }

    }


    nextPageSelected(page) {
        console.log('nextseldct ', page)
        if (page >= 1 && page <= 9) {
            this.setState({ page: page })
        }

    }

    nextPage() {

        if (this.state.page >= 1 && this.state.page <= 5) {
            this.setState({ page: this.state.page + 1 })
        }

    }

    previousPage() {
        if (this.state.page <= 6) {
            this.setState({ page: this.state.page - 1 })
        }

    }
    paramTotalization(item) {
        this.setState({
            rateDelivery: item.rateDelivery,
            discountValue: item.discountValue,
            valueTot: item.valueTot,
            valueSubTot: item.valueSubTot,
        })
    }
    async paramPaymentMethods(item) {

        await this.setState({ page: 4, createOrder: true, paymentMethod: item })
        await this.mountCartPreSubmission()

    }

    async paramCouponsDiscounts(item) {
        await this.setState({ coupomDiscount: item })
        await window.localStorage.setItem("coupom", btoa(JSON.stringify({ coupomDiscount: item })))
        this.previousPage()
    }

    paramResponsibleDelivery(event, values) {
        if (values.veritas === true) {
            window.localStorage.setItem("responsible", btoa(JSON.stringify({
                responsible: {
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    veritas: values.veritas,
                }
            })))
            //window.dispatchEvent(new Event("storageProfile"))
            this.setState({
                responsible: {
                    name: values.name,
                    email: values.email,
                    phone: ('55'.concat(values.phone)).replace(/[^0-9]/g, ''),
                    veritas: values.veritas,
                }
            })
            this.nextPage(values)
        } else {
            NotificationManager.danger("Marque a opção de informações verdadeiras", 'Aviso')
        }

    }

    paramPickupDelivery(item) {
        this.setState({ typeDelivery: item.id })
        window.localStorage.setItem("typeDelivery", btoa(JSON.stringify({ typeDelivery: item.id })))
        // window.dispatchEvent(new Event("storageProfile"))
        if (item.id === 1) {
            this.nextPage()
        } else {
            this.setState({ page: 3 })
        }

    }

    paramAddressPickupDelivery(item) {
        this.setState({ deliveryAddress: item })
        window.localStorage.setItem("addressPickupDelivery", btoa(JSON.stringify({ deliveryAddress: item })))
        // window.dispatchEvent(new Event("storage"))


        if (this.props.data_company != null && this.props.data_company.lat != 'undefined') {

            let coords_origin = item.coordinates.lat + ',' + item.coordinates.lng
            let coords_destination = this.props.data_company.lat + ',' + this.props.data_company.lng
            this.props.distanceCoordinates({
                latlng_origin: coords_origin,
                latlng_destination: coords_destination
            })
            this.setState({ load_get_fee_delivery: true })
        }
        this.nextPage()
    }

    renderPage() {
        const { page, load_get_fee_delivery, typeDelivery } = this.state
        const { duration, distance } = this.props

        return <UIBuyCartViewOrder
        funcParamTotalization={this.paramTotalization}
        deliveryAddress={this.state.deliveryAddress}
        typeDelivery={this.state.typeDelivery}
        fee_delivery={this.props.fee_delivery}
        coupomDiscount={this.state.coupomDiscount}
        openPaymentoMethodsPage={this.paymentMethodsPage}
        nextPage={this.nextPage}
        nextPageSelected={this.nextPageSelected}
        createOrder={this.state.createOrder}
        previousPage={this.previousPage}
        rows={this.state.rows} />

        if (parseInt(typeDelivery) === 1, distance !== null && distance.value !== null && load_get_fee_delivery === true && parseInt(distance.value) > 0) {
            this.props.searchFeeDelivery({
                company_id: 1,
                distance: (distance.value / 1000) <= 1000 ? (Math.ceil((distance.value / 1000)) * 1000) : 1000
            })
            this.setState({ load_get_fee_delivery: false })
        }

        if (page === 1) {
            return <UITypePickupDelivery funcParamPickup={this.paramPickupDelivery} />
        }
        if (page === 2) {
            return <UIAddressSearchPickupDelivery
                data={this.state.deliveryAddress}
                funcParamAddress={this.paramAddressPickupDelivery}
                previousPage={this.previousPage}
            />
        }
        if (page === 3) {
            return <UIResponsiblePickupDelivery
                previousPage={this.previousPage}
                data={this.state.responsible}
                funcParamResponsible={this.paramResponsibleDelivery} />
        }
        if (page === 4) {
            return <UIBuyCartViewOrder
                funcParamTotalization={this.paramTotalization}
                deliveryAddress={this.state.deliveryAddress}
                typeDelivery={this.state.typeDelivery}
                fee_delivery={this.props.fee_delivery}
                coupomDiscount={this.state.coupomDiscount}
                openPaymentoMethodsPage={this.paymentMethodsPage}
                nextPage={this.nextPage}
                nextPageSelected={this.nextPageSelected}
                createOrder={this.state.createOrder}
                previousPage={this.previousPage}
                rows={this.state.rows} />
        }
        if (page === 5) {
            return <UICoupomDiscountList
                funcParamCoupons={this.paramCouponsDiscounts}
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                rows={this.state.rows} />
        }//
        if (page === 6) {
            return <UIPaymentMethodsList
                funcParamPaymentMethods={this.paramPaymentMethods}
                nextPage={this.nextPage}
                previousPage={this.cartPage} />
        }
        if (page === 9) {
            return <UIStatusOrderProgress
                error={this.props.error}
                message={this.props.message}
                nextPage={this.nextPageSelected} />
        }
    }


    render() {
       
        return (
            <PerfectScrollbar>
                <Card className="react-scroll-mob">
                    <CardBody>
                        <Row className="align-items-md-center  mb-3" style={{ height: '100% !important' }}>
                            <Col>
                                <div className="text-end">
                                    <a onClick={this.props.closeLayoutDrawer}><i
                                        className="mdi mdi-close-thick mdi-18px" /></a>
                                </div>
                            </Col>

                            {
                                this.state.rows && !this.state.rows.length > 0
                                    ?

                                    <UICardEmptyCart />
                                    :


                                    this.renderPage()


                            }


                        </Row>
                    </CardBody>
                </Card>
            </PerfectScrollbar>
        )
    }


}

FormBuyCart.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    

    duration: PropTypes.any,
    distance: PropTypes.any,
    data_company: PropTypes.any,
    refresh: PropTypes.any,
    fee_delivery: PropTypes.any,
    closeLayoutDrawer: PropTypes.func,
    createOrder: PropTypes.func,
    distanceCoordinates: PropTypes.func,
    searchFeeDelivery: PropTypes.func


}
const mapStateToProps = state => {
  /*  const {
        loading,
        loading_form,
        error,
        message,
        fee_delivery
    } = state.OrderCart
    const { duration, distance, loading_map } = state.GeoLocation
*/
    return {
        /*fee_delivery,
        loading,
        loading_map,
        loading_form,
        error,
        message,
        duration,
        distance*/
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
   // createOrder,
   // searchFeeDelivery,
   // distanceCoordinates
})(
    (FormBuyCart)
)))
