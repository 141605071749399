import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";

// pega usuário da sessão local
const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser")
    if (user) return JSON.parse(user)
    return null
}

// Verifica se existe algum usuário logado
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null
}

const postLogin = data => post(url.POST_LOGIN, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postRecoveryAccess = data => post(url.POST_PASSWORD_FORGET, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getLoggedInUser,
    isUserAuthenticated,
    postLogin,
    postRecoveryAccess,
}
