import React from 'react'
import { CardBody } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Card, Col, Row, Stack } from "react-bootstrap"
import { findPromotionByCompanies } from "../../store/promotions/actions"
import BreadcrumbsCustom from 'components/BreadcrumbCustom'
import UIBodyCAProducts from './products/index'
import UIBodyCAPromotions from './promotions/Index'

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company_id: 1,
            promotion_selected: null,
            rows_ca: [
                {
                    id: 1,
                    name: '[CA][PROMOÇÕES]',
                    type: 1,
                    description: 'Está opção permite criar campanhas de ações promocionais.'
                },
                {
                    id: 2,
                    name: '[CA][PRODUTOS]',
                    type: 2,
                    description: 'Está opção permite criar campanhas de ações de produtos (Lançamento, exclusividade).'
                },
                {
                    id: 3,
                    name: '[CA][MENSAGENS]',
                    type: 3,
                    description: 'Está opção permite criar campanhas de ações de alertas e mensagens.'
                },
            ]
        }
        this.renderPage = this.renderPage.bind(this)
    }



    componentDidMount() {

    }

    renderPage(promotion) {
        switch (promotion.type) {
            case 1:
                return (<UIBodyCAPromotions
                    promotion_id={promotion.id}
                    type={promotion.type}
                    company_id={this.state.company_id} />);
            case 2:
                return (<UIBodyCAProducts
                    promotion_id={promotion.id}
                    type={promotion.type}
                    company_id={this.state.company_id} />);
        }
    }

    render() {
        const { promotion_selected } = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <BreadcrumbsCustom title='' breadcrumbItem='Campanhas' />
                    <Row>
                        <Col sm={3}>
                            <Row>
                                {
                                    this.state.rows_ca && this.state.rows_ca.length > 0 &&
                                    this.state.rows_ca.map((item, index) => {
                                        return (
                                            <Col key={index} className="col-12">
                                                <Card>
                                                    <CardBody>
                                                        <span
                                                            className="h4 card-title">{item.name}</span>
                                                        <p className="card-title-desc mt-2">
                                                            {item.description}
                                                        </p>
                                                        <a
                                                            onClick={() => {
                                                                this.setState({ promotion_selected: item })
                                                            }}
                                                            className="btn btn-dark btn-sm w-100 text-light"
                                                        >
                                                            <span> Conferir</span>
                                                        </a>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }

                            </Row>
                        </Col>
                        <Col sm={9}>

                            {
                                promotion_selected &&
                                this.renderPage(promotion_selected)
                            }

                        </Col>
                    </Row>

                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,

    findPromotionByCompanies: PropTypes.func,
    rows: PropTypes.any,

}
const mapStateToProps = state => {

    const { loading, rows } = state.Promotions

    return {
        loading,
        rows
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    findPromotionByCompanies
})(Index)
))
// 