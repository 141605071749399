import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const findProductByCampaignAPI = ({ page, limit, company_id }) => get(url.GET_PRODUCT_BY_CAMPAIGN, { params: { page, limit, company_id } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const findProductCampaignByIdAPI = ({ id }) => get(url.GET_CAMPAIGN_BY_ID.concat('/').concat(id))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const createProductCampaignAPI = data => post(url.POST_CREATE_CAMPAIGN, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })
const addProductCampaignAPI = data => post(url.POST_ADD_CAMPAIGN_PRODUCTS, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })
const removeProductCampaignAPI = data => del(url.DELETE_CAMPAIGN_PRODUCT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })
const getProductCampaignPaginateAPI = ({ company_id, page, limit }) => get(url.GET_CAMPAIGN_PRODUCT_PAGINATE, { params: { company_id, page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    findProductByCampaignAPI,
    findProductCampaignByIdAPI,
    createProductCampaignAPI,
    addProductCampaignAPI,
    getProductCampaignPaginateAPI,
    removeProductCampaignAPI
}
