import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    GET_CATEGORIES_PROMOTIONS,
} from "./actionTypes"


import {
    getCategorySectionPromotionSuccess,
    apiCategorySectionPromotionError
} from "./actions"


import {
    getCategoriesPromotionsAPI,

} from "../../helpers/categories_helper"

function* getCategorySectionPromotion({ payload: { params } }) {
    try {

        const response = yield call(getCategoriesPromotionsAPI, params)

        yield put(getCategorySectionPromotionSuccess(response))

    } catch (error) {
        yield put(apiCategorySectionPromotionError(error))
    }
}


function* categorySaga() {
    yield takeEvery(GET_CATEGORIES_PROMOTIONS, getCategorySectionPromotion)
}

export default categorySaga
