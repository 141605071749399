import {
    FIND_HOME_BY_HASH_COMPANY,
    FIND_HOME_BY_HASH_COMPANY_SUCCESS,
    FIND_HOME_BY_QUERY_COMPANY,
    FIND_HOME_BY_QUERY_COMPANY_API_ERROR,
    FIND_HOME_BY_QUERY_COMPANY_SUCCESS
} from "./actionTypes"


export const findHomeQueryCompany = query => {
    return {
        type: FIND_HOME_BY_QUERY_COMPANY,
        payload: {query},
    }
}

export const findHomeQueryCompanySuccess = results => {
    return {
        type: FIND_HOME_BY_QUERY_COMPANY_SUCCESS,
        payload: {results},
    }
}

export const findHomeHashCompany = hash => {
    return {
        type: FIND_HOME_BY_HASH_COMPANY,
        payload: {hash},
    }
}

export const findHomeHashCompanySuccess = results => {

    return {
        type: FIND_HOME_BY_HASH_COMPANY_SUCCESS,
        payload: {results},
    }
}


export const apiFindHomeCompanyError = error => {
    return {
        type: FIND_HOME_BY_QUERY_COMPANY_API_ERROR,
        payload: {error},
    }
}

