import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
    CardBody,
    Row, Col,
    TabContent, TabPane, NavItem, NavLink
} from "reactstrap"
import ImageURI from 'components/ImageURI'
import NumberFormat from 'components/NumberFormat'
import { Stack } from "react-bootstrap"
import UICardProducts_type_1 from './UICardProducts_type_1'
import UICardProducts_type_2 from './UICardProducts_type_2'
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

class UIProductsListSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        }
    }


    render() {
        return (
            <React.Fragment>
                <Row className="align-items-md-center p-2">
                    <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                            <h4 className="card-title mb-1">Produtos</h4>
                            <p><small>Escolha abaixo os produtos que farão parte da campanha promocional</small></p>
                        </div>
                        <div className="text-md-right ms-auto p-2">
                            <i onClick={this.props.closeLayoutDrawer} className="bx bx-x"></i>
                        </div>
                    </Col>
                </Row>
                <ul className="nav nav-tabs nav-tabs-custom">
                    {
                        this.props.rows.map((item, index) => {
                            return (
                                <>
                                    {
                                        item.promotions == 0 &&
                                        <NavItem key={index}>
                                            <NavLink

                                                onClick={(e) => {
                                                    console.log('clicou ', e)
                                                    console.log('clicou ', item)
                                                    this.setState({ activeTab: index })
                                                }}
                                                className={classnames({
                                                    active: this.state.activeTab === index,
                                                })}
                                            >
                                                {item.name}
                                            </NavLink>
                                        </NavItem>
                                    }
                                </>

                            )
                        })
                    }


                </ul>
                <div style={{ paddingBottom: 20 }}>
                    <TabContent
                        activeTab={this.state.activeTab}
                        className="p-2 "
                    >
                        {
                            this.props.rows.map((item, index) => {
                                return (
                                    <TabPane tabId={index} key={index}>
                                        <PerfectScrollbar style={{ height: "530px" }}>
                                            <Row>
                                                {
                                                    item.type === 1 &&
                                                    item.products.map((p, index) => {
                                                        return (
                                                            <UICardProducts_type_1
                                                                type={item.type}
                                                                product={p}
                                                                key={index}
                                                                visible_button={true}
                                                                addProductList={this.props.getProductSelected}
                                                                sizes={p.sizes}
                                                            />
                                                        )
                                                    })
                                                }

                                                {
                                                    item.type === 2 &&
                                                    item.groups.map((group, index) => {
                                                        return group.products.map((product, index) => {
                                                            return (
                                                                <UICardProducts_type_2
                                                                    type={item.type}
                                                                    product={product}
                                                                    visible_button={true}
                                                                    addProductList={this.props.getProductSelected}
                                                                    key={index}

                                                                />

                                                            )
                                                        })
                                                    })
                                                }

                                            </Row>
                                        </PerfectScrollbar>
                                    </TabPane>
                                )
                            })
                        }


                    </TabContent >
                </div>
            </React.Fragment>
        )
    }
}

UIProductsListSelect.propTypes = {
    key: PropTypes.any,
    product: PropTypes.any,
    rows: PropTypes.array,
    type: PropTypes.any,
    addProductList: PropTypes.func,
    closeLayoutDrawer: PropTypes.func,
    getProductSelected: PropTypes.func,
}

export default UIProductsListSelect
