import React, {Component} from "react"
import {Col, Row, Spinner} from "reactstrap";
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"

class ButtonCustom extends Component {

    constructor(props) {
        super(props);
    }


    render() {

        const {isLoading, message_loaging, children,
            col_size, animation, variant, size, clazz,
        clazzContainer} = this.props;

        return (
            <div className={clazzContainer ? clazzContainer : "mt-3 d-grid"}>
                {
                    isLoading
                        ?
                        <div className={"width-lg "+clazz}>
                            <Row className="text-center">
                                <Col lg={3} className="align-self-end">

                                </Col>
                                <Col lg={2} className="align-self-end">
                                    <Spinner size={size ? size : 'sm'} animation={animation ? animation : 'border'}
                                             variant={variant ? variant : 'light'} />
                                </Col>
                                <Col lg={4}>
                                    <span className="text-small text-light text-center mt-1" >{message_loaging}</span>
                                </Col>
                            </Row>

                        </div>

                        :
                        children
                }

            </div>

        )
    }
}
ButtonCustom.propTypes = {
    isLoading: PropTypes.any,
    message_loaging: PropTypes.any,
    children: PropTypes.node,
    col_size: PropTypes.any,
    animation: PropTypes.any,
    variant: PropTypes.any,
    size: PropTypes.any,
    clazz: PropTypes.any,
    clazzContainer: PropTypes.any


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, null)(
    (ButtonCustom)
)))
