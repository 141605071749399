import React, { Component } from "react"
import $ from "jquery";
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import { Form, Stack, InputGroup } from "react-bootstrap";
import './index.css';

import { del, get, post, put } from "../../helpers/api_helper"

import { NotificationManager } from "react-notifications";
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

/**
 * @param { id, label, value } inputProps
 * @param { função para setar o valor } onSourceSelect
 * { inputProps, onSourceSelect }
 */

class Autocomplete extends React.Component {

	constructor(props) {
		super(props);
		this.state = {}
		this.searchable = this.searchable.bind(this);
	}
	componentDidMount() {
		this.searchable()
	}

	searchable() {
		const { inputProps, onSourceSelect, company_id } = this.props;
		//const {_userFocusOutData} = this.state;
		try {

			$("#" + inputProps.id).autocomplete({
				delay: 2,
				autoFocus: true,
				autoFill: true,
				scrollHeight: 220,
				minLength: 2,
				source: function (request, response) {

					const dados = Object.entries(
						Object.assign({ query: request.term }, inputProps.data)
					).map(item => item.join('=')).join('&')

					try {

						get(inputProps.url + '?' + dados.concat(inputProps.param_id))
							.then(resp => {

								const { data } = resp;
								console.log('Data ret ', data)
								//_userFocusOutData = data

								response(data.map(item => {
									return {
										label: item.text,
										value: item.text,
										name: inputProps.id,
										code: item.id,
										id: item.id,
										data: item
									}
								}))

								$(`[name='${inputProps.id}']`).removeClass("ui-autocomplete-loading")

							})
							.catch(error => {

								if (error.code === 404) {
									NotificationManager.warning('Não existem registros para serem exibidos', 'Ops!', 3000);
								}

								if (error.code === 401) {
									NotificationManager.warning('Não Autenticado, faça o login novamente', 'Ops!', 3000);
								}

								$(`[name='${inputProps.id}']`).removeClass("ui-autocomplete-loading")

							}).then(response => {
							})

					} catch (error) {
						console.log('error', error)
					}

				},
				select: function (event, ui) {
					console.log("UI elect ", ui.item)
					onSourceSelect(ui.item)
				},
				change: function (event, ui) {
					console.log("UI Change ", ui.item)

					if (!ui.item) {

						onSourceSelect(ui.item)

					}

				},
				open: function () {
					$(this).removeClass("ui-corner-all").addClass("ui-corner-top");
				},
				close: function () {
					$(this).removeClass("ui-corner-top").addClass("ui-corner-all");
				}

			})

		} catch (error) {
			console.error('ERRROR ', error)
		}
	}




	render() {

		const {inputProps, onSourceSelect, nameFieldSelect} = this.props
		return (


			<Stack gap={2}>
				<div>
					<label htmlFor={this.props.inputProps.id} className="font-label">{this.props.inputProps.label}</label>

				</div>
				<div>
					<InputGroup>
						<InputGroup.Text id="basic-addon1"><i className="bx bx-search" /></InputGroup.Text>
						<Form.Control
							type="text"
							className="form-control "
							placeholder={inputProps.placeholder}
							id={inputProps.id}
							disabled={inputProps.enable ? inputProps.enable : false }
							name={inputProps.name}
							onChange={e => onSourceSelect({ value: e.target.value, name: inputProps.id, code: '' }, nameFieldSelect)}
							value={inputProps.value} />
					</InputGroup>

				</div>
			</Stack>
		)
	}

}


Autocomplete.propTypes = {
	google: PropTypes.object,
	location: PropTypes.object,
	t: PropTypes.any,
	company_id: PropTypes.any,
	inputProps: PropTypes.object,
	onSourceSelect: PropTypes.func,
	nameFieldSelect: PropTypes.any,


}
const mapStateToProps = state => {
	return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
	(Autocomplete)
)))
