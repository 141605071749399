import som_alert1 from "../assets/sound.mp3"
import som_alert2 from "../assets/sound2.mp3"
import som_alert3 from "../assets/sound3.mp3"
import som_alert4 from "../assets/sound4.mp3"
import som_alert5 from "../assets/sound5.mp3"
import som_alert6 from "../assets/sound6.mp3"

let context,
    //sound = [som_alert1, som_alert2, som_alert3, som_alert4, som_alert5],
    sound = [som_alert6, som_alert6, som_alert6, som_alert6, som_alert6],
    index = 0,
    execute = 0

function sound_start() {

    try {
        context = new AudioContext()
        const audio = new Audio(sound[index])

        const source = context.createMediaElementSource(audio)
        source.connect(context.destination)

        setTimeout(function () {
            audio.pause()
            execute = 0
            if (index < 4) {
                index += 1

            } else {
                if (index > 0) {
                    index -= 1
                } else {
                    index = 0
                }

            }

        }, 5980)

        // console.log(context.state)
        // console.log('audio ', audio.paused)
        if (execute === 0 && audio.paused || typeof (context.state) === "running") {
            audio.play().catch(e => {
                console.log('mp3 ', e)
            })
            execute = 1
        } else {
            audio.pause()
            context.resume().catch(e => {
                console.log('mp3 ', e)
            })
            execute = 0
        }
    } catch (e) {
        console.log('err ', e)
    }
}

function sound_stop() {
    sound_start()

}

function sound_play() {
    sound_stop()
}

export {
    sound_play,
    sound_start,
    sound_stop

}
