import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

// Login Redux States
import {LOGIN_USER, LOGOUT_USER} from "./actionTypes"
import {loginSuccess, logoutUserSuccess, apiError} from "./actions"


import {
    postLogin,
    postRecoveryAccess,
} from "../../../helpers/login_helper"

function* loginUser({payload: {user, history}}) {
    try {

        const response = yield call(postLogin, {
            email: user.email,
            password: user.password,
        })
        localStorage.setItem("authUser", JSON.stringify(response))
        yield put(loginSuccess(response))

        history.push("/dashboard")
    } catch (error) {
        yield put(apiError(error))
    }
}

function* logoutUser({payload: {history}}) {
    try {
        localStorage.removeItem("authUser")

        const response = yield call(postRecoveryAccess)
        yield put(logoutUserSuccess(response))

        history.push("/login")
    } catch (error) {
        yield put(apiError(error))
    }
}


function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser)
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
