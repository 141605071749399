import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import PropTypes from "prop-types";
import { MetaTags } from 'react-meta-tags'
import SMProducts from './products/index'
import SMPromotions from './promotions/index'

class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            path_page: '',
            rows: [
                {
                    id: 1,
                    title: 'Feed de Produtos',
                    path: 'product',
                    description: 'Esta opção personaliza os produtos, para o feed de produtos no instagram/facebook e google shopping.'
                },
                {
                    id: 2,
                    title: 'Feed de Promoções',
                    path: 'promotion',
                    description: 'Esta opção permite customizar a lista de produtos em promoção, para disponibilizar no catalogo e principais feeds.'
                },

            ]
        }

    }

    componentDidMount() {

    }

    renderPage(path_page) {

        switch (path_page) {
            case 'product':
                return (<SMProducts />);
            case 'promotion':
                return (<SMPromotions />);
        }

    }


    render() {
        const { path_page } = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Social Midia</title>
                    </MetaTags>
                    <h3>Social Midia</h3>
                    <Row>
                        <Col sm={3}>

                            <Row>
                                {
                                    this.state.rows.map((item, index) => {
                                        return (
                                            <Col key={index} sm={12}>
                                                <Card className="react-scroll-mob">
                                                    <CardBody>
                                                        <CardTitle>{item.title}</CardTitle>
                                                        <CardText>{item.description}</CardText>
                                                        <a
                                                            onClick={() => {
                                                                this.setState({ path_page: item.path })
                                                            }}
                                                            className="btn btn-dark btn-sm w-100 text-light"
                                                        >
                                                            <span> Conferir</span>
                                                        </a>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }

                            </Row>
                        </Col>
                        <Col sm={9}>
                            <Card>
                                <CardBody>
                                    {this.renderPage(path_page)}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    createSMProducts: PropTypes.func,
    findByIdSMProducts: PropTypes.func,
    uploadSMProducts: PropTypes.func,
    editSMProducts: PropTypes.func,

    id: PropTypes.any,
    category_gmerchant: PropTypes.any,
    category_metafacebook: PropTypes.any,
    product: PropTypes.any,
    company: PropTypes.any,
    ncm_gtin: PropTypes.any,
    brand: PropTypes.any,
    is_visible_meta: PropTypes.any,
    is_visible_gmerchant: PropTypes.any,
    is_visible_tiktok: PropTypes.any,
    is_visible_kwai: PropTypes.any,
    image: PropTypes.any,
    type_dir_file: PropTypes.any,
    url_image: PropTypes.any,
    url_video: PropTypes.any,
    description: PropTypes.any,
    tags: PropTypes.any,
    status: PropTypes.any,

}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
