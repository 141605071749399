import {
    LISTENER_ROUTES_CARD_ID_CHANNEL,
    LISTENER_ROUTES_CARD_ID_CHANNEL_SUCCESS,
    LISTENER_ROUTES_CHANNEL_BY_ID_COMPANY,
    LISTENER_ROUTES_CHANNEL_BY_ID_COMPANY_SUCCESS,
    ROUTES_CHANNEL_ERROR
} from "./actionTypes"


export const findRouteIdChannelListener = params => {
    return {
        type: LISTENER_ROUTES_CARD_ID_CHANNEL,
        payload: {params},
    }
}

export const findRouteIdChannelListenerSuccess = params => {
    return {
        type: LISTENER_ROUTES_CARD_ID_CHANNEL_SUCCESS,
        payload: params,
    }
}

export const findRouteCompanyIdChannelListener = params => {
    return {
        type: LISTENER_ROUTES_CHANNEL_BY_ID_COMPANY,
        payload: {params},
    }
}

export const findRouteCompanyIdChannelListenerSuccess = params => {
    return {
        type: LISTENER_ROUTES_CHANNEL_BY_ID_COMPANY_SUCCESS,
        payload: params,
    }
}

export const channelCreateOrEditRouteError = error => {
    return {
        type: ROUTES_CHANNEL_ERROR,
        payload: error,
    }
}

