//import som_alert from "../assets/sound.mp3"

let context,
    oscillator,
    contextGain,
    x = 0

function beep_start() {
    try {
        context = new AudioContext()
        /*const audio = new Audio(som_alert);
        const source = context.createMediaElementSource(audio);
        source.connect(context.destination);
       // audio.play()*/

        oscillator = context.createOscillator()
        contextGain = context.createGain()

        oscillator.type = 'sine'
        oscillator.connect(contextGain)
        contextGain.connect(context.destination)
        oscillator.start(0)
    } catch (e) {
        console.log('err ', e)
    }
}

function beep_stop() {
    beep_start()
    contextGain.gain.exponentialRampToValueAtTime(
        0.00001, context.currentTime + x
    )
}

function beep_play() {
    x = 1
    beep_stop()
}

export {
    beep_play,
    beep_start,
    beep_stop

}
