import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import PropTypes from "prop-types";
import { AvField, AvForm } from "availity-reactstrap-validation"
import Autocomplete from 'components/Autocomplete'


class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            category_gmerchant: null,
            category_metafacebook: null,
            product: null,
            company: null,
            brand: null,
        }
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)

    }

    handleAutocomplete(_data, name_entity) {

        if (_data) {
            const { value, name, code, data } = _data

            let newState = {}

            newState[name] = value
            newState[name_entity] = code

            //  console.log('newState ', newState)
            //   console.log('name_entity ', name_entity)

            this.setState(newState)

            if (data) {
                let newobj = {}
                newobj[name] = {
                    id: code,
                    name: value
                }

                this.props.getFieldsForm(newobj)
            }
        }
    }

    handleOnChange(e) {

        const { id, name, value } = e.target
        let newobj = {}
        newobj[name] = value
        this.props.getFieldsForm(newobj)
    }


    render() {


        return (
            <Row>
                <AvForm
                    className="form-horizontal"
                >
                    <Col sm={12} className="mt-2">

                        <Row>
                            <Col>
                                <Autocomplete
                                    onSourceSelect={this.handleAutocomplete}
                                    nameFieldSelect={'product_id'}
                                    limit={10}
                                    inputProps={{
                                        id: 'product',
                                        label: "Produto",
                                        placeholder: "Pesquisar produto...",
                                        value: this.state.product ? this.state.product : this.props.product,
                                        url: 'search/products'
                                    }} />
                            </Col>
                            <Col className='pt-2'>
                                <AvField
                                    name="brand"
                                    label="Marca do produto"
                                    className="form-control"
                                    placeholder="Ex: Coca cola"
                                    value={this.state.brand ? this.state.brand : this.props.brand}
                                    onChange={(e) => this.handleOnChange(e)}
                                    type="text"
                                />
                                {/*<Autocomplete
                                onSourceSelect={this.handleAutocomplete}
                                nameFieldSelect={'company_id'}
                                limit={10}
                                inputProps={{
                                    id: 'company',
                                    name: 'company_id',
                                    label: "Empresa",
                                    placeholder: "Pesquisar empresa...",
                                    value: this.state.company ? this.state.company : this.props.company,
                                    url: 'search/companies'
                                }} />*/}
                            </Col>

                        </Row>

                    </Col>
                    <Col sm={12} className="mt-2">
                        <Autocomplete
                            onSourceSelect={this.handleAutocomplete}
                            nameFieldSelect={'category_gmerchant_id'}
                            limit={10}
                            inputProps={{
                                id: 'category_gmerchant',
                                label: "Categoria Google Merchant",
                                placeholder: "Pesquisar categoria...",
                                value: this.state.category_gmerchant ? this.state.category_gmerchant : this.props.category_gmerchant,
                                url: 'search/categories/gmerchant'
                            }} />
                    </Col>

                    <Col sm={12} className="mt-3">
                        <Autocomplete
                            onSourceSelect={this.handleAutocomplete}
                            nameFieldSelect={'category_metafacebook_id'}
                            limit={10}
                            inputProps={{ 
                                id: 'category_metafacebook',
                                label: "Categoria Meta Facebook / instagram catalogo",
                                placeholder: "Pesquisar categoria...",
                                value: this.state.category_metafacebook ? this.state.category_metafacebook : this.props.category_metafacebook,
                                url: 'search/categories/mfacebook'
                            }} />
                    </Col>
                </AvForm>

            </Row>
        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    title: PropTypes.any,
    category_gmerchant: PropTypes.any,
    category_metafacebook: PropTypes.any,
    product: PropTypes.any,
    company: PropTypes.any,
    brand: PropTypes.any,
    getFieldsForm: PropTypes.func



}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
