import {
    FIND_SOCIAL_MIDIA_PROMOTION,
    FIND_SOCIAL_MIDIA_PROMOTION_SUCCESS,
    CREATE_SOCIAL_MIDIA_PROMOTION,
    CREATE_SOCIAL_MIDIA_PROMOTION_SUCCESS,
    PAGINATE_SOCIAL_MIDIA_PROMOTION,
    PAGINATE_SOCIAL_MIDIA_PROMOTION_SUCCESS,
    GET_BY_ID_SOCIAL_MIDIA_PROMOTION,
    GET_BY_ID_SOCIAL_MIDIA_PROMOTION_SUCCESS,
    UPLOAD_IMAGE_MIDIA_PROMOTION,
    UPLOAD_IMAGE_MIDIA_PROMOTION_SUCCESS,
    UPDATE_MIDIA_PROMOTION,
    UPDATE_MIDIA_PROMOTION_SUCCESS,
    FIND_SOCIAL_MIDIA_PROMOTION_API_ERROR,
} from "./actionTypes"


const initialState = {
    loading: false,
    error: false,
    message: '',

    id: 0,
    loading: false,
    refresh: false,
    loading_delete: false,
    rows: [],
    current_page: 0,
    last_page: 0,
    total: 0,
    from: 0,
    to: 0,

    promotion: '',
    product: '',
    company: '',
    is_visible_meta: 0,
    is_visible_gmerchant: 0,
    is_visible_tiktok: 0,
    is_visible_kwai: 0,
    image: '',
    type_dir_file: '',
    url_image: '',
    url_video: '',
    description: '',
    tags: '',
    status: 0,
    id: 0,
}

const SMPromotions = (state = initialState, action) => {

    switch (action.type) {
        case PAGINATE_SOCIAL_MIDIA_PROMOTION:
            state = {
                ...state,
                loading: true,
                refresh: false,
            }
            break
        case PAGINATE_SOCIAL_MIDIA_PROMOTION_SUCCESS:
            state = {
                ...state,
                current_page: action.payload.result.current_page,
                last_page: action.payload.result.last_page,
                total: action.payload.result.total,
                from: action.payload.result.from,
                to: action.payload.result.to,
                rows: action.payload.result.data,
                refresh: false,
                loading: false
            }
            break
        case UPDATE_MIDIA_PROMOTION:
        case UPLOAD_IMAGE_MIDIA_PROMOTION:
            state = { ...state, loading: true }
            break
        case UPLOAD_IMAGE_MIDIA_PROMOTION_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case GET_BY_ID_SOCIAL_MIDIA_PROMOTION:
            state = { ...state, loading: false, loading_form: true }
            break
        case GET_BY_ID_SOCIAL_MIDIA_PROMOTION_SUCCESS:
    
console.log('Promotions ', action.payload.result)
            state = {
                ...state,
                loading: false,
                loading_form: false,

                id: action.payload.result.id,
                promotion: action.payload.result.promotion.text,
                product: action.payload.result.product.text,
                company: action.payload.result.company.text,
                is_visible_meta: action.payload.result.is_visible_meta,
                is_visible_gmerchant: action.payload.result.is_visible_gmerchant,
                is_visible_tiktok: action.payload.result.is_visible_tiktok,
                is_visible_kwai: action.payload.result.is_visible_kwai,
                image: action.payload.result.image,
                type_dir_file: action.payload.result.type_dir_file,
                url_image: action.payload.result.url_image,
                url_video: action.payload.result.url_video,
                description: action.payload.result.description,
                tags: action.payload.result.tags,
                status: action.payload.result.status,

            }
            break

        case CREATE_SOCIAL_MIDIA_PROMOTION:
        case FIND_SOCIAL_MIDIA_PROMOTION:
            state = {
                ...state,
                loading: true
            }
            break
        case FIND_SOCIAL_MIDIA_PROMOTION_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload.data,
                loading: false
            }
            break
        case UPDATE_MIDIA_PROMOTION_SUCCESS:
        case CREATE_SOCIAL_MIDIA_PROMOTION_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_SOCIAL_MIDIA_PROMOTION_API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default SMPromotions
