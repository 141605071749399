import {
    FIND_LOG_SNIPER_BOT,
    FIND_LOG_SNIPER_BOT_SUCCESS,
    RESTART_CMD_SNIPER_BOT,
    RESTART_CMD_SNIPER_BOT_SUCCESS,
    STOP_CMD_SNIPER_BOT,
    STOP_CMD_SNIPER_BOT_SUCCESS,
    FIND_LOG_SNIPER_BOT_API_ERROR,
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    loading_btn_restart: false,
    loading_btn_stop: false,
    error: false,
    message: '',

    rows: [],
    id: 0,
    company_id: 0
}

const sniperBotLog = (state = initialState, action) => {

    switch (action.type) {
        case RESTART_CMD_SNIPER_BOT:
            state = {
                ...state,
                loading: false,
                loading_btn_stop: false,
                loading_btn_restart: true
            }
            break
        case RESTART_CMD_SNIPER_BOT_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false,
                loading_btn_stop: false,
                loading_btn_restart: false
            }
            break
        case STOP_CMD_SNIPER_BOT:
            state = {
                ...state,
                loading: false,
                loading_btn_stop: true,
                loading_btn_restart: false
            }
            break
        case STOP_CMD_SNIPER_BOT_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false,
                loading_btn_stop: false,
                loading_btn_restart: false
            }
            break
        case FIND_LOG_SNIPER_BOT:
            state = {
                ...state,
                loading: true
            }
            break
        case FIND_LOG_SNIPER_BOT_SUCCESS:

            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload.data.result.logs,
                loading: false
            }
            break
        case FIND_LOG_SNIPER_BOT_API_ERROR:
            state = {...state, error: action.payload, loading: false,
                loading_btn_stop: false,
                loading_btn_restart: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default sniperBotLog
