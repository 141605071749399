import {
    GET_DAYS_WEEK_BY_COMPANIES,
    GET_DAYS_WEEK_BY_COMPANIES_SUCCESS,
    GET_DAYS_WEEK_BY_COMPANIES_API_ERROR
} from "./actionTypes"


export const getDaysWeekByCompanies = params => {
    return {
        type: GET_DAYS_WEEK_BY_COMPANIES,
        payload: { params },
    }
}

export const getDaysWeekByCompaniesSuccess = params => {
    return {
        type: GET_DAYS_WEEK_BY_COMPANIES_SUCCESS,
        payload: params,
    }
}

export const apiDaysWeekByCompaniesError = error => {
    return {
        type: GET_DAYS_WEEK_BY_COMPANIES_API_ERROR,
        payload: { error },
    }
}

