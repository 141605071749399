import { all, fork, spawn } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import LayoutSaga from "./layout/saga"
import HomeSaga from "./catalog/home/saga"
import ProductsCategorySaga from "./catalog/productsByCategory/saga"
import ProductsCategorySizeSaga from "./catalog/productsByCategorySize/saga"
import PaymentMethodsSaga from "./catalog/paymentMethods/saga"
import CouponsDiscountsSaga from "./coupomDiscounts/saga"
import OrdersSaga from "./order/saga"
import RoutesSaga from "./routes/saga"
import LocationSaga from "./search/location/saga"
import SniperBOTSaga from "./whatsapp/saga"
import PromotionsSaga from "./promotions/saga"
import CompanySaga from "./company/saga"
import CategorySaga from "./category/saga"

import OrdersListenersSaga from "./channels/orders/saga"
import RoutesListenersSaga from "./channels/routes/saga"
import WhatsappListenersSaga from "./channels/whatsapp/saga"

import SMProductsSaga from "./socialmidia/products/saga"
import SMPromotionsSaga from "./socialmidia/promotions/saga"

import CampaingnProductsSaga from "./campaign/products/saga"
import CampaingnPromotionsSaga from "./campaign/promotions/saga"


export default function* rootSaga() {
  yield all([


    //public
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(LayoutSaga),
    fork(HomeSaga),
    fork(SniperBOTSaga),
    fork(LocationSaga),
    fork(OrdersSaga),
    fork(RoutesSaga),
    fork(ProductsCategorySaga),
    fork(ProductsCategorySizeSaga),
    fork(PaymentMethodsSaga),
    fork(CouponsDiscountsSaga),
    fork(PromotionsSaga),
    fork(CompanySaga),
    fork(CategorySaga),

    //channels
    fork(OrdersListenersSaga),
    fork(RoutesListenersSaga),
    fork(WhatsappListenersSaga),
    fork(SMProductsSaga),
    fork(SMPromotionsSaga),
    fork(CampaingnProductsSaga),
    fork(CampaingnPromotionsSaga),


  ])
}
