import React from 'react'
import {
    Badge,
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { findOrders, findOrdersNumber, updateStatusOrders, printerOrders } from "../../store/order/actions"
import NumberFormat from "../../components/NumberFormat"
import { Spinner } from "react-bootstrap"
import { findOrderIdChannelListener, findOrderCompanyIdChannelListener } from "../../store/channels/orders/actions"
import { sound_play } from "../../components/SoundPlayerMP3"
import toast from "react-hot-toast"
import UICardListProductType_1 from "./UICardListProductType_1"
import UICardListProductType_2 from "./UICardListProductType_2"
import UICardListProductType_3 from "./UICardListProductType_3"

const rows_ids = []
class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            order_id: 0,
            load_rows: false,
            reverse: true,
            load_chanel_ids: true,
            rows_ids: [],
            rows: [],
        }
        this._showingstatuslabel = this._showingstatuslabel.bind(this)
        this._showingsbuttonsservices = this._showingsbuttonsservices.bind(this)
        this._idOrderSelect = this._idOrderSelect.bind(this)
        this.playAudio = this.playAudio.bind(this)
    }

    async playAudio() {

        const { rows } = this.props
        if (typeof (rows) != "undefined") {
            if (rows.length > 0) {
                rows.map((item, index) => {
                    if (item.status === 0) {
                        sound_play()
                        toast.error("Aceite ou cancele os pedidos")
                    }
                })
            }
        }


    }

    componentDidMount() {
        this.props.findOrderCompanyIdChannelListener({ company_id: 1 })
        this.props.findOrders({ page: 1, limit: 100, company_id: 1 })
        setInterval(this.playAudio, 6000)

    }

    _idOrderSelect(item) {
        this.props.findOrdersNumber(item.id)
    }

    _showingsbuttonsservices(value, index) {
        console.log(value.status)

        switch (parseInt(value.status)) {

            case 1:
                return (
                    <Col sm={12} className={"text-end mt-2"}>
                        <a onClick={() => {
                            this.props.updateStatusOrders({
                                order_id: value.id,
                                order_created_at: value.order_created_at,
                                delivery_type: value.delivery_type,
                                delivery_time: value.delivery_time,
                                status: 3,
                                customer: value.customer,
                            })
                            // this.setState({reverse: false})
                            this.props.rows[index]['status'] = 3
                        }} className="btn btn-danger btn-sm">
                            <span className="text-center text-light">
                                <i className="text-center mdi mdi-16px text-light mdi-cancel" />
                                <span className="px-2 mb-1 font-size-12">Cancelar</span>
                            </span>
                        </a>
                    </Col>
                )
            //case  2:
            case 0:
                // case  3:
                return (<>
                    <Col sm={parseInt(value.status) == 0 ? 6 : 12} className={parseInt(value.status) == 0 ? "text-start mt-2" : "text-end mt-2"}>
                        <a onClick={() => {
                            this.props.updateStatusOrders({
                                order_id: value.id,
                                order_created_at: value.order_created_at,
                                delivery_type: value.delivery_type,
                                delivery_time: value.delivery_time,
                                status: 2,
                                customer: value.customer,
                            })
                            // this.setState({reverse: false})
                            this.props.rows[index]['status'] = 2
                        }} className="btn btn-danger btn-sm">
                            <span className="text-center text-light">
                                <i className="text-center mdi mdi-16px text-light mdi-cancel" />
                                <span className="px-2 mb-1 font-size-12">Recusar</span>
                            </span>
                        </a>
                    </Col>
                    {
                        parseInt(value.status) <= 0 &&
                        <Col sm={6} className="text-end mt-2">
                            <a onClick={() => {
                                this.props.updateStatusOrders({
                                    order_id: value.id,
                                    order_created_at: value.order_created_at,
                                    delivery_type: value.delivery_type,
                                    delivery_time: value.delivery_time,
                                    status: 1,
                                    customer: value.customer,
                                })
                                this.props.rows[index]['status'] = 1
                                // this.setState({reverse: false})
                            }} className="btn btn-success btn-sm">
                                <span className="text-center text-light">
                                    <i className="text-center mdi mdi-16px text-light mdi-check-all" />
                                    <span className="px-2 mb-1 font-size-12">Aceitar</span>
                                </span>
                            </a>
                        </Col>
                    }

                </>)

            /* case 1:
                 return (<span>  </span>)
             case 2:
                 return (<span>  </span>)
             case 3:
                 return (<span> </span>)*/
            default:
                return (<span>  </span>)
        }
    }

    _showingstatuslabel(value, index) {
        this.props.rows[index]['status'] = value
        switch (parseInt(value)) {
            case 0:
                return (<span className="badge bg-danger p-1">Pendente</span>)
            case 1:
                return (<span className="badge  bg-warning p-1"> Em Preparo </span>)
            case 2:
                return (<span className="badge  bg-danger p-1"> Rejeitado </span>)
            case 3:
            case 4:
                return (<span className="badge  bg-dark p-1"> Cancelado </span>)
            case 5:
                return (<span className="badge  bg-success p-1"> Despachado </span>)
            default:
                return (<span className="badge  bg-danger p-1"> Pendente </span>)
        }

    }

    /* componentDidUpdate(prevProps, prevState, snapshot) {
  
            if (this.props.rows.length > 0) {
                this.props.rows.reverse()
                console.log('props rows reverse')
            }
        }*/


    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Row>
                        <Col sm={3}>
                            <Card>
                                <CardBody>
                                    <Row className="align-items-md-center  mb-3">
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <span className="h4 card-title">{this.props.t("Orders")}</span>
                                            </div>
                                            <div className="text-md-right ms-auto mb-2">
                                                <a onClick={() => {
                                                    this.props.findOrders({ page: 1, limit: 100, company_id: 1 })

                                                }} className="btn btn-dark btn-sm text-light">
                                                    <i className="bx bx-sync" />
                                                    {this.props.t("Update")}
                                                </a>
                                            </div>
                                        </Col>
                                        {
                                            this.props.rows.length > 0 &&
                                            this.props.rows.map((item, index) => {
                                                if (rows_ids.length === 0) {
                                                    this.props.findOrderIdChannelListener({ order_id: item.id })
                                                }
                                                if (rows_ids.indexOf(item.id) === -1) {
                                                    this.props.findOrderIdChannelListener({ order_id: item.id })
                                                    rows_ids.push(item.id)
                                                }

                                                return (
                                                    <Col key={index} sm={12} className="mb-1">
                                                        <a onClick={() => {
                                                            this._idOrderSelect(item)
                                                        }}>
                                                            <div className=" border border-1 ">
                                                                <Row className="p-2">
                                                                    <Col sm={12}>
                                                                        <h6 className="text-truncate font-size-15 text-dark">
                                                                            Cliente: {item.customer.name}
                                                                        </h6>
                                                                        <span className="text-muted font-size-12">
                                                                            Pedido Nº.: #{item.id}
                                                                        </span>
                                                                    </Col>

                                                                    {
                                                                        item.status === 0 || item.status === 1
                                                                            ?
                                                                            <Col sm={12}>
                                                                                <Row>
                                                                                    <Col className="text-start">
                                                                                        <Badge
                                                                                            color="dark"
                                                                                            className={"bg-dark"}
                                                                                        >
                                                                                            Entregar até {item.order_delivery_at}
                                                                                        </Badge>
                                                                                    </Col>
                                                                                    <Col className="text-end">
                                                                                        {this._showingstatuslabel(item.status, index)}
                                                                                    </Col>
                                                                                </Row>

                                                                            </Col>
                                                                            :
                                                                            <Col sm={12}>
                                                                                {this._showingstatuslabel(item.status, index)}


                                                                            </Col>
                                                                    }
                                                                    {this._showingsbuttonsservices(item, index)}

                                                                </Row>
                                                            </div>
                                                        </a>
                                                    </Col>
                                                )


                                            })
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        {
                            this.props.loading_detail
                                ?
                                <Col sm={9}>
                                    <Card>
                                        <CardBody>
                                            <div className="text-center"
                                                style={{ width: '100%', position: 'relative', paddingBottom: 20 }}>
                                                <Spinner size="sm" animation="border"
                                                    style={{ marginTop: '10%' }}
                                                    variant="dark" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                :
                                this.props.customer &&
                                <Col sm={9}>
                                    <Card>
                                        <CardBody>
                                            <Row className="h-100">
                                                <Col sm={12} className="mb-3">
                                                    <h6 className="text-truncate font-size-13 text-black font-weight-semibold">Cliente: {this.props.customer.name} </h6>
                                                    <h6 className="text-truncate font-size-13 text-black font-weight-semibold">
                                                        <span className="text-muted">Pedido #{this.props.id}</span>
                                                        <span
                                                            className="text-muted px-1"> - {this.props.order_created_at}</span>
                                                    </h6>

                                                    <h6 className="text-muted mb-2">{this.props.customer.contact}</h6>
                                                    {this.props.delivery_type === 2 &&
                                                        <Badge
                                                            color="danger"
                                                            className={"danger p-1"}
                                                        >
                                                            <i className="text-center mdi  text-light mdi-map-marker" />
                                                            <span
                                                                className="px-1">Cliente irá retirar o pedido na loja</span>
                                                        </Badge>
                                                    }
                                                </Col>
                                                {
                                                    this.props.delivery_type === 1 &&
                                                    <Col sm={12} className="mb-2">
                                                        <div className=" border border-1 p-2">
                                                            <Badge
                                                                color="danger"
                                                                className={"danger p-1"}
                                                            >
                                                                <i className="text-center mdi  text-light mdi-map-marker" />
                                                                <span className="px-1">Entregar em:</span>
                                                            </Badge>

                                                            <h6 className="text-truncate font-size-13 text-muted pt-3">
                                                                {this.props.address_delivery.address}
                                                            </h6>
                                                        </div>
                                                    </Col>
                                                }

                                                <Col sm={12}>
                                                    <div className=" border border-1 p-3">
                                                        <Badge
                                                            color="warning"
                                                            className={"warning p-1"}
                                                        >
                                                            Produtos
                                                        </Badge>

                                                        <Row className="pt-2">
                                                            {
                                                                this.props.group.map((item, index) => {
                                                                    return (
                                                                        <Col sm={12} key={index}>
                                                                            <Row>
                                                                                <Col sm={12}>
                                                                                    <h6 className="text-truncate font-size-13 text-dark font-weight-semibold text-start">{item.name}</h6>
                                                                                </Col>
                                                                                <Col sm={12}>
                                                                                    <Row>
                                                                                        {
                                                                                            item.products.map((product, index2) => {

                                                                                                return (
                                                                                                    <Col sm={12} key={index2}>
                                                                                                        <Row>
                                                                                                            {
                                                                                                                parseInt(product.is_half_product) === 2
                                                                                                                    ?
                                                                                                                    <UICardListProductType_1
                                                                                                                        index_current={index2}
                                                                                                                        product_name={product.description_order != null ? product.description_order : product.name}
                                                                                                                        product_qtd={product.qtd}
                                                                                                                        prod_type={item.type}
                                                                                                                        size_name={product.size ? product.size.name : ''}
                                                                                                                        qtd_pieces={product.size ? product.size.qtd_pieces : ''}
                                                                                                                        edge_name={product.edge ? product.edge.name : ''}
                                                                                                                        pasta_name={product.pasta ? product.pasta.name : ''}
                                                                                                                        product_price={product.price}
                                                                                                                        edge_price={product.edge ? product.edge.price : ''}
                                                                                                                        pasta_price={product.pasta ? product.pasta.price : ''}
                                                                                                                        product_description={product.description}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    parseInt(product.is_half_product) === 3
                                                                                                                        ?
                                                                                                                        <UICardListProductType_3
                                                                                                                            index_current={index2}
                                                                                                                            product_name={product.description_order != null ? product.description_order : product.name}
                                                                                                                            product_qtd={product.qtd}
                                                                                                                            prod_type={item.type}
                                                                                                                            product_price={product.price}
                                                                                                                            product_description={product.description}
                                                                                                                            size_name={product.size ? product.size.name :  null}
                                                                                                                            size_price={product.size ? product.size.price :  null}
                                                                                                                            
                                                                                                                            qtd_pieces={product.size ? product.size.qtd_pieces :  null}
                                                                                                                            edge_name={product.edge ? product.edge.name :  null}
                                                                                                                            edge_price={product.edge  ? product.edge.price :  null}
                                                                                                                           
                                                                                                                            pasta_name={product.pasta ? product.pasta.name :  null}
                                                                                                                            pasta_price={product.pasta ? product.pasta.price : null}

                                                                                                                        />
                                                                                                                        :
                                                                                                                        <UICardListProductType_2
                                                                                                                            index_current={index2}
                                                                                                                            product_name={product.description_order != null ? product.description_order : product.name}
                                                                                                                            product_qtd={product.qtd}
                                                                                                                            prod_type={item.type}
                                                                                                                            size_name={product.size ? product.size.name : ''}
                                                                                                                            qtd_pieces={product.size ? product.size.qtd_pieces : ''}
                                                                                                                            edge_name={product.edge ? product.edge.name : ''}
                                                                                                                            pasta_name={product.pasta ? product.pasta.name : ''}
                                                                                                                            product_price={product.price}
                                                                                                                            edge_price={product.edge ? product.edge.price : ''}
                                                                                                                            pasta_price={product.pasta ? product.pasta.price : ''}
                                                                                                                            product_description={product.description}
                                                                                                                        />
                                                                                                            }
                                                                                                        </Row>
                                                                                                    </Col>

                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>

                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                            <Col sm={12} className="mt-3">
                                                                <h6 className="text-truncate font-size-13 text-dark font-weight-semibold text-start">Pagamento </h6>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <h6 className="text-truncate font-size-10 text-muted font-weight-semibold text-start">
                                                                    {this.props.payment_method.name}
                                                                </h6>
                                                                {
                                                                    this.props.delivery_type === 1 &&
                                                                    <h6 className="text-truncate font-size-10 text-muted font-weight-semibold text-start">
                                                                        Taxa de Entrega
                                                                    </h6>
                                                                }
                                                                {
                                                                    parseFloat(this.props.payment_method.discount) > 0 &&
                                                                    <h6 className="text-truncate font-size-10 text-muted font-weight-semibold text-start">
                                                                        Desconto
                                                                    </h6>
                                                                }
                                                                <h6 className="text-truncate font-size-10 text-muted font-weight-semibold text-start">
                                                                    SubTotal
                                                                </h6>
                                                                <h6 className="text-truncate font-size-10 text-muted font-weight-semibold text-start">
                                                                    Total
                                                                </h6>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <p className="text-truncate font-size-10 text-muted text-end"></p>
                                                                {
                                                                    this.props.delivery_type === 1 &&
                                                                    <h6 className="text-truncate font-size-10 text-muted text-end">
                                                                        <NumberFormat value={this.props.payment_method.shipping_fee} />
                                                                    </h6>
                                                                }
                                                                {
                                                                    parseFloat(this.props.payment_method.discount) > 0 &&
                                                                    <h6 className="text-truncate font-size-10 text-muted text-end">
                                                                        <NumberFormat value={this.props.payment_method.discount} />
                                                                    </h6>
                                                                }
                                                                <h6 className="text-truncate font-size-10 text-muted text-end">
                                                                    <NumberFormat value={this.props.payment_method.subtotal} />
                                                                </h6>
                                                                <h6 className="text-truncate font-size-10 text-muted text-end">
                                                                    <NumberFormat value={this.props.payment_method.total} />
                                                                </h6>
                                                            </Col>

                                                        </Row>

                                                        {
                                                            this.props.status === 0 || this.props.status === 1 &&
                                                            <Row>
                                                                <Col sm={6} className="text-start">
                                                                    <a onClick={() => {
                                                                        this.props.printerOrders({ company_id: 1, order_id: this.props.id })
                                                                    }}
                                                                        className="btn btn-dark btn-sm">
                                                                        <span className="text-center text-light">
                                                                            <i className="text-center mdi mdi-16px text-light mdi-printer" />
                                                                            <span
                                                                                className="px-2 mb-1 font-size-10">IMPRIMIR</span>
                                                                        </span>
                                                                    </a>
                                                                </Col>
                                                                <Col sm={6} className="text-end">
                                                                    <a onClick={() => {
                                                                        this.props.updateStatusOrders({
                                                                            order_id: this.props.id,
                                                                            order_created_at: this.props.order_created_at,
                                                                            delivery_type: this.props.delivery_type,
                                                                            delivery_time: this.props.delivery_time,
                                                                            status: 5,
                                                                            customer: this.props.customer,
                                                                        })
                                                                    }}
                                                                        className="btn btn-success btn-sm">
                                                                        <span className="text-center text-light">
                                                                            <i className="text-center mdi mdi-16px text-light mdi-bike-fast" />
                                                                            <span
                                                                                className="px-2 mb-1 font-size-10">DESPACHAR</span>
                                                                        </span>
                                                                    </a>
                                                                </Col>
                                                            </Row>
                                                        }


                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                        {

                        }
                    </Row>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
    rows: PropTypes.any,
    findOrders: PropTypes.func,
    findOrdersNumber: PropTypes.func,

    loading: PropTypes.any,
    loading_detail: PropTypes.any,
    current_page: PropTypes.number,
    last_page: PropTypes.number,
    total: PropTypes.number,
    from: PropTypes.number,
    to: PropTypes.number,


    listener_update_order: PropTypes.any,
    order: PropTypes.any,
    id: PropTypes.any,
    order_id: PropTypes.any,
    order_created_at: PropTypes.any,
    delivery_type: PropTypes.any,
    delivery_time: PropTypes.any,
    status: PropTypes.any,
    group: PropTypes.any,
    customer: PropTypes.any,
    payment_method: PropTypes.any,
    address_delivery: PropTypes.any,
    updateStatusOrders: PropTypes.func,
    findOrderIdChannelListener: PropTypes.func,
    findOrderCompanyIdChannelListener: PropTypes.func,
    printerOrders: PropTypes.func


}
const mapStateToProps = state => {
    const { order, listener_update_order } = state.OrdersChannelListeners
    const {
        loading,
        rows,
        current_page,
        last_page,
        total,
        from,
        to,
        loading_detail,
        id,
        order_created_at,
        delivery_type,
        delivery_time,
        status,
        group,
        customer,
        payment_method,
        address_delivery
    } = state.Orders

    // var orders = null
    if (order !== null) {
        rows.map((item, index) => {

            if (item.id !== order.id) {

                if (rows_ids.indexOf(order.id) === -1) {
                    // orders = order
                    rows.push(order)
                    rows_ids.push(order.id)
                    rows.reverse()
                }

                //rows.push(order)

            } else {
                rows[index] = order
                //  orders = null

            }
            console.log('item.id ', item.id + ' ' + item.status)
            console.log('order.id ', order.id + ' ' + order.status)
        })
    }


    /* if (orders !== null) {
         console.log('adicionou 1 ', order.id + ' '+order.status)
         //rows.push(orders)
          orders = null
      }*/


    if (rows.length === 0) {
        if (order !== null) {
            //   console.log('adicionou 2 ', order.id + ' '+order.status)
            rows.push(order)
            rows_ids.push(order.id)
            //rows.reverse()
            // orders = null
        }
    }


    return {
        listener_update_order,
        loading,
        loading_detail,
        rows,
        order,
        current_page,
        last_page,
        total,
        from,
        to,
        id,
        delivery_type,
        delivery_time,
        order_created_at,
        status,
        group,
        customer,
        payment_method,
        address_delivery
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    findOrders,
    findOrdersNumber,
    updateStatusOrders,
    findOrderIdChannelListener,
    findOrderCompanyIdChannelListener,
    printerOrders
})(Index)
))
