import React, {Component} from "react"
import {Card, CardBody, CardText, CardTitle, Col, Row} from "reactstrap"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
/*import {
    apiFindHomeCompanyError,
    apiFindPaymentMethodsError,
    findHomeHashCompany,
    findHomeQueryCompany,
    findPaymentMethods
} from "../../store/actions"*/
import InputMask from "react-input-mask"
import UILoadingAnim from "../../../components/UILoadingAnim"

class UIPaymentMethodsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            open: false,
            position: 'top',
            noOverlay: false,
            thing: null,
            paymentMethod: null,

        }

        this.handleNext = this.handleNext.bind(this)
        this.handleFields = this.handleFields.bind(this)

        this.toggleDrawer = this.toggleDrawer.bind(this)
        this.closeDrawer = this.closeDrawer.bind(this)
        this.onDrawerClose = this.onDrawerClose.bind(this)
        this.setPosition = this.setPosition.bind(this)
        this.setNoOverlay = this.setNoOverlay.bind(this)

    }

    componentDidMount() {
        //this.props.findPaymentMethods()
    }

    handleNext() {


    }

    setPosition(e) {
        this.setState({position: e.target.value})
    }

    setNoOverlay(e) {
        this.setState({noOverlay: e.target.checked})
    }

    toggleDrawer() {
        this.setState({open: !this.state.open})
    }

    closeDrawer() {
        this.setState({open: false})
    }

    onDrawerClose() {
        this.setState({open: false, paymentMethod: null})
    }

    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    render() {

        return (
            <Col sm={12}>

                <Row>
                    <Col sm={12}>
                        <div className="text-start mb-1">
                            <a onClick={this.props.previousPage}>
                                <b>
                                    <h4 className="card-title font-bl-12-400h text-danger">
                                        <i className="mdi mdi-arrow-left mdi-16px"/> Voltar
                                    </h4>
                                </b>
                            </a>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="text-center  mb-4">
                            <h4 className="card-title">Formas de Pagamentos</h4>
                            <small>em Pizzaria Fontanella</small>
                        </div>
                    </Col>
                    {
                        this.props.loading
                            ?
                            <Col sm={12}>
                                <div style={{
                                    height: '700px !important',
                                    marginBottom: '400px'

                                }}>
                                    <UILoadingAnim
                                        url_anim={4}/>
                                </div>
                            </Col>
                            :
                            this.props.rows && this.props.rows.length > 0 &&
                            this.props.rows.map((item, index) => {
                                return (
                                    <Col sm={12} key={index}>

                                        <a onClick={() => {
                                            //
                                            console.log('payment select ',parseInt(item.id) )
                                            switch (parseInt(item.id)) {

                                                case 1 :
                                                   // this.toggleDrawer()
                                                    this.setState({paymentMethod: item})
                                                   // this.props.funcParamPaymentMethods(item)
                                                    break
                                                case 4 :
                                                   // this.toggleDrawer()
                                                    this.setState({paymentMethod: item})
                                                    this.props.funcParamPaymentMethods(item)
                                                    break
                                                default:
                                                    this.props.funcParamPaymentMethods(item)
                                                   // this.toggleDrawer()
                                                    break
                                            }

                                        }}>
                                            <Card className="border-2 p-2">
                                                <div className="card-horizontal">
                                                    <div className="img-square-wrapper pr-2">
                                                        <img className="rounded" src="https://via.placeholder.com/70x70"
                                                             alt=" "/>
                                                    </div>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <p className="card-title font-bl-13-700h">{item.name}</p>
                                                            <p className="card-text font-bl-12-400h">{item.description}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                        </a>
                                    </Col>
                                )
                            })
                    }


                    <Col sm={12}>
                        {
                            this.state.open &&
                            <div className="bg-white bottom-bar-partners border-dark">
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center ">
                                            <Col>
                                                <Row>
                                                    <Col sm={12}>
                                                        <h2 className="font-bl-20-700h text-center">Precisa de
                                                            Troco?</h2>
                                                    </Col>

                                                </Row>

                                            </Col>
                                            <Col>
                                                <div className="text-end">
                                                    <a onClick={this.onDrawerClose}>
                                                        <i className="mdi mdi-close-thick mdi-18px"/>
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <h5 className="font-bl-13-700h">Preencha o campo abaixo, com o valor do
                                                    troco
                                                    desejado</h5>
                                            </Col>
                                            <Col sm={12}>

                                                <div className="form-group mt-2">
                                                    <label htmlFor="" className="control-label">Valor de troco:</label>
                                                    <InputMask
                                                        mask="R$ 99,99"
                                                        value={this.state.thing}
                                                        name="thing"
                                                        className="form-control input-mask"
                                                        onChange={this.handleFields}
                                                    />

                                                </div>
                                            </Col>
                                            <Col sm={12}>

                                                <a onClick={() => {

                                                    this.props.funcParamPaymentMethods({
                                                        id: this.state.paymentMethod.id,
                                                        name: this.state.paymentMethod.name,
                                                        value_thing: this.state.thing
                                                    })
                                                    //this.onDrawerClose()
                                                }}
                                                   className="btn btn-lg btn-block btn-danger btn-size mt-2">
                                                    <h4 className="font-bl-13-700h text-center pt-1 text-light">Finalizar</h4>
                                                </a>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        }
                        {/* <ReactDrawer
                                open={this.state.open}
                                position={this.state.position}
                                onClose={this.onDrawerClose}
                                noOverlay={this.state.noOverlay}>
                                <div className="bg-white">
                                    <Card>
                                        <CardBody>
                                            <Row className="align-items-md-center ">
                                                <Col>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <h2 className="font-bl-20-700h text-center">Precisa de Troco?</h2>
                                                        </Col>

                                                    </Row>

                                                </Col>
                                                <Col>
                                                    <div className="text-end">
                                                        <a onClick={this.onDrawerClose}>
                                                            <i className="mdi mdi-close-thick mdi-18px"/>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col sm={12}>
                                                    <h5 className="font-bl-13-700h">Preencha o campo abaixo, com o valor do troco
                                                        desejado</h5>
                                                </Col>
                                                <Col sm={12}>

                                                    <div className="form-group mt-2">
                                                        <label htmlFor="" className="control-label">Valor de troco:</label>
                                                        <InputMask
                                                            mask="R$ 99,99"
                                                            value={this.state.thing}
                                                            name="thing"
                                                            className="form-control input-mask"
                                                            onChange={this.handleFields}
                                                        />

                                                    </div>
                                                </Col>
                                                <Col sm={12}>

                                                    <a onClick={() => {
                                                        this.onDrawerClose()
                                                        this.props.funcParamPaymentMethods({
                                                            id: this.state.paymentMethod.id,
                                                            name: this.state.paymentMethod.name,
                                                            value_thing: this.state.thing
                                                        })
                                                    }}
                                                       className="btn btn-lg btn-block btn-danger btn-size mt-2">
                                                        <h4 className="font-bl-13-700h text-center pt-1 text-light">Finalizar</h4>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            </ReactDrawer>*/}
                    </Col>
                </Row>

            </Col>

        )
    }
}

UIPaymentMethodsList.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    getParamsModal: PropTypes.func,
    rows: PropTypes.array,
    funcParamPaymentMethods: PropTypes.func,
    findPaymentMethods: PropTypes.func,
    apiFindPaymentMethodsError: PropTypes.func,
    previousPage: PropTypes.func


}
const mapStateToProps = state => {
    const {
        loading,
        rows
    } = state.PaymentMethods
    return {
        loading,
        rows
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

   // findPaymentMethods,
  //  apiFindPaymentMethodsError
})(
    (UIPaymentMethodsList)
)))
