import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import toast from "react-hot-toast"
import {
    FIND_ROUTES,
    FIND_ROUTES_BY_NUMBER,
    PRINTER_ROUTES,
    UPDATE_STATUS_ROUTES
} from "./actionTypes"


import {
    findRoutesSuccess,
    findRoutesNumberSuccess,
    updateStatusRoutesSuccess,
    printerRoutesSuccess,
    apiCreateOrEditRouteError
} from "./actions"


import {
    getRoutesPaginate,
    getRouteByNumber,
    postPrinterRoute,
    putEditStatusRoute
} from "../../helpers/routes_helper"


function* printerRoute({payload: {params}}) {
    try {

        const response = yield call(postPrinterRoute, params)

        yield put(printerRoutesSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditRouteError(error))
    }
}

function* findRoutesList({payload: {params}}) {
    try {

        const response = yield call(getRoutesPaginate, params)

        yield put(findRoutesSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditRouteError(error))
    }
}

function* findRouteDetail({payload: {params}}) {
    try {

        const response = yield call(getRouteByNumber, params)

        yield put(findRoutesNumberSuccess(response))


    } catch (error) {
        yield put(apiCreateOrEditRouteError(error))
    }
}

function* updateStatusRoute({payload: {params, history}}) {
    try {

        const response = yield call(putEditStatusRoute, params)

        yield put(updateStatusRoutesSuccess(response))
        const {message} = response
        toast.success(message)
    } catch (error) {
        yield put(apiCreateOrEditRouteError(error))
    }
}

function* createOrEditRoutesSaga() {
    yield takeEvery(FIND_ROUTES, findRoutesList)
    yield takeEvery(FIND_ROUTES_BY_NUMBER, findRouteDetail)
    yield takeEvery(UPDATE_STATUS_ROUTES, updateStatusRoute)
    yield takeEvery(PRINTER_ROUTES, printerRoute)

}

export default createOrEditRoutesSaga
