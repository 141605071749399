import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap"
import PlaceHolder from '../assets/img/placeholder_pizzaria.jpg'
class ImageURI extends Component {

    constructor(props) {
        super(props);
        this.state = {
            place_holder: PlaceHolder
        }
        this.onImageError = this.onImageError.bind(this);
    }

    onImageError(e) {
        const { place_holder } = this.state
        e.target.src = place_holder
    }

    render() {
        return (
            <React.Fragment>
                <div className="img-square-wrapper">
                    <img className="m-2 rounded thumbnail img-responsive img-card-list"
                        src={this.props.url}
                        onError={this.onImageError}
                        alt={this.props.alt} />
                </div>
            </React.Fragment>
        )
    }
}

ImageURI.propTypes = {
    url: PropTypes.string,
    alt: PropTypes.string
}

export default ImageURI
