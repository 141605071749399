import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"
import PropTypes from "prop-types";

class Logout extends Component {
  /**
   * Redirect to login
   */
  componentDidMount = () => {
     // emit the event
    this.props.logoutUser()
  }

  render() {
    return <React.Fragment></React.Fragment>
  }
}
Logout.propTypes = {
  logoutUser: PropTypes.func,


}
export default withRouter(connect(null, { logoutUser })(Logout))
