import React from 'react'
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import slugify from 'react-slugify';

class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            image: null,
            file_image: null,
            exists_file: false,

        }
    }


    render() {
        const { is_edit_img, handleFileImgDrop, handleFileImgDropUpdate, name_product, img, url_img, entity_id, before_file, file_dir_name } = this.props

        return (
            <Dropzone onDrop={(e) => {
                let reader = new FileReader();
                let file = e[0];

                reader.onloadend = () => {
                    this.setState({
                        file_image: file,
                        exists_file: true,
                        image: reader.result
                    });
                }
                reader.readAsDataURL(file)

                if (is_edit_img == true) {

                    let name_replaced = name_product.replace(' ', '');
                    let name_file = slugify((name_replaced.concat(' pizzaria fontanella')).toLowerCase())

                    var data = new FormData();

                    if (entity_id) {
                        data.append('id', entity_id);
                    }
                    data.append('name_file', name_file);
                    data.append('type_dir_file', file_dir_name);
                    data.append('files', file);
                    data.append('path_file_before', before_file);
                    handleFileImgDropUpdate(data)
                } else {
                    handleFileImgDrop({
                        file_image: file,
                        exists_file: this.state.exists_file,
                        image: this.state.image
                    })
                }



            }}>

                {({ getRootProps, getInputProps }) => (

                    <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        {
                            this.props.is_edit_img
                                ?
                                <img
                                    className="thumbnail img-responsive img_box_product"
                                    src={this.state.image ? this.state.image : url_img} />

                                :
                                img != null && img !== '' || this.state.image != null && this.state.image !== ''
                                    ?
                                    <img
                                        className="thumbnail img-responsive img_box_product"
                                        src={this.state.image ? this.state.image : img} />
                                    :
                                    <p style={{ marginTop: '20%' }} className="text-center">
                                        Arraste e  solte ou clique  para adicionar a imagem
                                    </p>
                        }

                    </div>

                )}

            </Dropzone>

        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    is_edit_img: PropTypes.any,
    handleFileImgDrop: PropTypes.func,
    handleFileImgDropUpdate: PropTypes.func,
    img: PropTypes.any,
    entity_id: PropTypes.any,
    before_file: PropTypes.any,
    file_dir_name: PropTypes.any,

    url_img: PropTypes.any,
    name_product: PropTypes.any,

}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
