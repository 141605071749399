import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const findPromotionByCampaignAPI = ({ page, limit, company_id }) => get(url.GET_PROMOTIONS_BY_CAMPAIGN, { params: { page, limit, company_id } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const findPromotionCampaignByIdAPI = ({ id }) => get(url.GET_CAMPAIGN_BY_ID.concat('/').concat(id))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const createPromotionCampaignAPI = data => post(url.POST_CREATE_CAMPAIGN, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })
const addPromotionCampaignAPI = data => post(url.POST_ADD_CAMPAIGN_PROMOTIONS, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })
const removePromotionCampaignAPI = data => del(url.DELETE_CAMPAIGN_PROMOTIONS, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })

export {
    findPromotionByCampaignAPI,
    findPromotionCampaignByIdAPI,
    createPromotionCampaignAPI,
    addPromotionCampaignAPI,
    removePromotionCampaignAPI
}
