import React from 'react';
import { Col, Row, Spinner } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import {apiPaginateCityError,  deleteCity,allCityPaginate} from "../../../store/actions";
import PropTypes from "prop-types";
//import City from "../../../store/city/table/reducer";



class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}

        this._delete_item = this._delete_item.bind(this);
        this._showingpaginate = this._showingpaginate.bind(this);
        this.handleClickPagination = this.handleClickPagination.bind(this);
        this._showingstatuslabel = this._showingstatuslabel.bind(this);
    }

    componentDidMount() {
        this._all_by_paginate(1, 10)
    }

    _showingstatuslabel(value) {
        switch (parseInt(value)) {
            case 0:
                return (<span className="badge pill bg-danger p-1" style={{ fontSize: '90%' }}>Inativo</span>);
            case 1:
                return (<span className="badge pill bg-success p-1" style={{ fontSize: '90%' }}> Ativo </span>);
            default:
                return (<span className="badge pill bg-success p-1" style={{ fontSize: '90%' }}> Ativo </span>);
        }

    }

    async handleClickPagination(value) {
        await this._all_by_paginate(value, 10)
    }

    async _delete_item(id) {


    }


    async _all_by_paginate(page, limit) {

    }

    _showingpaginate(current_page, last_page, total, from, to) {
        let pages = [];

        if (to <= total) {

            for (let i = 1; i <= last_page; i++) {

                pages.push(i)

            }

        }
        if (current_page <= last_page) {
            return (
                <div className="clearfix">
                    <ul className="pagination pagination-sm m-0 float-right">
                        <li className="page-item" key={11 + 'ss'}>
                            <button disabled={1 === current_page}
                                className={`page-link ${1 === current_page ? 'text-light bg-dark' : ''}`}
                                onClick={() => this.handleClickPagination(1)}>Primeiro
                            </button>
                        </li>
                        {
                            pages.map((page, index) => {
                                return (<li key={index} className="page-item">
                                    <button disabled={page === current_page}
                                        className={`page-link ${page === current_page ? 'text-light bg-dark' : ''}`}
                                        onClick={() => this.handleClickPagination(page)}>{page}</button>
                                </li>)
                            })
                        }
                        <li key={12 + 'ss'} className="page-item ">
                            <button disabled={last_page === current_page}
                                className={`page-link ${last_page === current_page ? 'text-light bg-dark' : ''}`}
                                onClick={() => this.handleClickPagination(last_page)}>Último
                            </button>
                        </li>
                    </ul>
                </div>
            )
        }
    }

    render() {


        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <table className="table table-bordered table-striped table-responsive">
                            <thead>
                                <tr>
                                    {this.props.columns.map(
                                        (column, index) => {
                                            return (
                                                <th key={index} className="text-center">{column.name}</th>
                                            )
                                        })
                                    }

                                </tr>
                            </thead>
                            <tbody>

                                {

                                    this.props.rows &&
                                        this.props.rows.length > 0
                                        ?
                                        this.props.rows.map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td >{row.name}</td>
                                                    <td className="text-center" >{row.state}</td>
                                                    <td className="text-center" >{row.code}</td>
                                                    <td className="text-center" >{row.ibge}</td>
                                                    <td className="text-center" >{row.siafi}</td>
                                                    <td className="text-center" >{row.woeid}</td>
                                                    {showStatus !== false &&
                                                        <td className="text-center" >
                                                            {
                                                                this._showingstatuslabel(row.status)
                                                            }
                                                        </td>
                                                    }

                                                    <td className="text-center">
                                                        <div className="btn-group">

                                                            {edit !== false &&
                                                                <Link className="btn btn-dark btn-sm"
                                                                    to={{ pathname: route_edit.concat('/' + row.id) }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            }

                                                            <a onClick={() => confirm('Você tem certeza?') ? this._delete_item(row.id) : ''}
                                                                className="btn btn-danger btn-sm">
                                                                <i className="fas fa-trash text-light"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            {this.props.columns.map(item => <td key={item.id}></td>)}
                                        </tr>
                                }


                            </tbody>
                        </table>
                    </Col>

                </Row>
            </div>


        )
    }


}

Index.propTypes = {
    apiPaginateCityError: PropTypes.any,
    error: PropTypes.any,
    loading: PropTypes.any,
    refresh: PropTypes.any,
    loading_delete: PropTypes.any,
    rows: PropTypes.any,
    columns: PropTypes.any,
    current_page: PropTypes.number,
    last_page: PropTypes.number,
    total: PropTypes.number,
    from: PropTypes.number,
    to: PropTypes.number,
}

const mapStateToProps = state => {

    return {}
}

export default withRouter(
    connect(mapStateToProps, {


    })(Index)
)
