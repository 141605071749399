import React, { Component } from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { connect } from "react-redux"


// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

// layouts
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import io from 'socket.io-client';
import Echo from 'laravel-echo/dist/echo';
import Pusher from 'pusher-js';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    window.io = io;
    window.Pusher = Pusher;
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: 'fontanella-key',
      wsHost: 'wss.pizzariafontanella.com.br',
      wsPort: 8080,
      wssPort: 443,
      cluster: 'us2',
      forceTLS: false,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],


      //broadcaster: "socket.io",
      // host: 'https://portal.pizzariafontanella.com.br' + ':19881',
      //transports: ["websocket", "polling", "flashsocket"]
    });


    window.addEventListener("load", function () {

      setTimeout(function () {
        // This hides the address bar:
        window.scrollTo(0, 1);

      }, 0);
    });

  }



  render() {

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

App.propTypes = {
  layout: PropTypes.object,
  children: PropTypes.node,
}

export default connect(mapStateToProps, null)(App)
