import React, { Component } from 'react'
import MetaTags from 'react-meta-tags';
import {
  Container
} from "reactstrap"
//import echo from "../../Echo-Socket"
import Echo from 'laravel-echo/dist/echo';
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {findWhatsAppIdChannelListener} from "../../store/channels/orders/actions"



export default class index extends Component {

  constructor(props) {
    super(props);

   /* window.Echo = new Echo({
      broadcaster: "socket.io",
      host: 'https://portal.pizzariafontanella.com.br' + ':2083',
      //transports: ["websocket", "polling", "flashsocket"]
    });*/


  }

  componentDidMount() {
    //document.getElementById('dash').focus();
    /*window.Echo.channel('fontanella-chan-order')
        .listen('.order.created', e => {
          console.log(e);

        });*/

    /* console.log('event')

       window.Echo = new Echo({
         broadcaster: 'socket.io',
       host: 'https://portal.pizzariafontanella.com.br:2083',

       auth: {
         headers: {
           'Authorization': 'Bearer 46044e514122bdeff49d44993060aa33'
         }
       },
     });
     window.Echo.connect();

     //window.Echo.join('everywhere');
     window.Echo.channel('fontanella-chan-order')
         .listen('order.created', function (e)  {
           console.log('HEre ',e);
         }).error(error => {
           console.log('error ',error);
     });*/


      /*echo.join('_fontanella_chanOrder')
          .here((e) => {
              console.log('HEre ',e);
          })
          .joining((ee) => {
              console.log('joining ',ee);
          })
          .leaving((eee) => {
              console.log('leaving ',eee);
          })
          .error((error) => {
              console.log("echo:error", error);
          })
          .listen("OrdersCreatedEvent", (e) => {
              console.log("listen", e);
          })
          .listenForWhisper("typing", (e) => {
              console.log("listenForWhisper", e);
          });*/
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard </title>
          </MetaTags>
          <Container fluid>
            <h3>Dashboard</h3>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
