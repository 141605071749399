import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import PropTypes from "prop-types";
import { AvField, AvForm } from "availity-reactstrap-validation"
import Autocomplete from 'components/Autocomplete'



class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            category: null,
            category_id: null,
            product: null,
            company: null,
            brand: null,
        }
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnCheck = this.handleOnCheck.bind(this)
    }
    handleOnCheck(e, last_value) {
        const { getFieldsForm } = this.props
        const { id, name, value } = e.target

        let newobj = {}
        newobj[name] = parseInt(last_value) === 1 ? 0 : 1
        this.setState(newobj)
        getFieldsForm(newobj)
    }

    handleOnChange(e) {
        const { getFieldsForm } = this.props
        const { id, name, value } = e.target
        let newobj = {}
        newobj[name] = value
        getFieldsForm(newobj)
    }

    async handleAutocomplete(_data, name_entity) {

        if (_data) {
            const { value, name, code, data } = _data
            let newState = {}

            newState[name] = value
            newState[name + '_id'] = code

            //   console.log('newState ', newState)
            //   console.log('name_entity ', name_entity)
            //console.log('nadata ', data)

            this.setState(newState)

            if (data) {
                let newobj = {}
                newobj[name] = {
                    id: data.product_id,
                    promotion_id: data.promotion_id,
                    code: code,
                    name: value
                }

                this.props.getFieldsForm(newobj)
            }
        }
    }

    handleOnChange(e) {

        const { id, name, value } = e.target
        let newobj = {}
        newobj[name] = value
        this.props.getFieldsForm(newobj)
    }
    handleValidSubmit(event, values) {


    }

    render() {

        return (
            <Row>
                <AvForm
                    className="form-horizontal"
                >
                    <Col sm={12} className="mt-2">

                        <Row>
                            <Col>
                                <Autocomplete
                                    onSourceSelect={(d, e) => this.handleAutocomplete(d, e)}
                                    nameFieldSelect='category_id'
                                    limit={10}
                                    inputProps={{
                                        id: 'category',
                                        name: 'category',
                                        label: "Promoções",
                                        placeholder: "Pesquisar promoção...",
                                        param_id: '&company_id=1',
                                        value: this.state.category ? this.state.category : this.props.promotion,
                                        url: 'search/promotions/category'
                                    }} />

                            </Col>
                            <Col>
                                {
                                    this.state.category_id > 0 &&
                                    <Autocomplete
                                        onSourceSelect={(d, e) => this.handleAutocomplete(d, e)}
                                        nameFieldSelect='product_id'
                                        limit={10}
                                        inputProps={{
                                            id: 'product',
                                            name: 'product',
                                            enable: (this.state.category_id > 0 ? false : true),
                                            label: "Produto",
                                            param_id: ('&category_id='.concat((this.state.category_id))),
                                            placeholder: "Pesquisar produto...",
                                            value: this.state.product ? this.state.product : this.props.product,
                                            url: 'search/promotions/products'
                                        }} />
                                }
                            </Col>
                            <Col sm={12}>
                                <AvForm
                                    className="form-horizontal mt-2"
                                    onValidSubmit={this.handleValidSubmit}>
                                    <Row>

                                        <Col sm={9}>
                                            <AvField
                                                name="tags"
                                                label="Tags"
                                                className="form-control"
                                                placeholder="Ex: bebidas, pizzas"
                                                type="text"
                                                value={this.state.tags ? this.state.tags : this.props.tags}
                                                onChange={(e) => this.handleOnChange(e)}
                                                required
                                            />
                                        </Col>
                                        <Col sm={3}></Col>
                                        <Col sm={12}>
                                            <AvField
                                                name="description"
                                                label="Descrição"
                                                className="form-control"
                                                placeholder="Digite uma descrição"
                                                type="textarea"
                                                value={this.state.description ? this.state.description : this.props.description}
                                                onChange={(e) => this.handleOnChange(e)}
                                                rows={5}
                                                required
                                            />
                                        </Col>
                                        <Col sm={12}>
                                            <AvField
                                                name="url_video"
                                                label="URL Video"
                                                className="form-control"
                                                placeholder="informe a url do video"
                                                value={this.state.url_video ? this.state.url_video : this.props.url_video}
                                                onChange={(e) => this.handleOnChange(e)}
                                                type="text"
                                            />
                                        </Col>

                                        <Col>

                                            <Row>
                                                <Col xl={6} sm={6}>
                                                    <div >
                                                        <h5 className="font-size-14 mb-4">Disponivel em quais Feeds:</h5>
                                                        <div className="form-check mb-3">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="is_visible_gmerchant"
                                                                id="is_visible_gmerchant"
                                                                value="1"
                                                                checked={this.state.is_visible_gmerchant ? this.state.is_visible_gmerchant : this.props.is_visible_gmerchant}
                                                                onChange={(e) => this.handleOnCheck(e, this.state.is_visible_gmerchant)}

                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="is_visible_gmerchant"
                                                            >
                                                                Google Merchant (Shopping)
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="is_visible_meta"
                                                                id="is_visible_meta"
                                                                value="1"
                                                                checked={this.state.is_visible_meta ? this.state.is_visible_meta : this.props.is_visible_meta}
                                                                onChange={(e) => this.handleOnCheck(e, this.state.is_visible_meta)}

                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="is_visible_meta"
                                                            >
                                                                Facebok/Instagram (Catálogo)
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl={6} sm={6}>
                                                    <div >
                                                        <h5 className="font-size-14 mb-4"></h5>
                                                        <div className="form-check mb-3">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="is_visible_tiktok"
                                                                id="is_visible_tiktok"
                                                                value="1"
                                                                checked={this.state.is_visible_tiktok ? this.state.is_visible_tiktok : this.props.is_visible_tiktok}
                                                                onChange={(e) => this.handleOnCheck(e, this.state.is_visible_tiktok)}

                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="is_visible_tiktok"
                                                            >
                                                                Tiktok (Catálogo)
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="is_visible_kwai"
                                                                id="is_visible_kwai"
                                                                value="1"
                                                                checked={this.state.is_visible_kwai ? this.state.is_visible_kwai : this.props.is_visible_kwai}
                                                                onChange={(e) => this.handleOnCheck(e, this.state.is_visible_kwai)}

                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="is_visible_kwai"
                                                            >
                                                                Kwai (Catálogo)
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Col>


                                            </Row>
                                        </Col>

                                    </Row>
                                </AvForm>
                            </Col>
                        </Row>

                    </Col>

                </AvForm>

            </Row>
        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    title: PropTypes.any,
    product: PropTypes.any,
    company: PropTypes.any,
    promotion: PropTypes.any,
    getFieldsForm: PropTypes.func



}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
