import {
    FIND_PRODUCT_BY_CAMPAIGN,
    FIND_PRODUCT_BY_CAMPAIGN_SUCCESS,
    FIND_PRODUCT_BY_ID,
    FIND_PRODUCT_BY_ID_SUCCESS,
    PAGINATE_PRODUCT_CAMPAIGN,
    PAGINATE_PRODUCT_CAMPAIGN_SUCCESS,
    CREATE_AND_ADD_PRODUCT_CAMPAIGN,
    CREATE_AND_ADD_PRODUCT_CAMPAIGN_SUCCESS,
    REMOVE_PRODUCT_CAMPAIGN,
    REMOVE_PRODUCT_CAMPAIGN_SUCCESS,
    ADD_PRODUCT_CAMPAIGN,
    ADD_PRODUCT_CAMPAIGN_SUCCESS,
    PRODUCT_CAMPAIGN_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    rows: [],
    current_page: 0,
    last_page: 0,
    total: 0,
    from: 0,
    to: 0,

    rows: [],
    id: 0,
}

const productCampaign = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_AND_ADD_PRODUCT_CAMPAIGN:
        case REMOVE_PRODUCT_CAMPAIGN:
        case ADD_PRODUCT_CAMPAIGN:
        case FIND_PRODUCT_BY_CAMPAIGN:
        case PAGINATE_PRODUCT_CAMPAIGN:
        case FIND_PRODUCT_BY_ID:
            state = {
                ...state,
                loading: true
            }
            break
        case PAGINATE_PRODUCT_CAMPAIGN_SUCCESS:
            state = {
                ...state,
                current_page: action.payload.result.current_page,
                last_page: action.payload.result.last_page,
                total: action.payload.result.total,
                from: action.payload.result.from,
                to: action.payload.result.to,
                rows: action.payload.result.data,
                refresh: false,
                loading: false
            }
            break
        case FIND_PRODUCT_BY_CAMPAIGN_SUCCESS:
        case FIND_PRODUCT_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload,
                loading: false
            }
            break
        case CREATE_AND_ADD_PRODUCT_CAMPAIGN_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                loading: false
            }
            break
        case REMOVE_PRODUCT_CAMPAIGN_SUCCESS:
        case ADD_PRODUCT_CAMPAIGN_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case PRODUCT_CAMPAIGN_API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break

        default:
            state = { ...state }
            break
    }

    return state
}
export default productCampaign
