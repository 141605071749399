import {
    FIND_PROMOTIONS_BY_CAMPAIGN,
    FIND_PROMOTIONS_BY_CAMPAIGN_SUCCESS,
    FIND_PROMOTION_BY_ID,
    FIND_PROMOTION_BY_ID_SUCCESS,
    CREATE_AND_ADD_CAMPAIGN_PROMOTION,
    CREATE_AND_ADD_CAMPAIGN_PROMOTION_SUCCESS,
    REMOVE_CAMPAIGN_PROMOTION,
    REMOVE_CAMPAIGN_SUCCESS,
    ADD_CAMPAIGN_PROMOTION,
    ADD_CAMPAIGN_PROMOTION_SUCCESS,
    FIND_PROMOTIONS_BY_CAMPAIGN_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    rows: [],
    id: 0,
}

const promotionsCampaigns = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_AND_ADD_CAMPAIGN_PROMOTION:
        case REMOVE_CAMPAIGN_PROMOTION:
        case ADD_CAMPAIGN_PROMOTION:
        case FIND_PROMOTIONS_BY_CAMPAIGN:
        case FIND_PROMOTION_BY_ID:
            state = {
                ...state,
                loading: true
            }
            break
        case FIND_PROMOTIONS_BY_CAMPAIGN_SUCCESS:
        case FIND_PROMOTION_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload,
                loading: false
            }
            break
        case CREATE_AND_ADD_CAMPAIGN_PROMOTION_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                loading: false
            }
            break
        case REMOVE_CAMPAIGN_SUCCESS:
        case ADD_CAMPAIGN_PROMOTION_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_PROMOTIONS_BY_CAMPAIGN_API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break

        default:
            state = { ...state }
            break
    }

    return state
}
export default promotionsCampaigns
