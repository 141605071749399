import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    FIND_LOG_SNIPER_BOT,
    RESTART_CMD_SNIPER_BOT,
    STOP_CMD_SNIPER_BOT,
} from "./actionTypes"


import {
    restartSniperBotSuccess,
    stopSniperBotSuccess,
    findLogSniperBotSuccess,
    apiFindLogSniperBotError,
} from "./actions"


import {
    getLogSniperBotAPI,
    postRestartSniperBotAPI,
    postStopSniperBotAPI
} from "../../helpers/sniper_bot_helper"

function* findLogSniperBOT({payload: {params}}) {
    try {

        const response = yield call(getLogSniperBotAPI, params)

        yield put(findLogSniperBotSuccess(response))

    } catch (error) {
        yield put(apiFindLogSniperBotError(error))
    }
}

function* restartSniperBOT() {
    try {

        const response = yield call(postRestartSniperBotAPI)
        yield put(restartSniperBotSuccess(response))

    } catch (error) {
        yield put(apiFindLogSniperBotError(error))
    }
}

function* stopSniperBOT() {
    try {

        const response = yield call(postStopSniperBotAPI)
        yield put(stopSniperBotSuccess(response))

    } catch (error) {
        yield put(apiFindLogSniperBotError(error))
    }
}

function* SniperBOTSaga() {
    yield takeEvery(FIND_LOG_SNIPER_BOT, findLogSniperBOT)
    yield takeEvery(RESTART_CMD_SNIPER_BOT, restartSniperBOT)
    yield takeEvery(STOP_CMD_SNIPER_BOT, stopSniperBOT)
}

export default SniperBOTSaga
