import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";
import toast from "react-hot-toast"


const getRouteByNumber = data => get(url.GET_ROUTE_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const delRoute = data => del(url.DELETE_ROUTE.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        toast.error(message)
        throw err.response.data
    })

const postCreateRoute = data => post(url.POST_CREATE_ROUTE, data)
    .catch(err => {
        const {title, message} = err.response.data;
        toast.error(message)
        throw err.response.data
    })

const postPrinterRoute = ({company_id, route_id}) => get(url.POST_PRINTER_ROUTE.concat('/').concat(route_id).concat('/').concat(company_id))
    .catch(err => {
        const {title, message} = err.response.data;
        toast.error(message)
        throw err.response.data
    })

const putEditStatusRoute = data => put(url.PUT_EDIT_STATUS_ROUTE, data)
    .catch(err => {
        const {title, message} = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const getRoutesPaginate = ({page, limit, company_id}) => get(url.GET_ROUTES_PAGINATE, { params: { page, limit, company_id} })
    .catch(err => {
        const {title, message} = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const getFilterRoutesPaginate = data => get(url.GET_ROUTES_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const getSearchRoutes = params => get(url.GET_SEARCH_ROUTES, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getRoutesPaginate,
    getFilterRoutesPaginate,
    putEditStatusRoute,
    postCreateRoute,
    delRoute,
    getRouteByNumber,
    postPrinterRoute,
    getSearchRoutes
}
