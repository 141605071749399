import {
    LISTENER_ROUTES_CARD_ID_CHANNEL,
    LISTENER_ROUTES_CARD_ID_CHANNEL_SUCCESS,
    LISTENER_ROUTES_CHANNEL_BY_ID_COMPANY,
    LISTENER_ROUTES_CHANNEL_BY_ID_COMPANY_SUCCESS,
    ROUTES_CHANNEL_ERROR
} from "./actionTypes"



const initialState = {
    listener_route: false,
    listener_update_route: false,
    route: null

}

const createOrEditRoutesChannelListeners = (state = initialState, action) => {

    switch (action.type) {
        case LISTENER_ROUTES_CARD_ID_CHANNEL:
            state = {
                ...state,
                listener_route: false,
                listener_update_route: false,
            }
            break
        case LISTENER_ROUTES_CARD_ID_CHANNEL_SUCCESS:
            console.log(    'action.payloadroute ', action.payload)
            state = {
                ...state,
                route: {
                    id: action.payload.id,
                    route_created_at: action.payload.route_created_at,
                    status: action.payload.status,
                    qtd_point: action.payload.qtd_point,
                    time_route: action.payload.time_route,
                    group_index: action.payload.group_index,
                },
                listener_route: false,
                listener_update_route: true
            }
            break
        case LISTENER_ROUTES_CHANNEL_BY_ID_COMPANY:
            state = {
                ...state,
                listener_route: true,
                listener_update_route: false
            }
            break
        case LISTENER_ROUTES_CHANNEL_BY_ID_COMPANY_SUCCESS:

            state = {
                ...state,
                route:{
                    id: action.payload.id,
                    route_created_at: action.payload.route_created_at,
                    status: action.payload.status,
                    qtd_point: action.payload.qtd_point,
                    time_route: action.payload.time_route,
                    group_index: action.payload.group_index,
                },
                listener_route: false,
                listener_update_route: false
            }
            break
        case ROUTES_CHANNEL_ERROR:
            state = {...state, error: action.payload, listener_route: false,  listener_update_route:false}
            break
        default:
            state = {...state, listener_route: false, listener_update_route: false}
            break
    }

    return state
}
export default createOrEditRoutesChannelListeners
