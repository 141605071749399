import React from 'react'
import { CardBody } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Card, Col, Row, Stack } from "react-bootstrap"
import { findProductsByCategory } from "../../../../store/catalog/productsByCategory/actions"
import { findPromotionByCompanies, createPromotionByCompanies } from "../../../../store/promotions/actions"
import BreadcrumbsCustom from 'components/BreadcrumbCustom'
import WeekDay from 'components/WeekDay'
import ReactDrawer from 'react-drawer';
import UIRadioDescPerc from 'components/UIRadioDescPerc'
import UITimeSelect from 'components/UITimeSelect'

import UIProductSectionLists from './../Index'
import UIProductsListSelect from '../UIProductsListSelect'

import UICategorySelect from '../categories/Index'

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            open: false,
            position: 'right',
            noOverlay: false,
            products: [],
            days_week: [],
            coupom_discount: null,
            page: 0,
            category_id: null,

        }
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.productSelected = this.productSelected.bind(this);
        this.getDescPerc = this.getDescPerc.bind(this);
        this.getDayWeeks = this.getDayWeeks.bind(this);
        this.removeDayWeeks = this.removeDayWeeks.bind(this);
        this.check_duplicates_weeks = this.check_duplicates_weeks.bind(this)
        this.getTime = this.getTime.bind(this)
        this.handlerCreate = this.handlerCreate.bind(this)
        this.getCategorySelected = this.getCategorySelected.bind(this)
    }
    setPosition(e) {
        this.setState({ position: e.target.value });
    }

    setNoOverlay(e) {
        this.setState({ noOverlay: e.target.checked });
    }

    toggleDrawer() {
        this.setState({ open: !this.state.open, isEdit: false });
    }

    closeDrawer() {
        this.setState({ open: false, openEdit: false, isEdit: false });
    }

    onDrawerClose() {
        this.setState({ open: false, isEdit: false });
    }


    componentDidMount() {
        this.props.findProductsByCategory(this.props.company_id)

    }

    productSelected(item, type) {
        item['type'] = type
        const { products } = this.state
        if (products.length > 0) {
            products.map((p, index) => {
                if (p.id != item.id) {
                    products.push(item)
                }
            })
        } else {
            products.push(item)
        }


        this.setState({ products })
        this.toggleDrawer()
    }
    removeDayWeeks(item) {

        if (this.state.days_week.length >= 1) {
            this.state.days_week.map((d, index) => {
                if (d.id === item.id) {
                    this.state.days_week.splice(index, 1);
                }
            })
            this.setState({ days_week: this.state.days_week })

        } else {
            this.state.days_week.splice(0, this.state.days_week.length);
            this.setState({ days_week: this.state.days_week })

        }


    }
    getDayWeeks(item) {

        if (this.state.days_week.length > 0) {
            this.state.days_week.map((d, index) => {
                if (d.id !== item.id) {
                    this.state.days_week.push(item)
                }
            })
        }
        if (this.state.days_week.length === 0) {
            this.state.days_week.push(item)
        }

        this.setState({ days_week: this.state.days_week })
    }
    check_duplicates_weeks(days) {
        const { days_week } = this.state;
        days.map((day, index) => {
            if (this.state.days_week.length > 0) {
                this.state.days_week.map((day_selected, index_selected) => {
                    if (day.id == day_selected.id) {
                        if (!day.is_selected) {
                            days_week.splice(index_selected, 1)
                        }
                    }
                })
            } else {
                this.setState({ days_week: [] })

            }

        })
    }
    getDescPerc(item) {
        this.setState({ coupom_discount: item })
    }
    getTime(e) {
        const { id, name, value } = e.target
        let tempState = {}
        tempState[name] = value
        this.setState(tempState)

    }

    handlerCreate() {

        const { products, days_week, coupom_discount, category_id } = this.state;
        this.props.createPromotionByCompanies({
            company_id: this.props.company_id,
            promotion_id: this.props.promotion_id,
            category_id: category_id,
            type: this.props.type,
            products,
            days_week,
            coupom_discount,

        });
    }

    getCategorySelected(item) {
        this.setState({ page: 2, category_id: item.id })
    }

    renderPage(page) {
        const { products, category_id } = this.state
        const { company_id } = this.props

        switch (page) {
            case 0:
            case 1:
                return (<UICategorySelect getCategorySelected={this.getCategorySelected} company_id={company_id} />)
            case 2:
                return (<>

                    <Stack gap={2} direction="horizontal">
                        <div className='w-75'>
                            <span
                                className="h4 card-title">Desconto em produtos</span>
                            <p className="card-title-desc mt-2">
                                Está opção permite aplicar descontos em produtos e, disponibilizar por dias da semana.
                            </p>
                            <span className="card-title-desc mb-2">
                                Dias de visibilidade <small className='text-danger'> escolha abaixo</small>
                            </span>
                        </div>
                        <div className='w-25'>
                            <div className="text-md-right ms-auto text-end" >
                                <button
                                    onClick={() => this.handlerCreate()}
                                    className="btn btn-danger btn-sm text-light"
                                >
                                    <i className="bx bx-save" />
                                    <span> Cadastrar</span>
                                </button>
                            </div>
                        </div>
                    </Stack>



                    <WeekDay
                        company_id={company_id}
                        getDayWeeks={this.getDayWeeks}
                        removeDayWeeks={this.removeDayWeeks}
                        check_duplicates_weeks={this.check_duplicates_weeks} />

                    <UIRadioDescPerc company_id={company_id} getDescPerc={this.getDescPerc} />

                    <UIProductSectionLists
                        rows={products}
                        title="Listagem de produtos com descontos"
                        category_id={category_id}
                        openDrawerProducts={() => {
                            this.setState({ open: !this.state.open })

                        }}
                    />




                    <ReactDrawer
                        open={this.state.open}
                        position={this.state.position}
                        onClose={this.onDrawerClose}
                        noOverlay={this.state.noOverlay}>

                        <UIProductsListSelect
                            rows={this.props.rows}
                            getProductSelected={this.productSelected}
                            closeLayoutDrawer={this.closeDrawer}
                        />

                    </ReactDrawer>
                </>)
        }

    }

    render() {
        const { page } = this.state
        return this.renderPage(page)
    }


}

Index.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.any,
    findProductsByCategory: PropTypes.func,

    createPromotionByCompanies: PropTypes.func,
    rows: PropTypes.any,
    company_id: PropTypes.any,
    promotion_id: PropTypes.any,
    type: PropTypes.any,


}
const mapStateToProps = state => {
    const {
        loading,
        rows,
        id
    } = state.ProductsCategory

    return {
        loading,
        rows,
        id
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    findProductsByCategory,

    createPromotionByCompanies
})(Index)
))
