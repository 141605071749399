import React from 'react'
import { CardBody } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Card, Col, Row, Stack } from "react-bootstrap"
import UICardProducts_type_1 from './UICardProducts_type_1'
import UICardProducts_type_2 from './UICardProducts_type_2'

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        const { rows } = this.props

        return (
            <>
                <div className="mt-2">
                    <a
                        onClick={() => this.props.openDrawerProducts()}
                        className="btn btn-dark btn-sm text-light mb-2"
                    >
                        <span> + Add. Produtos</span>
                    </a> <br />
                    <span className="mt-5 card-title-desc mb-2 mt-2">
                        Listagem de produtos com descontos
                    </span>


                </div>

                <Row className="mt-2">
                    {
                        rows.length == 0
                            ?
                            <Col sm={12} className='text-center mt-5'>
                                <div className="mt-2">
                                    <span className="card-title-desc mb-2 mt-2">
                                        Não existem produtos na grade, para aplicar o desconto promocional.
                                    </span>
                                    <br />

                                    <a
                                        onClick={() => this.props.openDrawerProducts()}
                                        className="btn btn-danger btn-sm text-light mt-2"
                                    >
                                        <span> + Add. Produtos</span>
                                    </a>
                                </div>
                            </Col>
                            :
                            rows.map((item, index) => {
                                return (
                                    <>
                                        {
                                            item.type === 1
                                                ?
                                                <UICardProducts_type_1
                                                    type={item.type}
                                                    product={item}
                                                    key={index}
                                                    visible_button={false}
                                                    sizes={item.sizes}
                                                />
                                                :
                                                <UICardProducts_type_2
                                                    type={item.type}
                                                    product={item}
                                                    visible_button={false}
                                                    key={index}

                                                />
                                        }
                                    </>
                                )
                            })
                    }
                </Row>
            </>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,

    rows: PropTypes.any,
    category_id: PropTypes.any,
    openDrawerProducts: PropTypes.func,
}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
})(Index)
))
