import {
    GET_CATEGORIES_PROMOTIONS,
    GET_CATEGORIES_PROMOTIONS_SUCCESS,
    GET_CATEGORIES_PROMOTIONS_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    rows: [],
    id: 0,
}

const categories = (state = initialState, action) => {

    switch (action.type) {
        case GET_CATEGORIES_PROMOTIONS:
            state = {
                ...state,
                loading: true
            }
            break
        case GET_CATEGORIES_PROMOTIONS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload.results,
                loading: false
            }
            break
        case GET_CATEGORIES_PROMOTIONS_API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break

        default:
            state = { ...state }
            break
    }

    return state
}
export default categories
