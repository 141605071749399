import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Col, Row } from "reactstrap"
import "./days_week.css"
import { Stack } from "react-bootstrap"
import { getDiscountCouponsByType } from "../store/coupomDiscounts/actions"

//
class UIRadioDescPerc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            perc_disc: [
                {
                    disc: 5,
                },
                {
                    disc: 10,
                },
                {
                    disc: 15,
                },
                {
                    disc: 20,
                },
                {
                    disc: 25,
                },
                {
                    disc: 30,
                },
                {
                    disc: 35,
                },
                {
                    disc: 40,
                },
                {
                    disc: 45,
                },
                {
                    disc: 50,
                },
                {
                    disc: 55,
                },
                {
                    disc: 60,
                },
                {
                    disc: 65,
                },
                {
                    disc: 70,
                },
                {
                    disc: 75,
                },
                {
                    disc: 80,
                },
                {
                    disc: 85,
                },
                {
                    disc: 90,
                },
                {
                    disc: 95,
                },
                {
                    disc: 100,
                },
            ],
        }

    }
    componentDidMount() {
        this.props.getDiscountCouponsByType(
            this.props.company_id
        );
    }

    render() {
        const { rows } = this.props;
        return (
            <div className="mt-2 mb-2">
                <span className="card-title-desc mb-2">
                    Escolha abaixo, o percentual de desconto.
                </span>

                <Row onChange={(e) => {
                    const { id, name, value } = e.target

                    this.props.rows.map((item, index) => {
                        if (item.id == id) {
                            this.props.getDescPerc(item)
                            return;
                        }
                    });

                }}>

                    {rows && rows.length > 0 &&
                        rows.map((item, index) => {
                            return (
                                <Col key={index}>
                                    <div className="form-check m-1" >
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="type"
                                            id={item.id}
                                            value={item.percentage}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="type"
                                        >
                                            {item.percentage}%
                                        </label>
                                    </div>
                                </Col>
                            )
                        })}


                </Row>
            </div>
        )
    }
}

UIRadioDescPerc.propTypes = {
    getDescPerc: PropTypes.func,
    name: PropTypes.any,
    getDiscountCouponsByType: PropTypes.func,
    title: PropTypes.string,
    company_id: PropTypes.any,
    rows: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        loading,
        rows,
        id
    } = state.CouponsDiscounts

    return {
        loading,
        rows,
        id
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    getDiscountCouponsByType
})(UIRadioDescPerc)
))
