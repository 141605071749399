import {
    FIND_COUPOM_DISCOUNT,
    FIND_COUPOM_DISCOUNT_SUCCESS,
    GET_COUPOM_DISCOUNT_BY_TYPE,
    GET_COUPOM_DISCOUNT_BY_TYPE_SUCCESS,
    FIND_COUPOM_DISCOUNT_API_ERROR,
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    rows: [],
    id: 0,
}

const discountCoupons = (state = initialState, action) => {

    switch (action.type) {
        case GET_COUPOM_DISCOUNT_BY_TYPE:
        case FIND_COUPOM_DISCOUNT:
            state = {
                ...state,
                loading: true
            }
            break
        case GET_COUPOM_DISCOUNT_BY_TYPE_SUCCESS:
        case FIND_COUPOM_DISCOUNT_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload.data,
                loading: false
            }
            break
        case FIND_COUPOM_DISCOUNT_API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default discountCoupons
