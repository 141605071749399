import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    FIND_HOME_BY_HASH_COMPANY,
    FIND_HOME_BY_QUERY_COMPANY
} from "./actionTypes"


import {
    findHomeQueryCompanySuccess,
    findHomeHashCompanySuccess,
    apiFindHomeCompanyError
} from "./actions"


import {
    findHomeQueryCompanyAPI,
    findHomeHashCompanyAPI
} from "../../../helpers/catalog_helper"

function* findByQueryHomeCompany({payload: {query}}) {
    try {

        const response = yield call(findHomeQueryCompanyAPI, query)

        yield put(findHomeQueryCompanySuccess(response))

    } catch (error) {
        yield put(apiFindHomeCompanyError(error))
    }
}

function* findByHashHomeCompany({payload: {hash}}) {

   try {

        const response = yield call(findHomeHashCompanyAPI, hash)
        yield put(findHomeHashCompanySuccess(response))


    } catch (error) {
        yield put(apiFindHomeCompanyError(error))
    }
}


function* CatalogCompanySaga() {
    yield takeEvery(FIND_HOME_BY_QUERY_COMPANY, findByQueryHomeCompany)
    yield takeEvery(FIND_HOME_BY_HASH_COMPANY, findByHashHomeCompany)
}

export default CatalogCompanySaga
