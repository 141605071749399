import React from 'react'
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import PropTypes from "prop-types";
import { Card, Col, Row, Stack } from "react-bootstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import UIProductSectionLists from './../UIProductsList'
import UIProductsListSelect from './../UIProductsListSelect'
import WeekDay from './../../../../components/WeekDay'
import ReactDrawer from 'react-drawer';
import UITimeSelect from './../../../../components/UITimeSelect'

class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}


    }




    render() {
        const { lastPage, open, onCreate, position, noOverlay, productSelected, rows, products, category_id, company_id, openDrawerProduct, closeDrawer, onDrawerClose, getTime, getDayWeeks, removeDayWeeks, check_duplicates_weeks } = this.props

        return (
            <>
               
                <Stack gap={2} direction="horizontal">
                    <div className='w-75'>
                        <span
                            className="h4 card-title">Configurar campanha para produtos</span>
                        <p className="card-title-desc mt-2">
                            Está opção permite configurar campanhas de disparo em massa, sobe alerta de novos produtos e ou, exclusividades.
                        </p>
                        <span className="card-title-desc mb-2">
                            Dias de disparo <small className='text-danger'> escolha abaixo</small>
                        </span>
                    </div>
                    <div className='w-25'>
                        <div className="text-md-right ms-auto text-end" >
                            <button
                                onClick={() => onCreate()}
                                className="btn btn-danger btn-sm text-light"
                            >
                                <i className="bx bx-save" />
                                <span> Cadastrar</span>
                            </button>
                        </div>
                    </div>
                </Stack>



                <WeekDay
                    company_id={company_id}
                    getDayWeeks={getDayWeeks}
                    removeDayWeeks={removeDayWeeks}
                    check_duplicates_weeks={check_duplicates_weeks} />

                <div className='w-25'>
                    <UITimeSelect
                        id="time_scheduler"
                        name="Informe o horário de inicio."
                        getTime={getTime} />
                </div>

                <UIProductSectionLists
                    rows={products}
                    title="Produtos da campanha de disparo em massa."
                    category_id={category_id}
                    openDrawerProducts={openDrawerProduct}
                />




                <ReactDrawer
                    open={open}
                    position={position}
                    onClose={onDrawerClose}
                    noOverlay={noOverlay}>

                    <UIProductsListSelect
                        rows={rows}
                        getProductSelected={productSelected}
                        closeLayoutDrawer={closeDrawer}
                    />

                </ReactDrawer>
            </>
        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    handleOnChange: PropTypes.func,

    open: PropTypes.any,
    position: PropTypes.any,
    noOverlay: PropTypes.any,
    productSelected: PropTypes.any,
    rows: PropTypes.any,
    products: PropTypes.any,
    category_id: PropTypes.any,
    company_id: PropTypes.any,
    closeDrawer: PropTypes.func,
    onDrawerClose: PropTypes.func,
    getTime: PropTypes.func,
    getDayWeeks: PropTypes.func,
    removeDayWeeks: PropTypes.func,
    check_duplicates_weeks: PropTypes.func,
    openDrawerProduct: PropTypes.func,
    lastPage: PropTypes.func,
    onCreate: PropTypes.func,


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
