import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import toast from "react-hot-toast"
import {
    FIND_ORDERS,
    FIND_ORDERS_BY_NUMBER,
    PRINTER_ORDERS,
    UPDATE_STATUS_ORDERS
} from "./actionTypes"


import {
    findOrdersSuccess,
    findOrdersNumberSuccess,
    updateStatusOrdersSuccess,
    printerOrdersSuccess,
    apiCreateOrEditOrderError
} from "./actions"


import {
    getOrdersPaginate,
    getOrderByNumber,
    postPrinterOrder,
    putEditStatusOrder
} from "../../helpers/orders_helper"


function* printerOrder({payload: {params}}) {
    try {

        const response = yield call(postPrinterOrder, params)

        yield put(printerOrdersSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditOrderError(error))
    }
}

function* findOrdersList({payload: {params}}) {
    try {

        const response = yield call(getOrdersPaginate, params)

        yield put(findOrdersSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditOrderError(error))
    }
}

function* findOrderDetail({payload: {params}}) {
    try {

        const response = yield call(getOrderByNumber, params)

        yield put(findOrdersNumberSuccess(response))


    } catch (error) {
        yield put(apiCreateOrEditOrderError(error))
    }
}

function* updateStatusOrder({payload: {params, history}}) {
    try {

        const response = yield call(putEditStatusOrder, params)

        yield put(updateStatusOrdersSuccess(response))
        const {message} = response
        toast.success(message)
    } catch (error) {
        yield put(apiCreateOrEditOrderError(error))
    }
}

function* createOrEditOrdersSaga() {
    yield takeEvery(FIND_ORDERS, findOrdersList)
    yield takeEvery(FIND_ORDERS_BY_NUMBER, findOrderDetail)
    yield takeEvery(UPDATE_STATUS_ORDERS, updateStatusOrder)
    yield takeEvery(PRINTER_ORDERS, printerOrder)

}

export default createOrEditOrdersSaga
