import {
    FIND_SOCIAL_MIDIA_PRODUCT,
    FIND_SOCIAL_MIDIA_PRODUCT_SUCCESS,
    CREATE_SOCIAL_MIDIA_PRODUCT,
    CREATE_SOCIAL_MIDIA_PRODUCT_SUCCESS,
    PAGINATE_SOCIAL_MIDIA_PRODUCT,
    PAGINATE_SOCIAL_MIDIA_PRODUCT_SUCCESS,
    GET_BY_ID_SOCIAL_MIDIA_PRODUCT,
    GET_BY_ID_SOCIAL_MIDIA_PRODUCT_SUCCESS,
    UPLOAD_IMAGE_MIDIA_PRODUCT,
    UPLOAD_IMAGE_MIDIA_PRODUCT_SUCCESS,
    UPDATE_MIDIA_PRODUCT,
    UPDATE_MIDIA_PRODUCT_SUCCESS,
    FIND_SOCIAL_MIDIA_PRODUCT_API_ERROR,
} from "./actionTypes"

export const editSMProducts = (params, goBack) => {
    return {
        type: UPDATE_MIDIA_PRODUCT,
        payload: {params, goBack},
    }
}

export const editSMProductsSuccess = result => {
    return {
        type: UPDATE_MIDIA_PRODUCT_SUCCESS,
        payload: {result},
    }
}


export const findByIdSMProducts = id => {
    return {
        type: GET_BY_ID_SOCIAL_MIDIA_PRODUCT,
        payload: {id},
    }
}

export const findSMProductsIdSuccess = result => {
    return {
        type: GET_BY_ID_SOCIAL_MIDIA_PRODUCT_SUCCESS,
        payload: result,
    }
}

export const uploadSMProducts = ( dataForm) => {
    return {
        type: UPLOAD_IMAGE_MIDIA_PRODUCT,
        payload: {dataForm},
    }
}

export const uploadSMProductsSuccess = result => {
    return {
        type: UPLOAD_IMAGE_MIDIA_PRODUCT_SUCCESS,
        payload: result,
    }
}


export const allSMProductsPaginate = params => {
    return {
        type: PAGINATE_SOCIAL_MIDIA_PRODUCT,
        payload: { params },
    }
}

export const allSMProductsPaginateSuccess = result => {
    return {
        type: PAGINATE_SOCIAL_MIDIA_PRODUCT_SUCCESS,
        payload: { result },
    }
}

export const createSMProducts = (params, history) => {
    return {
        type: CREATE_SOCIAL_MIDIA_PRODUCT,
        payload: { params, history },
    }
}

export const createSMProductsSuccess = params => {
    return {
        type: CREATE_SOCIAL_MIDIA_PRODUCT_SUCCESS,
        payload: params,
    }
}

export const findSMProducts = query => {
    return {
        type: FIND_SOCIAL_MIDIA_PRODUCT,
        payload: { query },
    }
}

export const findSMProductsSuccess = data => {
    return {
        type: FIND_SOCIAL_MIDIA_PRODUCT_SUCCESS,
        payload: { data }
    }
}

export const apiFindSMProductsError = error => {
    return {
        type: FIND_SOCIAL_MIDIA_PRODUCT_API_ERROR,
        payload: { error },
    }
}

