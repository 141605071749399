import React, { Component } from "react";
import Leaflet from "leaflet";
import { Map, Marker, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import PropTypes from "prop-types"
import {Link, withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {withTranslation} from "react-i18next"
import icon_market from '../../assets/img/ic_marker_market.png';

Leaflet.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png";
delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
});

class SimpleMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
             lat: -22.5624067,
             lng: -47.3842187,
            zoom: 13,
           
          };

        }

  render() {
    const position = [this.state.lat, this.state.lng];
    return (
      <Map center={position} zoom={13} scrollWheelZoom={false} style={{ height: "400px" }}>
          <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> Insígnia Engenharia'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} />
        <GeoJSON  data={this.props.geojson} />
      </Map>
    );
  }
}
SimpleMap.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
    geojson: PropTypes.any,


}
const mapStateToProps = state => {
 

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    
    })(SimpleMap)
))