import React, {Component} from 'react'
import MetaTags from 'react-meta-tags'
import {
    Badge,
    CardBody,
    Container
} from "reactstrap"
import {findWhatsAppIdChannelListener} from "../../store/channels/whatsapp/actions"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {Card, Col, Row} from "react-bootstrap"
import bg_whatsapp from "../../assets/img/whatsapp_bg.png"
import {findLogSniperBot, restartSniperBot, stopSniperBot} from "../../store/whatsapp/actions"

class Index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            logs: [],
            load: true,
            sniper_shot: null
        }

    }

    componentDidMount() {
        // this.props.findWhatsAppIdChannelListener({company_id: 1})
        this.props.findLogSniperBot({ params: { company_id: 1 } })

        window.Echo.channel('chan-sniper-bot')
            .listen('.sniper.bot.options.log', response => {

                let shot = {
                    type: response.type,
                    message: response.message,
                    qrcode: response.qrcode
                }
                this.setState({sniper_shot: shot, logs: [...this.state.logs, {message: response.message}].reverse()})

            })
            .error(error => {
                console.log('error ', error)

            })


    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Row>
                        <Col sm={3}>
                            <Card>
                                <CardBody className="text-center">
                                    <Row className="align-items-md-center  mb-3">
                                        <Col sm={10} className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <span
                                                    className="h4 card-title">{this.props.t("QRCode WhatsApp")} </span>
                                            </div>
                                        </Col>
                                        <Col sm={2} className="inner-custom-pagination d-flex text-end">
                                            <div className="d-inline">
                                                <Badge
                                                    color="success"
                                                    className={"bg-success"}
                                                >
                                                    Online
                                                </Badge>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <img
                                                src={this.state.sniper_shot ? this.state.sniper_shot.type === 1 ? this.state.sniper_shot.qrcode : bg_whatsapp : bg_whatsapp}
                                                alt="QRCode" className="bg-whatsapp img-fluid mt-3 mb-3"/>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="text-md-right  ms-auto mb-2">
                                                <a onClick={() => {
                                                    this.props.restartSniperBot()
                                                }} className="btn btn-warning m-2 btn-sm text-light">
                                                    <i className="bx bx-loader"/>
                                                    <span> {this.props.t("Restart Service")}</span>
                                                </a>
                                                <a onClick={() => {
                                                    this.props.stopSniperBot()
                                                }} className="btn btn-danger m-2 btn-sm text-light">
                                                    <i className="bx bx-stop"/>
                                                    <span> {this.props.t("Stop Service")}</span>
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card>
                                <CardBody>
                                    <Row className="align-items-md-center  mb-3">
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <span
                                                    className="h4 card-title">{this.props.t("Logs Sniper Bot (Real Time)")}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {

                                            this.state.sniper_shot &&
                                            this.state.logs.length > 0 &&
                                            this.state.logs.map((item, index) => {
                                                return <Col key={index} sm={12}><span>{item.message}</span></Col>
                                            })


                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={5}>
                            <Card>
                                <CardBody>
                                    <Row className="align-items-md-center  mb-3">
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <span
                                                    className="h4 card-title">{this.props.t("History Logs Sniper Bot")}</span>
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row>
                                        {

                                            this.props.rows.length > 0 &&
                                            this.props.rows.map((item, index) => {
                                                return <Col key={index} sm={12}><span>({item.log_created_at}) {item.text}</span></Col>
                                            })


                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    sniper_shot: PropTypes.any,
    logs_msg: PropTypes.array,
    findWhatsAppIdChannelListener: PropTypes.func,
    restartSniperBot: PropTypes.func,
    stopSniperBot: PropTypes.func,
    findLogSniperBot: PropTypes.func,

    loading: PropTypes.any,
    loading_form: PropTypes.any,
    loading_btn_restart: PropTypes.any,
    loading_btn_stop: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    rows: PropTypes.array,


}
const mapStateToProps = state => {
    const {sniper_shot, logs_msg} = state.WhatsAppChannelListeners
    const {
        loading,
        loading_form,
        loading_btn_restart,
        loading_btn_stop,
        error,
        message,
        rows,
    } = state.SniperBOT
    return {
        sniper_shot,
        logs_msg,
        loading,
        loading_form,
        loading_btn_restart,
        loading_btn_stop,
        error,
        message,
        rows,
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
        findWhatsAppIdChannelListener,
        restartSniperBot,
        stopSniperBot,
        findLogSniperBot
    })(Index)
))