import React from 'react'
import { CardBody } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Card, Col, Row, Stack } from "react-bootstrap"
import UIBodyCAProducts from './body/Index'
import UITableCAProducts from './table/Index'

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            columns: [
                {
                    id: 1,
                    name: '#',
                    status: 1
                },
                {
                    id: 2,
                    name: 'Canais',
                    status: 1
                },
                {
                    id: 3,
                    name: 'Produtos',
                    status: 1
                },
                {
                    id: 4,
                    name: 'Disponibilidade',
                    status: 1
                },
                {
                    id: 5,
                    name: 'Ações',
                    status: 1
                },
            ],
        }

        this.renderPage = this.renderPage.bind(this)
        this.renderForm = this.renderForm.bind(this)
    }

    renderForm(page) {
        this.setState({ page: page })
    }

    renderPage() {
        const { page } = this.state
        const { promotion_id, type, company_id } = this.props

        switch (page) {
            case 0:
                return (<UITableCAProducts
                    columns={this.state.columns}
                    getFormRegister={this.renderForm}
                    promotion_id={promotion_id}
                    company_id={company_id}
                    type={type} />);
            case 1:
                return (<UIBodyCAProducts
                    getFormRegister={this.renderForm}
                    promotion_id={promotion_id}
                    type={type}
                    company_id={company_id} />);
        }
    }


    render() {
        const { rows, title } = this.props

        return (
            <Card>
                <CardBody>
                    {this.renderPage()}
                </CardBody>
            </Card>

        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
    title: PropTypes.string,

    type: PropTypes.any,
    company_id: PropTypes.any,

    rows: PropTypes.any,
    category_id: PropTypes.any,
    openDrawerProducts: PropTypes.func,
}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
})(Index)
))
