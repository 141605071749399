import React from 'react'
import { CardBody } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Card, Col, Row, Stack } from "react-bootstrap"
import { findProductsByCategory } from "../../../../store/catalog/productsByCategory/actions"

import { createProductCampaign } from "../../../../store/campaign/products/actions"


import UIFormCA from '../../form/index'
import UIFormProductCA from '../form_01/index'
import { toast } from 'react-hot-toast'


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            open: false,
            position: 'right',
            noOverlay: false,
            products: [],
            days_week: [],
            page: 0,
            dispacher_meta_wa: 1,
            dispacher_meta_instagram: 1,
            dispacher_meta_messenger: 1,
            dispacher_sms: 1,
            dispacher_google_rcs: 1,
            dispacher_google_rbm: 1,
            description: null,
            title: null,
            time_scheduler: null

        }
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.productSelected = this.productSelected.bind(this);
        this.getDescPerc = this.getDescPerc.bind(this);
        this.getDayWeeks = this.getDayWeeks.bind(this);
        this.removeDayWeeks = this.removeDayWeeks.bind(this);
        this.check_duplicates_weeks = this.check_duplicates_weeks.bind(this)
        this.getTime = this.getTime.bind(this)
        this.handlerCreate = this.handlerCreate.bind(this)
        this.getFieldsForm = this.getFieldsForm.bind(this)
        this.getFormSuccess = this.getFormSuccess.bind(this)
    }
    setPosition(e) {
        this.setState({ position: e.target.value });
    }

    setNoOverlay(e) {
        this.setState({ noOverlay: e.target.checked });
    }

    toggleDrawer() {
        this.setState({ open: !this.state.open, isEdit: false });
    }

    closeDrawer() {
        this.setState({ open: false, openEdit: false, isEdit: false });
    }

    onDrawerClose() {
        this.setState({ open: false, isEdit: false });
    }


    componentDidMount() {
        this.props.findProductsByCategory(this.props.company_id)

    }

    productSelected(item, type) {
        item['type'] = type
        const { products } = this.state
        if (products.length == 5) {
            toast.error('Máximo permitido é 5 produtos por campanha!')
            return;
        } else {
            if (products.length > 0) {
                products.map((p, index) => {
                    if (p.id != item.id) {
                        products.push(item)
                    }
                })
            } else {
                products.push(item)
            }

            this.setState({ products: products })
        }


        this.toggleDrawer()
    }
    removeDayWeeks(item) {

        if (this.state.days_week.length >= 1) {
            this.state.days_week.map((d, index) => {
                if (d.id === item.id) {
                    this.state.days_week.splice(index, 1);
                }
            })
            this.setState({ days_week: this.state.days_week })

        } else {
            this.state.days_week.splice(0, this.state.days_week.length);
            this.setState({ days_week: this.state.days_week })

        }


    }
    getDayWeeks(item) {

        if (this.state.days_week.length > 0) {
            this.state.days_week.map((d, index) => {
                if (d.id !== item.id) {
                    this.state.days_week.push(item)
                }
            })
        }
        if (this.state.days_week.length === 0) {
            this.state.days_week.push(item)
        }

        this.setState({ days_week: this.state.days_week })
    }
    check_duplicates_weeks(days) {
        const { days_week } = this.state;
        days.map((day, index) => {
            if (this.state.days_week.length > 0) {
                this.state.days_week.map((day_selected, index_selected) => {
                    if (day.id == day_selected.id) {
                        if (!day.is_selected) {
                            days_week.splice(index_selected, 1)
                        }
                    }
                })
            } else {
                this.setState({ days_week: [] })

            }

        })
    }
    getDescPerc(item) {
        this.setState({ coupom_discount: item })
    }
    getTime(e) {
        const { id, name, value } = e.target
        let tempState = {}
        tempState[name] = value
        this.setState(tempState)

    }

    handlerCreate() {

        const {
            products,
            days_week,
            dispacher_meta_wa,
            dispacher_meta_instagram,
            dispacher_meta_messenger,
            dispacher_sms,
            dispacher_google_rcs,
            dispacher_google_rbm,
            description,
            title,
            time_scheduler } = this.state;


        this.props.createProductCampaign({
            promotions: [],
            products,
            days_week,
            dispacher_meta_wa,
            dispacher_meta_instagram,
            dispacher_meta_messenger,
            dispacher_sms,
            dispacher_google_rcs,
            dispacher_google_rbm,
            description,
            title,
            time_scheduler,
            type: 2,
            company_id: 1
        }, this.getFormSuccess)


    }
    getFieldsForm(values) {
        this.setState(values)

    }
    getFormSuccess(page) {
        this.setState({ page: page })

    }

    renderPage(page) {
        const { products, category_id } = this.state

        const { company_id } = this.props

        switch (page) {
            case 0:
            case 1:
                return <>
                    <UIFormCA
                        getFieldsForm={this.getFieldsForm} />

                    <div className="text-md-right ms-auto text-end" >
                        <button
                            onClick={() => {
                                this.setState({ page: 2 })
                            }}
                            className="btn btn-dark btn-sm text-light"
                        >
                            <i className="bx bx-right-arrow-alt" />
                            <span> Próximo</span>
                        </button>
                    </div>

                </>
            case 2:
                return <UIFormProductCA
                    open={this.state.open}
                    position={this.state.position}
                    noOverlay={this.state.noOverlay}
                    lastPage={() => {
                        this.setState({ page: 1 })
                    }}
                    productSelected={this.productSelected}
                    rows={this.props.rows}
                    products={products}
                    category_id={category_id}
                    company_id={company_id}
                    openDrawerProduct={() => {
                        this.setState({ open: !this.state.open })
                    }}
                    closeDrawer={this.closeDrawer}
                    onDrawerClose={this.onDrawerClose}
                    getTime={this.getTime}
                    getDayWeeks={this.getDayWeeks}
                    removeDayWeeks={this.removeDayWeeks}
                    check_duplicates_weeks={this.check_duplicates_weeks}
                    onCreate={this.handlerCreate}
                />
            case 3:
                return <>Campanha Cadastrada com sucesso.</>
        }

    }

    render() {
        const { page } = this.state
        return <>

            <div className="text-md-right ms-auto mb-3">
                <a
                    onClick={() => this.props.getFormRegister(0)}
                    className="btn btn-dark btn-sm text-light"
                >
                    <i className="bx bx-left-arrow-alt" />
                    <span> Voltar</span>
                </a>
            </div>
            {this.renderPage(page)}
        </>



    }


}

Index.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.any,
    findProductsByCategory: PropTypes.func,
    getFormRegister: PropTypes.func,
    createProductCampaign: PropTypes.func,
    rows: PropTypes.any,
    company_id: PropTypes.any,
    promotion_id: PropTypes.any,
    type: PropTypes.any,


}
const mapStateToProps = state => {
    const {
        loading,
        rows,
        id
    } = state.ProductsCategory

    return {
        loading,
        rows,
        id
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    findProductsByCategory,

    createProductCampaign
})(Index)
))
