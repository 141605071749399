import React from 'react'
import { Col, Row, TabPane } from "reactstrap"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import PropTypes from "prop-types";
import { AvField, AvForm } from "availity-reactstrap-validation"
import WeekDay from 'components/WeekDay'
import Autocomplete from 'components/Autocomplete'
import UITimeSelect from 'components/UITimeSelect'


class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dispacher_meta_wa: 1,
            dispacher_meta_instagram: 1,
            dispacher_meta_messenger: 1,
            dispacher_sms: 1,
            dispacher_google_rcs: 1,
            dispacher_google_rbm: 1,
            days_week: [],
            category: null,
            category_id: null,

        }

        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnCheck = this.handleOnCheck.bind(this)
        this.getTime = this.getTime.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
    }
    getTime(e) {
        const { id, name, value } = e.target
        let tempState = {}
        tempState[name] = value
        this.setState(tempState)

    }
    handleOnCheck(e, last_value) {
        const { getFieldsForm } = this.props
        const { name, value } = e.target

        let newobj = {}
        newobj[name] = parseInt(last_value) === 1 ? 0 : 1
        this.setState(newobj)
        getFieldsForm(newobj)
    }

    handleOnChange(e) {
        const { getFieldsForm } = this.props
        const { id, name, value } = e.target
        let newobj = {}
        newobj[name] = value
        getFieldsForm(newobj)
    }

    async handleAutocomplete(_data, name_entity) {

        if (_data) {
            const { value, name, code, data } = _data
            let newState = {}

            newState[name] = value
            newState[name + '_id'] = code

            //   console.log('newState ', newState)
            //   console.log('name_entity ', name_entity)
            //console.log('nadata ', data)

            this.setState(newState)

            if (data) {
                let newobj = {}
                newobj[name] = {
                    id: code,
                    promotion_id: data.promotion_id,
                    code: code,
                    name: value
                }

                this.props.getFieldsForm(newState)
            }
        }
    }
    render() {
        const { company_id,getTime, getDayWeeks, removeDayWeeks, check_duplicates_weeks } = this.props

        return (
            <>
                <div className='w-75'>
                    <span
                        className="h4 card-title">[COPY] engajamento da campanha [CA][PROMOÇÕES]</span>
                    <p className="card-title-desc mt-2">
                        Descritivo promocional, para campanha de produtos, configuravel, de acordo com o canal de disparo.
                    </p>
                </div>

                <Row>
                    <Col sm={12}>
                        <span className="card-title-desc mb-2">
                            Dias de disparo <small className='text-danger'> escolha abaixo</small>
                        </span>
                        <WeekDay
                            company_id={company_id}
                            getDayWeeks={getDayWeeks}
                            removeDayWeeks={removeDayWeeks}
                            check_duplicates_weeks={check_duplicates_weeks} />
                    </Col>
                    <Col sm={12}>
                        <div className='w-25'>
                            <UITimeSelect
                                id="time_scheduler"
                                name="Informe o horário de inicio."
                                getTime={getTime} />
                        </div>
                    </Col>
                    <Col sm={12} className="mb-2 w-50">
                        <Autocomplete
                            onSourceSelect={(d, e) => this.handleAutocomplete(d, e)}
                            nameFieldSelect='category_id'
                            limit={10}
                            inputProps={{
                                id: 'category',
                                name: 'category',
                                label: "Promoção",
                                placeholder: "Pesquisar promoção...",
                                param_id: '&company_id='.concat(company_id),
                                value: this.state.category ? this.state.category : this.props.promotion,
                                url: 'search/promotions/category'
                            }} />

                    </Col>
                    <Col sm={12}>
                        <AvForm
                            className="form-horizontal">

                            <Row>
                                <Col >
                                    <AvField
                                        name="title"
                                        label="Titulo"
                                        className="form-control"
                                        placeholder="Ex: Quartou, Quintou com promoção"
                                        value={this.state.title ? this.state.title : this.props.title}
                                        onChange={(e) => this.handleOnChange(e)}
                                        type="text"
                                    />

                                </Col>
                                <Col sm={4} >
                                    <AvField
                                        value={this.state.status}
                                        type="select" name="select"
                                        label={this.props.t("Status")} >
                                        <option value="1">Ativo</option>
                                        <option value="0">Inativo</option>
                                    </AvField>
                                </Col>
                                <Col sm={12}>
                                    <AvField
                                        name="description"
                                        label="Descrição"
                                        className="form-control"
                                        placeholder="Digite uma descrição"
                                        type="textarea"
                                        value={this.state.text ? this.state.text : this.props.text}
                                        onChange={(e) => this.handleOnChange(e)}
                                        rows={5}
                                        required
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                    </Col>

                    <Col xl={12} sm={12}>
                        <div >
                            <h5 className="font-size-14 mb-2">Canais de Disparo:</h5>

                            <Row>

                                <Col xl={6} sm={6}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_meta_wa"
                                            id="dispacher_meta_wa"
                                            value="1"
                                            checked={this.state.dispacher_meta_wa ? this.state.dispacher_meta_wa : this.props.dispacher_meta_wa}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_meta_wa)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_meta_wa"
                                        >
                                            Whatsapp Business API (Meta Oficial)
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_meta_instagram"
                                            id="dispacher_meta_instagram"
                                            value="1"
                                            checked={this.state.dispacher_meta_instagram ? this.state.dispacher_meta_instagram : this.props.dispacher_meta_instagram}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_meta_instagram)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_meta_instagram"
                                        >
                                            Instagram Message (Meta Oficial)
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_meta_messenger"
                                            id="dispacher_meta_messenger"
                                            value="1"
                                            checked={this.state.dispacher_meta_messenger ? this.state.dispacher_meta_messenger : this.props.dispacher_meta_messenger}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_meta_messenger)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_meta_messenger"
                                        >
                                            Facebook message (Meta Oficial)
                                        </label>
                                    </div>
                                </Col>
                                <Col xl={6} sm={6}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_sms"
                                            id="dispacher_sms"
                                            value="1"
                                            checked={this.state.dispacher_sms ? this.state.dispacher_sms : this.props.dispacher_sms}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_sms)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_sms"
                                        >
                                            SMS Message (API)
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_google_rbm"
                                            id="dispacher_google_rbm"
                                            value="1"
                                            checked={this.state.dispacher_google_rbm ? this.state.dispacher_google_rbm : this.props.dispacher_google_rbm}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_google_rbm)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_google_rbm"
                                        >
                                            RBM Service (Google)
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_google_rcs"
                                            id="dispacher_google_rcs"
                                            value="1"
                                            checked={this.state.dispacher_google_rcs ? this.state.dispacher_google_rcs : this.props.dispacher_google_rcs}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_google_rcs)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_google_rcs"
                                        >
                                            RCS Service (Google)
                                        </label>
                                    </div>
                                </Col>
                            </Row>


                        </div>
                    </Col>

                </Row>
            </>
        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    getFieldsForm: PropTypes.func,
    company_id: PropTypes.any,
    dispacher_meta_wa: PropTypes.any,


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
