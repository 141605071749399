module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID:"",
    SECRET: "",
  },
  google_maps: {
    token: "AIzaSyCZBKd5P8qvxf3L0bTx1VO_pqxJJ6avnYY",
  },
  facebook: {
    APP_ID: "",
  },
}
