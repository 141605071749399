export const FIND_PROMOTIONS_BY_COMPANIES = "FIND_PROMOTIONS_BY_COMPANIES"
export const FIND_PROMOTIONS_BY_COMPANIES_SUCCESS = "FIND_PROMOTIONS_BY_COMPANIES_SUCCESS"

export const FIND_PROMOTION_BY_ID = "FIND_PROMOTION_BY_ID"
export const FIND_PROMOTION_BY_ID_SUCCESS = "FIND_PROMOTION_BY_ID_SUCCESS"

export const CREATE_AND_ADD_PRODUCT_PROMOTION = "CREATE_AND_ADD_PRODUCT_PROMOTION"
export const CREATE_AND_ADD_PRODUCT_PROMOTION_SUCCESS = "CREATE_AND_ADD_PRODUCT_PROMOTION_SUCCESS"

export const REMOVE_PRODUCT_PROMOTION = "REMOVE_PRODUCT_PROMOTION"
export const REMOVE_PRODUCT_SUCCESS = "REMOVE_PRODUCT_SUCCESS"

export const ADD_PRODUCT_PROMOTION = "ADD_PRODUCT_PROMOTION"
export const ADD_PRODUCT_PROMOTION_SUCCESS = "ADD_PRODUCT_PROMOTION_SUCCESS"


export const FIND_PROMOTIONS_BY_COMPANIES_API_ERROR = "FIND_PROMOTIONS_BY_COMPANIES_API_ERROR"
