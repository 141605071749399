import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"

import Promotions from "./promotions/reducer"
import SniperBOT from "./whatsapp/reducer"
import Catalog from "./catalog/home/reducer"
import Company from "./company/reducer"
import ProductsCategory from "./catalog/productsByCategory/reducer"
import ProductsCategorySize from "./catalog/productsByCategorySize/reducer"
import PaymentMethods from "./catalog/paymentMethods/reducer"
import CouponsDiscounts from "./coupomDiscounts/reducer"
import Orders from "./order/reducer"
import Routes from "./routes/reducer"
import OrdersChannelListeners from "./channels/orders/reducer"
import RoutesChannelListeners from "./channels/routes/reducer"
import WhatsAppChannelListeners from "./channels/whatsapp/reducer"
import GeoLocation from "./search/location/reducer"
import Category from "./category/reducer"

import SMProducts from "./socialmidia/products/reducer"
import SMPromotions from "./socialmidia/promotions/reducer"

import CampaingnProducts from "./campaign/products/reducer"
import CampaingnPromotions from "./campaign/promotions/reducer"



const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  GeoLocation,
  Catalog,
  ProductsCategory,
  ProductsCategorySize,
  PaymentMethods,
  CouponsDiscounts,
  Orders,
  Routes,
  OrdersChannelListeners,
  RoutesChannelListeners,
  WhatsAppChannelListeners,
  SniperBOT,
  Promotions,
  Company,
  Category,
  SMPromotions,
  SMProducts,
  CampaingnProducts,
  CampaingnPromotions

})

export default rootReducer
