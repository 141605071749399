import React from 'react'
import { Col, Row, TabPane } from "reactstrap"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import PropTypes from "prop-types";
import { AvField, AvForm } from "availity-reactstrap-validation"


class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dispacher_meta_wa: 1,
            dispacher_meta_instagram: 1,
            dispacher_meta_messenger: 1,
            dispacher_sms: 1,
            dispacher_google_rcs: 1,
            dispacher_google_rbm: 1,

        }

        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnCheck = this.handleOnCheck.bind(this)
    }

    handleOnCheck(e, last_value) {
        const { getFieldsForm } = this.props
        const { name, value } = e.target

        let newobj = {}
        newobj[name] = parseInt(last_value) === 1 ? 0 : 1
        this.setState(newobj)
        getFieldsForm(newobj)
    }

    handleOnChange(e) {
        const { getFieldsForm } = this.props
        const { id, name, value } = e.target
        let newobj = {}
        newobj[name] = value
        getFieldsForm(newobj)
    }


    render() {

        return (
            <>
                <div className='w-75'>
                    <span
                        className="h4 card-title">[COPY] engajamento da campanha [CA][PRODUTOS]</span>
                    <p className="card-title-desc mt-2">
                        Descritivo promocional, para campanha de produtos, configuravel, de acordo com o canal de disparo.
                    </p>
                </div>

                <Row>
                    <Col sm={12}>
                        <AvForm
                            className="form-horizontal">

                            <Row>
                                <Col >
                                    <AvField
                                        name="title"
                                        label="Nome"
                                        className="form-control"
                                        placeholder="Ex: Produtos em exclusividade"
                                        value={this.state.title ? this.state.title : this.props.title}
                                        onChange={(e) => this.handleOnChange(e)}
                                        type="text"
                                    />

                                </Col>
                                <Col sm={4} >
                                    <AvField
                                        value={this.state.status}
                                        type="select" name="select"
                                        label={this.props.t("Status")} >
                                        <option value="1">Ativo</option>
                                        <option value="0">Inativo</option>
                                    </AvField>
                                </Col>
                                <Col sm={12}>
                                    <AvField
                                        name="description"
                                        label="Descrição"
                                        className="form-control"
                                        placeholder="Digite uma descrição"
                                        type="textarea"
                                        value={this.state.text ? this.state.text : this.props.text}
                                        onChange={(e) => this.handleOnChange(e)}
                                        rows={5}
                                        required
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                    </Col>

                    <Col xl={12} sm={12}>
                        <div >
                            <h5 className="font-size-14 mb-2">Canais de Disparo:</h5>

                            <Row>

                                <Col xl={6} sm={6}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_meta_wa"
                                            id="dispacher_meta_wa"
                                            value="1"
                                            checked={this.state.dispacher_meta_wa ? this.state.dispacher_meta_wa : this.props.dispacher_meta_wa}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_meta_wa)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_meta_wa"
                                        >
                                            Whatsapp Business API (Meta Oficial)
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_meta_instagram"
                                            id="dispacher_meta_instagram"
                                            value="1"
                                            checked={this.state.dispacher_meta_instagram ? this.state.dispacher_meta_instagram : this.props.dispacher_meta_instagram}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_meta_instagram)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_meta_instagram"
                                        >
                                            Instagram Message (Meta Oficial)
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_meta_messenger"
                                            id="dispacher_meta_messenger"
                                            value="1"
                                            checked={this.state.dispacher_meta_messenger ? this.state.dispacher_meta_messenger : this.props.dispacher_meta_messenger}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_meta_messenger)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_meta_messenger"
                                        >
                                            Facebook message (Meta Oficial)
                                        </label>
                                    </div>
                                </Col>
                                <Col xl={6} sm={6}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_sms"
                                            id="dispacher_sms"
                                            value="1"
                                            checked={this.state.dispacher_sms ? this.state.dispacher_sms : this.props.dispacher_sms}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_sms)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_sms"
                                        >
                                            SMS Message (API)
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_google_rbm"
                                            id="dispacher_google_rbm"
                                            value="1"
                                            checked={this.state.dispacher_google_rbm ? this.state.dispacher_google_rbm : this.props.dispacher_google_rbm}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_google_rbm)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_google_rbm"
                                        >
                                            RBM Service (Google)
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="dispacher_google_rcs"
                                            id="dispacher_google_rcs"
                                            value="1"
                                            checked={this.state.dispacher_google_rcs ? this.state.dispacher_google_rcs : this.props.dispacher_google_rcs}
                                            onChange={(e) => this.handleOnCheck(e, this.state.dispacher_google_rcs)}

                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="dispacher_google_rcs"
                                        >
                                            RCS Service (Google)
                                        </label>
                                    </div>
                                </Col>
                            </Row>


                        </div>
                    </Col>

                </Row>
            </>
        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    getFieldsForm: PropTypes.func,

    dispacher_meta_wa: PropTypes.any,


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
