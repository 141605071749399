import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";
import toast from "react-hot-toast"


const getOrderByNumber = data => get(url.GET_ORDER_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const delOrder = data => del(url.DELETE_ORDER.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        toast.error(message)
        throw err.response.data
    })

const postCreateOrder = data => post(url.POST_CREATE_ORDER, data)
    .catch(err => {
        const {title, message} = err.response.data;
        toast.error(message)
        throw err.response.data
    })

const postPrinterOrder = ({company_id, order_id}) => get(url.POST_PRINTER_ORDER.concat('/').concat(order_id).concat('/').concat(company_id))
    .catch(err => {
        const {title, message} = err.response.data;
        toast.error(message)
        throw err.response.data
    })

const putEditStatusOrder = data => put(url.PUT_EDIT_STATUS_ORDER, data)
    .catch(err => {
        const {title, message} = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const getOrdersPaginate = ({page, limit, company_id}) => get(url.GET_ORDERS_PAGINATE, { params: { page, limit, company_id} })
    .catch(err => {
        const {title, message} = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const getFilterOrdersPaginate = data => get(url.GET_ORDERS_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const getSearchOrders = params => get(url.GET_SEARCH_ORDERS, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getOrdersPaginate,
    getFilterOrdersPaginate,
    putEditStatusOrder,
    postCreateOrder,
    delOrder,
    getOrderByNumber,
    postPrinterOrder,
    getSearchOrders
}
