import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE,
} from "./actionTypes"


import {
    findProductsByCategoryAndSizeSuccess,
    apiFindProductsByCategoryAndSizeError
} from "./actions"


import {
    findProductsByCategoryAndSizeID
} from "../../../helpers/catalog_helper"

function* findProductsByCategoryAndSize({payload: {query}}) {
    try {

        const response = yield call(findProductsByCategoryAndSizeID, query)
        yield put(findProductsByCategoryAndSizeSuccess(response))

    } catch (error) {
        yield put(apiFindProductsByCategoryAndSizeError(error))
    }
}


function* CatalogProductsByCategoryAndSizeSaga() {
    yield takeEvery(FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE, findProductsByCategoryAndSize)
}

export default CatalogProductsByCategoryAndSizeSaga
