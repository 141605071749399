import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Col, Row } from "reactstrap"
import "./days_week.css"
import { Stack } from "react-bootstrap"
import NumberFormat from "./NumberFormat"

/**
 * entende que configura o modo de exibição
 * type == 1 - modelo pizza tamanho broto, grande etc..
 * type == 2 - tipo de massa
 * type == 3 - modelo borda pizza
 * type == 4 - entende que é comum padrão. 
 */
class UIRadioDinamList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
        }

    }

    componentDidMount() {
        if (this.props.type == 1) {

            let rows = [];
            if (this.props.rows.length > 0) {
                this.props.rows.map((item, index) => {
                    rows.push({
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        qtd_flavors: item.qtd_flavors,
                        qtd_pieces: item.qtd_pieces,
                        type: 1
                    });
                    rows.push({
                        id: item.id,
                        name: '1/2 ' + item.name,
                        price: (parseFloat(item.price) / 2),
                        qtd_flavors: item.qtd_flavors,
                        qtd_pieces: (parseInt(item.qtd_pieces) / 2),
                        type: 2
                    });
                });

                this.setState({ rows });
            }
        } else {
            this.setState({ rows: this.props.rows });
        }
    }


    render() {
        return (
            <div className="mb-2">
                <span className="card-title-desc mb-2">
                    {this.props.title}
                </span>

                <Row onChange={this.props.getRadioType}>

                    {
                        this.state.rows.map((item, index) => {
                            return (
                                <Col sm={this.state.rows.length > 2 ? ((this.state.rows % 2) != 0) ? 6 : 3 : 6} key={index}>
                                    <div className="form-check" >
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={this.props.name}
                                            id={item.id}
                                            value={JSON.stringify(item)}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={this.props.name}
                                        >
                                            {
                                                this.props.type == 1 &&
                                                item.name + ' (' + item.qtd_pieces + ' PEDAÇOS ) '
                                                + Number(item.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

                                            }
                                            {
                                                this.props.type == 2 &&
                                                item.name + ' ' + (parseInt(item.price) > 0 ? Number(item.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '')

                                            }
                                            {
                                                this.props.type == 3 &&
                                                item.name + ' ' + (parseInt(item.price) > 0 ? Number(item.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '')

                                            }
                                        </label>
                                    </div>
                                </Col>
                            )
                        })}


                </Row>
            </div>
        )
    }
}

UIRadioDinamList.propTypes = {
    getRadioType: PropTypes.func,
    name: PropTypes.any,
    rows: PropTypes.any,
    title: PropTypes.any,
    type: PropTypes.any,
}

export default UIRadioDinamList
