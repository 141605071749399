import {takeEvery, put, call} from "redux-saga/effects"

import {
    LISTENER_ORDERS_CARD_ID_CHANNEL,
    LISTENER_ORDERS_CHANNEL_BY_ID_COMPANY
} from "./actionTypes"


import {
    findOrderIdChannelListenerSuccess,
    findOrderCompanyIdChannelListenerSuccess,
    channelCreateOrEditOrderError
} from "./actions"


import {
    findOrderIdChannelListener,
    findCompanyIdChannelListener
} from "../../../helpers/channels/orders_listeners"

function* findOrderIdChannel({payload: {params}}) {
    try {

        const {e} = yield call(findOrderIdChannelListener, params)
        console.log('ECHo ', e)
        yield put(findOrderIdChannelListenerSuccess(e))


    } catch (error) {
        yield put(channelCreateOrEditOrderError(error))
    }
}

function* findOrderCompanyIdChannel({payload: {params}}) {
   try {

       const {e} = yield call(findCompanyIdChannelListener, params)

       yield put(findOrderCompanyIdChannelListenerSuccess(e))

   } catch (error) {
        yield put(channelCreateOrEditOrderError(error))
    }
}


function* createOrEditOrdersChannelSaga() {
    yield takeEvery(LISTENER_ORDERS_CARD_ID_CHANNEL, findOrderIdChannel)
    yield takeEvery(LISTENER_ORDERS_CHANNEL_BY_ID_COMPANY, findOrderCompanyIdChannel)
}

export default createOrEditOrdersChannelSaga
