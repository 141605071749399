import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Col, Row } from "reactstrap"
import "./days_week.css"
import { Stack } from "react-bootstrap"

class UITimeSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }

    }


    render() {
        return (
            <div className="mt-2 mb-2">
                <span className="card-title-desc mb-2">
                    {this.props.name}
                </span>

                <input
                    onChange={this.props.getTime}
                    type="time"
                    className="form-control"
                    name={this.props.id}
                    id={this.props.id}
                    label={this.props.name}
                />

            </div>
        )
    }
}

UITimeSelect.propTypes = {
    getTime: PropTypes.func,
    name: PropTypes.any,
    id: PropTypes.any,
}

export default UITimeSelect
