//LOGIN
export const POST_LOGIN = "/api/v1/access/login"
export const POST_PASSWORD_FORGET = "/api/v1/access/recovery"
//HOME
export const GET_COMPANIES_BY_HASH = "/catalog/home"
export const GET_COMPANIES_BY_QUERY = "/search/home"

export const GET_PRODUCTS_GROUP_BY_CATEGORY = "/api/v1/category/prods-group"

export const GET_PAYMENT_METHODS = "/payments/methods"
export const GET_COUPONS_DISCOUNTS = "/payments/coupons"
export const GET_COUPONS_DISCOUNTS_BY_TYPE = "/api/v1/discount-coupons/type"

//GEO
export const GET_GEO_SEARCH_ADDRESS = "/geo/search/map/address"
export const GET_GEO_REVERSE = "/geo/search/map/reverse"

// ORDER
export const GET_SEARCH_ORDERS = "/orders"
export const GET_ORDERS_PAGINATE_FILTERS = "/orders"
export const GET_ORDERS_PAGINATE = "/orders/paginate"
export const PUT_EDIT_STATUS_ORDER = "/orders/status"
export const POST_CREATE_ORDER = "/orders/create"
export const POST_PRINTER_ORDER = "/orders/printer"
export const DELETE_ORDER = "/orders"
export const GET_ORDER_BY_ID = "/orders"

// ROUTE
export const GET_SEARCH_ROUTES = "/geo/routes"
export const GET_ROUTES_PAGINATE_FILTERS = "/geo/routes"
export const GET_ROUTES_PAGINATE = "/geo/routes/paginate"
export const PUT_EDIT_STATUS_ROUTE = "/geo/routes/status"
export const POST_CREATE_ROUTE = "/geo/routes/create"
export const POST_PRINTER_ROUTE = "/geo/routes/printer"
export const DELETE_ROUTE = "/geo/routes"
export const GET_ROUTE_BY_ID = "/geo/routes"


//SNIPER BOT
export const GET_LOG_SNIPER_BOT = "/snipers/shooters/log/entities"
export const RESTART_SNIPER_BOT = "/snipers/room/restart"
export const STOP_SNIPER_BOT = "/snipers/room/stop"

// PROMOTIONS
export const GET_PROMOTIONS_BY_COMPANIES = "/promotions/companies/paginate"
export const GET_PROMOTION_BY_ID = "/promotions"
export const POST_CREATE_PROMOTION = "/promotions"
export const POST_CREATE_PROMOTION_PRODUCTS = "/promotions/products"
export const POST_ADD_PRODUCT_PROMOTION = "/promotions/companies/products"
export const DELETE_PRODUCT_PROMOTION = "/promotions/companies/products"

// CAMPAIGN PROMOTIONS
export const GET_PROMOTIONS_BY_CAMPAIGN = "/promotions/companies/paginate"
export const POST_CREATE_PROMOTION_CAMPAIGNS = "/promotions/products"
export const POST_ADD_PRODUCT_CAMPAIGN = "/promotions/companies/products"
export const DELETE_CAMPAIGN_PROMOTIONS = "/promotions/companies/products"
export const POST_ADD_CAMPAIGN_PROMOTIONS = "/promotions/companies/products"

// CAMPAIGN PRODUCT
export const GET_PRODUCT_BY_CAMPAIGN = "/promotions/companies/paginate"
export const GET_CAMPAIGN_BY_ID = "/promotions"
export const POST_CREATE_CAMPAIGN = "/campaigns"
export const POST_ADD_CAMPAIGN_PRODUCTS = "/promotions/companies/products"
export const DELETE_CAMPAIGN_PRODUCT = "/promotions/companies/products"
export const GET_CAMPAIGN_PRODUCT_PAGINATE = "/campaigns/products/paginate";



// COMPANIES
export const GET_DAYS_WEEK_BY_COMPANIES = "/api/v1/companies/days-week"

//Categories Prmo
export const GET_CATEGORIES_PROMOTIONS = "/api/v1/category/promotions"

//SOCIAL MIDIA PRODUCT
export const GET_SOCIAL_MIDIA_PRODUCT = "";
export const POST_CREATE_SOCIAL_MIDIA_PRODUCT = "/api/v1/social-midia/feeds/products";
export const GET_SOCIAL_MIDIA_PRODUCT_PAGINATE = "/api/v1/social-midia/feeds/products/paginate";
export const POST_UPLOAD_SOCIAL_MIDIA_PRODUCT = "/upload/sm-feed/products"
export const GET_SOCIAL_MIDIA_PRODUCT_BY_ID = "/api/v1/social-midia/feeds/products"
export const PUT_EDIT_SOCIAL_MIDIA_PRODUCT = "/api/v1/social-midia/feeds/products";


//SOCIAL MIDIA PROMOTION
export const GET_SOCIAL_MIDIA_PROMOTION = "";
export const POST_CREATE_SOCIAL_MIDIA_PROMOTION = "/api/v1/social-midia/feeds/promotions";
export const GET_SOCIAL_MIDIA_PROMOTION_PAGINATE = "/api/v1/social-midia/feeds/promotions/paginate";
export const POST_UPLOAD_SOCIAL_MIDIA_PROMOTION = "/upload/sm-feed/promotions"
export const GET_SOCIAL_MIDIA_PROMOTION_BY_ID = "/api/v1/social-midia/feeds/promotions"
export const PUT_EDIT_SOCIAL_MIDIA_PROMOTION = "/api/v1/social-midia/feeds/promotions";

