import {
    GET_CATEGORIES_PROMOTIONS,
    GET_CATEGORIES_PROMOTIONS_SUCCESS,
    GET_CATEGORIES_PROMOTIONS_API_ERROR
} from "./actionTypes"


export const getCategorySectionPromotion = params => {
    return {
        type: GET_CATEGORIES_PROMOTIONS,
        payload: { params },
    }
}

export const getCategorySectionPromotionSuccess = params => {
    return {
        type: GET_CATEGORIES_PROMOTIONS_SUCCESS,
        payload: params,
    }
}

export const apiCategorySectionPromotionError = error => {
    return {
        type: GET_CATEGORIES_PROMOTIONS_API_ERROR,
        payload: { error },
    }
}

