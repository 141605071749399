import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    FIND_PRODUCT_BY_CAMPAIGN,
    FIND_PRODUCT_BY_ID,
    PAGINATE_PRODUCT_CAMPAIGN,
    CREATE_AND_ADD_PRODUCT_CAMPAIGN,
    REMOVE_PRODUCT_CAMPAIGN,
    ADD_PRODUCT_CAMPAIGN,
} from "./actionTypes"


import {
    allProductCampaignPaginateSuccess,
    createProductCampaignSuccess,
    addProductCampaignSuccess,
    findProductByCampaignSuccess,
    removeProductCampaignSuccess,
    findProductCampaignByIdSuccess,
    apiFindProductCampaignError
} from "./actions"


import {
    findProductByCampaignAPI,
    findProductCampaignByIdAPI,
    createProductCampaignAPI,
    addProductCampaignAPI,
    getProductCampaignPaginateAPI,
    removeProductCampaignAPI

} from "../../../helpers/campaign_product_helper"

function* paginateProductCampaign({payload: {params}}) {
    try { 

        const response = yield call(getProductCampaignPaginateAPI, params)

        yield put(allProductCampaignPaginateSuccess(response))

    } catch (error) {
        yield put(apiFindProductCampaignError(error))
    }
}

function* findProductCampaignById({ payload: { params } }) {
    try {

        const response = yield call(findProductCampaignByIdAPI, params)
        const { data } = response;

        yield put(findProductCampaignByIdSuccess(data))

    } catch (error) {
        yield put(apiFindProductCampaignError(error))
    }
}

function* removeProductCampaign({ payload: { params } }) {
    try {

        const response = yield call(removeProductCampaignAPI, params)
        const { data } = response;

        yield put(removeProductCampaignSuccess(data))

    } catch (error) {
        yield put(apiFindProductCampaignError(error))
    }
}

function* addProductCampaign({ payload: { params } }) {
    try {

        const response = yield call(addProductCampaignAPI, params)
        const { data } = response;

        yield put(addProductCampaignSuccess(data))

    } catch (error) {
        yield put(apiFindProductCampaignError(error))
    }
}

function* createProductCampaign({ payload: { params, renderPage } }) {
    try {

        const response = yield call(createProductCampaignAPI, params)
        const { message, error } = response;

        yield put(createProductCampaignSuccess(response))
        //NotificationManager.info(message, 'Aviso');
        renderPage(3)
    } catch (error) {
        yield put(apiFindProductCampaignError(error))
    }
}

function* findProductByCampaign({ payload: { params } }) {
    try {

        const response = yield call(findProductByCampaignAPI, params)


        yield put(findProductByCampaignSuccess(response))

    } catch (error) {
        yield put(apiFindProductCampaignError(error))
    }
}


function* CampaignProductsSaga() {
    yield takeEvery(FIND_PRODUCT_BY_CAMPAIGN, findProductByCampaign)
    yield takeEvery(FIND_PRODUCT_BY_ID, findProductCampaignById)
    yield takeEvery(CREATE_AND_ADD_PRODUCT_CAMPAIGN, createProductCampaign)
    yield takeEvery(REMOVE_PRODUCT_CAMPAIGN, removeProductCampaign)
    yield takeEvery(ADD_PRODUCT_CAMPAIGN, addProductCampaign)
    yield takeEvery(PAGINATE_PRODUCT_CAMPAIGN, paginateProductCampaign)
}

export default CampaignProductsSaga
