import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import PropTypes from "prop-types";
import { MetaTags } from 'react-meta-tags'
import FormSMSteps from './steps/index'
import TableSM from './table/index'
import FormSMActionSteps from './actions/index'
import { createSMProducts, findByIdSMProducts, editSMProducts, uploadSMProducts } from '../../../store/actions'
import slugify from 'react-slugify';

class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            page_level_0: 0,
            page: 0,
            passedSteps: 0,
            category_gmerchant: null,
            category_metafacebook: null,
            company: null,
            description: null,
            exists_file: null,
            file_image: null,
            is_visible_gmerchant: null,
            is_visible_meta: null,
            ncm_gtin: null,
            product: null,
            brand: null,
            url_video: null,
            company_id: 1,
            is_edit: false,
            columns: [
                {
                    id: 1,
                    name: '#',
                    status: 1
                },
                {
                    id: 2,
                    name: 'Detalhe',
                    status: 1
                },
                {
                    id: 3,
                    name: 'Ações',
                    status: 1
                },
            ],


        }
        this.getStepSelected = this.getStepSelected.bind(this)
        this.getFieldsForm = this.getFieldsForm.bind(this)
        this.sendForm = this.sendForm.bind(this)
        this.getFormRegister = this.getFormRegister.bind(this)
        this.findEntity = this.findEntity.bind(this)
        this.sendImgUpload = this.sendImgUpload.bind(this)
    }

    componentDidMount() {

    }

    findEntity(e) {
        this.props.findByIdSMProducts(e)
        this.setState({ is_edit: true, page_level_0: 1 })
    }

    getStepSelected(page) {
        this.setState({ page })

    }
    sendImgUpload(value) {
        this.props.uploadSMProducts(value)
    }
    getFieldsForm(values) {
        this.setState(values)

    }
    getFormRegister(page) {
        this.setState({ page_level_0: page })
    }
    sendForm() {
        const {
            category_gmerchant,
            category_metafacebook,
            description,
            file_image,
            is_visible_gmerchant,
            is_visible_meta,
            is_visible_tiktok,
            is_visible_kwai,
            ncm_gtin,
            product,
            tags,
            brand,
            url_video,
            company_id, is_edit
        } = this.state
        const { id } = this.props

        var data = new FormData();

        if (category_gmerchant != null) {
            data.append('category_gmerchant_id', category_gmerchant.id);

        }
        if (category_metafacebook != null) {
            data.append('category_metafacebook_id', category_metafacebook.id);

        }
        if (product != null) {
            data.append('product_id', product.id);
        }
        if (company_id != null) {
            data.append('company_id', company_id);
        }
        if (ncm_gtin != null) {
            data.append('ncm_gtin', ncm_gtin);
        }
        if (is_visible_meta != null) {
            data.append('is_visible_meta', is_visible_meta);
        }
        if (is_visible_gmerchant != null) {
            data.append('is_visible_gmerchant', is_visible_gmerchant);
        }
        if (is_visible_tiktok != null) {
            data.append('is_visible_tiktok', is_visible_tiktok);
        }
        if (is_visible_kwai != null) {
            data.append('is_visible_kwai', is_visible_kwai);
        }
        if (url_video != null) {
            data.append('url_video', url_video);
        }
        if (description != null) {
            data.append('description', description);
        }
        if (tags != null) {
            data.append('tags', tags);
        }
        if (brand != null) {
            data.append('brand', brand);
        }

        if (is_edit == true) {
            data.append('id', id);

            this.props.editSMProducts({
                category_gmerchant_id: category_gmerchant != null ? category_gmerchant.id : null,
                category_metafacebook_id: category_metafacebook != null ? category_metafacebook.id : null,
                product_id: product != null ? product.id : null,
                company_id: company_id != null ? company_id : null,
                ncm_gtin: ncm_gtin != null ? ncm_gtin : null,
                is_visible_meta: is_visible_meta != null ? is_visible_meta : null,
                is_visible_gmerchant: is_visible_gmerchant != null ? is_visible_gmerchant : null,
                is_visible_tiktok: is_visible_tiktok != null ? is_visible_tiktok : null,
                is_visible_kwai: is_visible_kwai != null ? is_visible_kwai : null,
                url_video: url_video != null ? url_video : null,
                description: description != null ? description : null,
                tags: tags != null ? tags : null,
                brand: brand != null ? brand : null,
                id: id != null ? id : null,

            }, this.getFormRegister)
        } else {

            const { name } = product
            let name_replaced = name.replace('-', '');
            let name_file = slugify((name_replaced.concat(' pizzaria fontanella')).toLowerCase())

            data.append('name_file', name_file);
            data.append('type_dir_file', 'image');
            data.append('files', file_image);
            this.props.createSMProducts(data, this.props.history)
        }

    }

    renderPage() {
        const { page_level_0, is_edit } = this.state
        const {
            category_gmerchant, category_metafacebook,
            id, product, company, ncm_gtin, brand,
            is_visible_meta, is_visible_gmerchant, is_visible_tiktok,
            is_visible_kwai, image, type_dir_file, url_image,
            url_video, description, tags, status
        } = this.props
        switch (page_level_0) {
            case 0:
                return (<TableSM
                    getFormRegister={this.getFormRegister}
                    findEntityEdit={(e) => this.findEntity(e)}
                    columns={this.state.columns}
                />)
            case 1:
                return (<FormSMSteps
                    getStepSelected={this.getStepSelected}
                    getFieldsForm={this.getFieldsForm}
                    handleFileImgDropUpdate={this.sendImgUpload}
                    page={this.state.page}
                    is_edit={is_edit}
                    activeTab={this.state.page}
                    passedSteps={this.state.passedSteps}
                    title={"Feed de Produtos"}

                    id={id}
                    category_gmerchant={category_gmerchant}
                    category_metafacebook={category_metafacebook}
                    product={product}
                    company={company}
                    ncm_gtin={ncm_gtin}
                    brand={brand}
                    is_visible_meta={is_visible_meta}
                    is_visible_gmerchant={is_visible_gmerchant}
                    is_visible_tiktok={is_visible_tiktok}
                    is_visible_kwai={is_visible_kwai}
                    image={image}
                    type_dir_file={type_dir_file}
                    url_image={url_image}
                    url_video={url_video}
                    description={description}
                    tags={tags}
                    status={status}
                >


                    <FormSMActionSteps
                        getStepSelected={this.getStepSelected}
                        sendForm={this.sendForm}
                        sendImgUpload={this.sendImgUpload}
                        is_edit={is_edit}
                        activeTab={this.state.page}


                    />

                </FormSMSteps>)
        }
    }

    render() {

        return (
            <React.Fragment>
                {this.renderPage()}
            </React.Fragment>
        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    createSMProducts: PropTypes.func,
    findByIdSMProducts: PropTypes.func,
    uploadSMProducts: PropTypes.func,
    editSMProducts: PropTypes.func,

    id: PropTypes.any,
    category_gmerchant: PropTypes.any,
    category_metafacebook: PropTypes.any,
    product: PropTypes.any,
    company: PropTypes.any,
    ncm_gtin: PropTypes.any,
    brand: PropTypes.any,
    is_visible_meta: PropTypes.any,
    is_visible_gmerchant: PropTypes.any,
    is_visible_tiktok: PropTypes.any,
    is_visible_kwai: PropTypes.any,
    image: PropTypes.any,
    type_dir_file: PropTypes.any,
    url_image: PropTypes.any,
    url_video: PropTypes.any,
    description: PropTypes.any,
    tags: PropTypes.any,
    status: PropTypes.any,

}
const mapStateToProps = state => {

    const {
        id,
        category_gmerchant,
        category_metafacebook,
        product,
        company,
        ncm_gtin,
        brand,
        is_visible_meta,
        is_visible_gmerchant,
        is_visible_tiktok,
        is_visible_kwai,
        image,
        type_dir_file,
        url_image,
        url_video,
        description,
        tags,
        status,
    } = state.SMProducts
    return {
        id,
        category_gmerchant,
        category_metafacebook,
        product,
        company,
        ncm_gtin,
        brand,
        is_visible_meta,
        is_visible_gmerchant,
        is_visible_tiktok,
        is_visible_kwai,
        image,
        type_dir_file,
        url_image,
        url_video,
        description,
        tags,
        status,
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    findByIdSMProducts,
    createSMProducts,
    uploadSMProducts,
    editSMProducts
})(
    (Index)
)))
