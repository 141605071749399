export const UPDATE_STATUS_ROUTES = "UPDATE_STATUS_ROUTES"
export const UPDATE_STATUS_ROUTES_SUCCESS = "UPDATE_STATUS_ROUTES_SUCCESS"

export const PRINTER_ROUTES = "PRINTER_ROUTES"
export const PRINTER_ROUTES_SUCCESS = "PRINTER_ROUTES_SUCCESS"

export const FIND_ROUTES = "FIND_ROUTES"
export const FIND_ROUTES_SUCCESS = "FIND_ROUTES_SUCCESS"

export const FIND_ROUTES_BY_NUMBER = "FIND_ROUTES_BY_NUMBER"
export const FIND_ROUTES_BY_NUMBER_SUCCESS = "FIND_ROUTES_BY_NUMBER_SUCCESS"


export const ROUTES_API_ERROR = "FIND_OR_EDIT_ROUTES_API_ERROR"
