import React from 'react'
import {
    Badge,
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap"
import PropTypes from "prop-types"
import {Link, withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {withTranslation} from "react-i18next"
import {findRoutes, findRoutesNumber, updateStatusRoutes, printerRoutes} from "../../store/routes/actions"
import {findRouteIdChannelListener, findRouteCompanyIdChannelListener} from "../../store/channels/routes/actions"
import NumberFormat from "../../components/NumberFormat"
import {Spinner} from "react-bootstrap"
import toast from "react-hot-toast"
import SimpleMap from "./SimpleMap"
import CellPhoneFormat from "../../components/CellPhoneFormat";

//import 'mapbox-gl/dist/mapbox-gl.css';

import "../../assets/custom-theme_routes.css";


//const accessToken = 'pk.eyJ1IjoicGl6emFyaWEtZm9udGFuZWxsYSIsImEiOiJjbGNoNGV0dHMyNnVnM3FwNG1qOTY3amZnIn0.4bLcyoJmbqWlvA2py4wnOQ';
const rows_ids = []


class Index extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            points_label: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P'],
            route_id: 0,
            load_rows: false,
            reverse: true,
            load_chanel_ids: true,
            rows_ids: [],
            rows: [],
         
        }
        
        
        this._showingstatuslabel = this._showingstatuslabel.bind(this)
        this._showingpaymentlabel = this._showingpaymentlabel.bind(this)
        this._showingstatuspointlabel = this._showingstatuspointlabel.bind(this)
        this._idRouteSelect = this._idRouteSelect.bind(this)
    }

    componentDidMount() {
       this.props.findRouteCompanyIdChannelListener({company_id: 1})
       this.props.findRoutes({page: 1, limit: 100, company_id: 1})

    }

    
   _showingstatuslabel(value, index) {
        this.props.rows[index]['status'] = value
            switch (parseInt(value)) {
                case  0:
                    return (<span className="badge bg-danger p-1">Roteirizando</span>)
                case 1:
                    return (<span className="badge  bg-success p-1">Roteirizado</span>)
               default:
                    return (<span className="badge bg-danger p-1">Roteirizando</span>)
            }
    }
    _showingpaymentlabel(value, index) {
      
            switch (parseInt(value)) {
                case  0:
                    return (<span className="badge bg-success p-1">Pago não receber</span>)
                case 1:
                    return (<span className="badge  bg-danger p-1">Pendente, receber do cliente</span>)
               default:
                    return (<span className="badge bg-danger p-1">Roteirizando</span>)
            }
    }
    _showingstatuspointlabel(value, index) {
      
            switch (parseInt(value)) {
                case  0:
                    return (<span className="badge bg-warning p-1">Pendente em andamento</span>)
                case 1:
                    return (<span className="badge  bg-success p-1">Entregue ao cliente</span>)
                case 2:
                    return (<span className="badge  bg-danger p-1">Entrega cancelada</span>)    
               default:
                    return (<span className="badge bg-warning p-1">Pendente em andamento</span>)
            }
    }
     _idRouteSelect(item) {
        this.props.findRoutesNumber(item.id)
        
    }


    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Row>
                        <Col sm={this.props.geojson ? 2 : 3}>
                            <Card>
                                <CardBody> 
                                    <Row className="align-items-md-center  mb-3">
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <span className="h4 card-title">{this.props.t("Routes")}</span>
                                            </div>
                                            <div className="text-md-right ms-auto mb-2">
                                                <a onClick={() => {
                                                    this.props.findRoutes({page: 1, limit: 100, company_id: 1})
                                               
                                                }} className="btn btn-dark btn-sm text-light">
                                                    <i className="bx bx-sync"/>
                                                      {this.props.t("Update")}
                                                </a>
                                            </div>
                                        </Col>
                                        {
                                            this.props.rows.length > 0 &&
                                            this.props.rows.map((item, index) => {
                                                if(rows_ids.length === 0){
                                                    this.props.findRouteIdChannelListener({route_id: item.id})
                                                }
                                                if(rows_ids.indexOf(item.id) === -1){
                                                    this.props.findRouteIdChannelListener({route_id: item.id})
                                                    rows_ids.push(item.id)
                                                }

                                                return (
                                                        <Col key={index} sm={12} className="mb-1">
                                                            <a onClick={() => {
                                                                  this._idRouteSelect(item)
                                                                }}>
                                                               <div className=" border border-1 ">
                                                                  <Row className="p-2">
                                                                         <Col sm={12} className="text-right float-right ">
                                                                           <div className="circle_round_index text-center">     
                                                                               <small className="text-light">{this.state.points_label[item.group_index]}</small>     
                                                                           </div>
                                                                        </Col>
                                                                        <Col sm={12}>
                                                                            <span className="text-muted font-size-12">
                                                                                {item.qtd_points} pontos de entregas
                                                                            </span>
                                                                        </Col>
                                                                        <Col sm={12} className="text-end">
                                                                            {this._showingstatuslabel(item.status, index)}
                                                                        </Col>
                                                                        
                                                                  </Row>         
                                                               </div>
                                                            </a>
                                                        </Col>
                                                       )
                                            })
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={10}>
                        {
                         this.props.geojson &&
                   
                            <Row>
                               <Col sm={9}>
                                   <Card>
                                    <CardBody>
                                       
                                      {
                                         this.props.geojson &&
                                        <SimpleMap geojson={this.props.geojson}/>
                                        }
                                      </CardBody>
                                    </Card>    
                               </Col>
                               <Col sm={3}>
                                   <Card style={{ height: 440}}>
                                    <CardBody>
                                         <Row className="align-items-md-center  mb-3">
                                             <Col className="inner-custom-pagination d-flex mb-3">
                                                 <div className="d-inline">
                                                     <span className="h4 card-title">{this.props.t("Drivers")}</span>
                                                 </div>
                                             </Col>
                                             {
                                                this.props.drivers &&
                                                    this.props.drivers.length > 0 &&
                                                    this.props.drivers.map((item, index) => {
                                                        return (
                                                            <Col key={index} sm={12} className="mb-1">
                                                               <div className=" border border-1 ">
                                                                  <Row className="p-2">
                                                                         <Col sm={12} className="text-right float-right ">
                                                                           <span className="text-muted font-size-12">
                                                                                {item.first_name} {item.last_name}
                                                                            </span>
                                                                        </Col>
                                                                        <Col sm={12}>
                                                                            <span className="text-muted font-size-12">
                                                                                <CellPhoneFormat value={item.phone} />
                                                                            </span>
                                                                        </Col>
                                                                        
                                                                  </Row>         
                                                               </div>
                                                        </Col>
                                                        )
                                                    })
                                                        
                                             }

                                         </Row>
                                      </CardBody>
                                    </Card>    
                               </Col>
                               <Col sm={12}>
                                   <Card>
                                        <CardBody>
                                              <Row className="align-items-md-center  mb-3">
                                                 <Col className="inner-custom-pagination d-flex">
                                                     <div className="d-inline">
                                                         <span className="h4 card-title">{this.props.t("Points")}</span>
                                                     </div>
                                                 </Col>
                                                {
                                                    this.props.loading_detail
                                                    ?
                                                    <Col sm={9}>
                                                        <Card>
                                                            <CardBody>
                                                                <div className="text-center"
                                                                     style={{width: '100%', position: 'relative', paddingBottom: 20}}>
                                                                    <Spinner size="sm" animation="border"
                                                                             style={{marginTop: '10%'}}
                                                                             variant="dark"/>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>

                                                    :
                                                    this.props.points &&
                                                    this.props.points.length > 0 &&
                                                    this.props.points.map((item, index) => {
                                                        return (
                                                            <Col key={index} sm={12} className="mb-1">
                                                               <div className=" border border-1 ">
                                                                  <Row className="p-2">
                                                                         <Col sm={12} className="text-right float-right ">
                                                                            <div className="d-flex flex-row">
                                                                                <div className="p-2">
                                                                                   <div className="circle_round_index text-center mt-2">     
                                                                                       <small className="text-light">{this.state.points_label[item.index_point]}</small>     
                                                                                   </div>
                                                                                </div>
                                                                                <div className="p-2">
                                                                                   <p className="text-start text-muted font-size-12"> {item.address_complete} </p>
                                                                                   <p className="text-start text-muted font-size-12"> {item.instruction} </p>
                                                                                   <div className="d-flex flex-row">
                                                                                        <div className="p-2">{this._showingpaymentlabel(item.status, index)} </div>
                                                                                        <div className="p-2">{this._showingstatuspointlabel(item.status, index)} </div>
                                                                                   </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                       
                                                                        
                                                                  </Row>         
                                                               </div>
                                                        </Col>
                                                        )
                                                     })
                                                }    
                                             </Row>
                                        </CardBody>
                                    </Card>    
                               </Col>
                            </Row>
                        
                        }
                       </Col>
                    </Row>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
    rows: PropTypes.any,
    findRoutes: PropTypes.func,
    findRoutesNumber: PropTypes.func,

    loading: PropTypes.any,
    loading_detail: PropTypes.any,
    current_page: PropTypes.number,
    last_page: PropTypes.number,
    total: PropTypes.number,
    from: PropTypes.number,
    to: PropTypes.number,

    drivers: PropTypes.any,
    points: PropTypes.any,
    listener_update_route: PropTypes.any,
    geojson: PropTypes.any,
    route: PropTypes.any,
    id: PropTypes.any,
    route_id: PropTypes.any,
    route_created_at: PropTypes.any,
    qtd_points: PropTypes.any,
    time_route: PropTypes.any,
    status: PropTypes.any,
    group_index: PropTypes.any,
    updateStatusRoutes: PropTypes.func,
    findRouteIdChannelListener: PropTypes.func,
    findRouteCompanyIdChannelListener: PropTypes.func,
    printerRoutes: PropTypes.func


}
const mapStateToProps = state => {
    const {route, listener_update_route} = state.RoutesChannelListeners
    const {
        loading,
        rows,
        current_page,
        last_page,
        total,
        from,
        to,
        loading_detail,
        id,
        route_created_at,
        qtd_points,
        time_route,
        status,
        group_index,
        points,
        geojson,
        drivers
    } = state.Routes
    console.log(geojson)

   // var routes = null
    if (route !== null) {
        rows.map((item, index) => {

            if (item.id !== route.id) {

                if(rows_ids.indexOf(route.id) === -1){
                   // routes = route
                    rows.push(route)
                    rows_ids.push(route.id)
                    rows.reverse()
                }

                //rows.push(route)

            } else {
                rows[index] = route
              //  routes = null

            }
            console.log('item.id ', item.id + ' '+item.status)
            console.log('route.id ', route.id + ' '+route.status)
        })
    }


  /* if (routes !== null) {
       console.log('adicionou 1 ', route.id + ' '+route.status)
       //rows.push(routes)
        routes = null
    }*/


    if (rows.length === 0) {
        if (route !== null) {
         //   console.log('adicionou 2 ', route.id + ' '+route.status)
            rows.push(route)
            rows_ids.push(route.id)
            //rows.reverse()
           // routes = null
        }
    }


    return {
        listener_update_route,
        loading,
        loading_detail,
        rows,
        points,
        route,
        geojson,
        drivers,
        current_page,
        last_page,
        total,
        from,
        to,
        id,
        qtd_points,
        time_route,
        route_created_at,
        status,
        group_index
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
        findRouteIdChannelListener,
        findRouteCompanyIdChannelListener,
        findRoutes,
        findRoutesNumber,
        updateStatusRoutes,
        printerRoutes
    })(Index)
))
