import {
    FIND_PROMOTIONS_BY_CAMPAIGN,
    FIND_PROMOTIONS_BY_CAMPAIGN_SUCCESS,
    FIND_PROMOTION_BY_ID,
    FIND_PROMOTION_BY_ID_SUCCESS,
    CREATE_AND_ADD_CAMPAIGN_PROMOTION,
    CREATE_AND_ADD_CAMPAIGN_PROMOTION_SUCCESS,
    REMOVE_CAMPAIGN_PROMOTION,
    REMOVE_CAMPAIGN_SUCCESS,
    ADD_CAMPAIGN_PROMOTION,
    ADD_CAMPAIGN_PROMOTION_SUCCESS,
    FIND_PROMOTIONS_BY_CAMPAIGN_API_ERROR
} from "./actionTypes"


export const createPromotionByCampaign = params => {
    return {
        type: CREATE_AND_ADD_CAMPAIGN_PROMOTION,
        payload: { params },
    }
}

export const createPromotionByCampaignSuccess = params => {
    return {
        type: CREATE_AND_ADD_CAMPAIGN_PROMOTION_SUCCESS,
        payload: params,
    }
}

export const addCampaignPromotion = params => {
    return {
        type: ADD_CAMPAIGN_PROMOTION,
        payload: { params },
    }
}

export const addCampaignPromotionSuccess = params => {
    return {
        type: ADD_CAMPAIGN_PROMOTION_SUCCESS,
        payload: { params }
    }
}

export const removeCampaignPromotion = params => {
    return {
        type: REMOVE_CAMPAIGN_PROMOTION,
        payload: { params },
    }
}

export const removeCampaignPromotionSuccess = params => {
    return {
        type: REMOVE_CAMPAIGN_SUCCESS,
        payload: { params }
    }
}

export const findPromotionByCampaign = params => {
    return {
        type: FIND_PROMOTIONS_BY_CAMPAIGN,
        payload: { params },
    }
}

export const findPromotionByCampaignSuccess = params => {
    return {
        type: FIND_PROMOTIONS_BY_CAMPAIGN_SUCCESS,
        payload: params
    }
}

export const findPromotionCampaignById = params => {
    return {
        type: FIND_PROMOTION_BY_ID,
        payload: { params },
    }
}

export const findPromotionCampaignByIdSuccess = params => {
    return {
        type: FIND_PROMOTION_BY_ID_SUCCESS,
        payload: params
    }
}

export const apiFindPromotionsCampaignError = error => {
    return {
        type: FIND_PROMOTIONS_BY_CAMPAIGN_API_ERROR,
        payload: { error },
    }
}

