import React, { Children } from 'react'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import PropTypes from "prop-types";
import { MetaTags } from 'react-meta-tags'
import Form_page_01 from '../form_01/index'
import Form_page_02 from '../form_02/index'


class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}

        this.render_step_page = this.render_step_page.bind(this)
    }


    render_step_page() {
        const {
            page, getFieldsForm, handleFileImgDropUpdate, promotion,
            product, is_visible_meta, is_visible_gmerchant, is_visible_tiktok,
            is_visible_kwai, url_video, description,
            tags, url_image, type_dir_file,
            image, id, is_edit
        } = this.props

        switch (parseInt(page)) {

            case 0:
                return (<Form_page_01
                    promotion={promotion}
                    product={product}
                    id={id}
                    is_edit={is_edit}
                    is_visible_meta={is_visible_meta}
                    is_visible_gmerchant={is_visible_gmerchant}
                    is_visible_tiktok={is_visible_tiktok}
                    is_visible_kwai={is_visible_kwai}
                    url_video={url_video}
                    description={description}
                    tags={tags}
                    getFieldsForm={getFieldsForm} />)
            case 1:
                return (<Form_page_02
                    id={id}
                    is_edit={is_edit}
                    url_image={url_image}
                    type_dir_file={type_dir_file}
                    image={image}
                    name_product={product}
                    handleFileImgDropUpdate={handleFileImgDropUpdate}
                    getFieldsForm={getFieldsForm} />)
        }

    }


    render() {
        const { children, title, activeTab, passedSteps, getStepSelected } = this.props

        return (
            <React.Fragment>
                <MetaTags>
                    <title>{title}</title>
                </MetaTags>
                <CardTitle>{title}</CardTitle>
                <div className="wizard clearfix">

                    <div className="steps clearfix">
                        <ul>
                            <NavItem
                                className={classnames({ current: activeTab === 0 })}
                            >
                                <NavLink
                                    className={classnames({ current: activeTab === 0 })}
                                    onClick={() => {
                                        getStepSelected(0)
                                    }}
                                    disabled={!(passedSteps || []).includes(0)}
                                >
                                    <span className="number">1.</span> Configurar Promoção
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({ current: activeTab === 1 })}
                            >
                                <NavLink
                                    className={classnames({ active: activeTab === 1 })}
                                    onClick={() => {
                                        getStepSelected(1)
                                    }}
                                    disabled={!(passedSteps || []).includes(1)}
                                >
                                    <span className="number">2.</span> Imagens Promoção
                                </NavLink>
                            </NavItem>
                        </ul>
                    </div>

                    <div className="content clearfix">
                        {this.render_step_page()}

                    </div>
                    {children}

                </div>

            </React.Fragment>
        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    title: PropTypes.any,
    page: PropTypes.any,
    activeTab: PropTypes.any,
    passedSteps: PropTypes.any,
    getStepSelected: PropTypes.func,
    children: PropTypes.any,
    getFieldsForm: PropTypes.func,
    handleFileImgDropUpdate: PropTypes.func,
    is_edit: PropTypes.any,

    id: PropTypes.any,
    promotion: PropTypes.any,
    product: PropTypes.any,
    company: PropTypes.any,
    is_visible_meta: PropTypes.any,
    is_visible_gmerchant: PropTypes.any,
    is_visible_tiktok: PropTypes.any,
    is_visible_kwai: PropTypes.any,
    image: PropTypes.any,
    type_dir_file: PropTypes.any,
    url_image: PropTypes.any,
    url_video: PropTypes.any,
    description: PropTypes.any,
    tags: PropTypes.any,
    status: PropTypes.any,
}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
