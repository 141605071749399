import toast from "react-hot-toast"
import {beep_play} from "../../components/BeepAlert"

/***
 * canal responsável por ouvir atualizações das rotas de entrega por id.
 *
 * @param route_id
 * @returns {Promise<unknown>}
 */
const findRouteIdChannelListener = ({route_id}) => new Promise((resolve, reject) => {
    window.Echo.channel('chan-route-'.concat(route_id))
        .listen('.route.updated', (e) => {
            console.log(e)
            toast.success('Uma nova rota foi atualizada, verifique!')
            beep_play()
            resolve({e})
        })
        .error(error => {
            console.log('error ', error)
            toast.error('Ocorreu um erro no canal de rota por ID')
            reject({error})
        })
})
const findRouteCompanyIdChannelListener = ({company_id}) => new Promise((resolve, reject) => {
    window.Echo.channel('chan-routes-company-'.concat(company_id))
        .listen('.routes.list.by.company', (e) => {
            toast.success('Surgiu uma nova rota.')
            beep_play()

            resolve({e})
        })
        .error(error => {
            console.log('error ', error)
            toast.error('Ocorreu um erro no canal de rotas por empresa')
            reject({error})
        })

})

export {
    findRouteIdChannelListener,
    findRouteCompanyIdChannelListener
}
