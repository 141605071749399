import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const findSMPromotionsAPI = data => get(url.GET_SOCIAL_MIDIA_PROMOTION)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const getSMPromotionsPaginate = ({ company_id, page, limit }) => get(url.GET_SOCIAL_MIDIA_PROMOTION_PAGINATE, { params: { company_id, page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const createSMPromotionsAPI = data => post(url.POST_CREATE_SOCIAL_MIDIA_PROMOTION, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSMPromotionsById = data => get(url.GET_SOCIAL_MIDIA_PROMOTION_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postUploadSMPromotions = data => post(url.POST_UPLOAD_SOCIAL_MIDIA_PROMOTION, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const putEditSMPromotions = data => put(url.PUT_EDIT_SOCIAL_MIDIA_PROMOTION, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })


export {
    findSMPromotionsAPI,
    getSMPromotionsPaginate,
    createSMPromotionsAPI,
    getSMPromotionsById,
    postUploadSMPromotions,
    putEditSMPromotions
}

