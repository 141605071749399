import React from 'react'
import { CardBody } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Card, Col, Row, Stack } from "react-bootstrap"
import { findPromotionByCompanies} from "../../store/promotions/actions"
import BreadcrumbsCustom from 'components/BreadcrumbCustom'
import UIBodyPromotionProducts from './Products/Body/Index'

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company_id: 1,
            promotion_selected: null
        }
        this.renderPage = this.renderPage.bind(this)
    }



    componentDidMount() {

        this.props.findPromotionByCompanies({
            page: 1, limit: 10, company_id: 1
        });
    }

    renderPage(promotion) {
        switch (promotion.type) {
            case 1:
                return (<UIBodyPromotionProducts
                    promotion_id={promotion.id}
                    type={promotion.type}
                    company_id={this.state.company_id} />);
        }
    }

    render() {
        const { promotion_selected } = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <BreadcrumbsCustom title='' breadcrumbItem='Promoções' />
                    <Row>
                        <Col sm={3}>
                            <Row>
                                {
                                    this.props.rows && this.props.rows.length > 0 &&
                                    this.props.rows.map((item, index) => {
                                        return (
                                            <Col key={index} className="col-12">
                                                <Card>
                                                    <CardBody>
                                                        <span
                                                            className="h4 card-title">{item.name}</span>
                                                        <p className="card-title-desc mt-2">
                                                            {item.description}
                                                        </p>
                                                        <a
                                                            onClick={() => {
                                                                this.setState({ promotion_selected: item })
                                                            }}
                                                            className="btn btn-dark btn-sm w-100 text-light"
                                                        >
                                                            <span> Conferir</span>
                                                        </a>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }

                            </Row>
                        </Col>
                        <Col sm={9}>
                            <Card>
                                <CardBody>
                                    {
                                        promotion_selected &&
                                        this.renderPage(promotion_selected)
                                    }

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,

    findPromotionByCompanies: PropTypes.func,
    rows: PropTypes.any,

}
const mapStateToProps = state => {

    const { loading, rows } = state.Promotions

    return {
        loading,
        rows
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    findPromotionByCompanies
})(Index)
))
// 