import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    GET_DAYS_WEEK_BY_COMPANIES,
} from "./actionTypes"


import {
    getDaysWeekByCompaniesSuccess,
    apiDaysWeekByCompaniesError
} from "./actions"


import {
    getDaysWeekByCompaniesAPI,

} from "../../helpers/companies_helper"

function* getDaysWeekByCompanies({ payload: { params } }) {
    try {

        const response = yield call(getDaysWeekByCompaniesAPI, params)

        yield put(getDaysWeekByCompaniesSuccess(response))

    } catch (error) {
        yield put(apiDaysWeekByCompaniesError(error))
    }
}


function* companiesSaga() {
    yield takeEvery(GET_DAYS_WEEK_BY_COMPANIES, getDaysWeekByCompanies)
}

export default companiesSaga
