export const UPDATE_STATUS_ORDERS = "UPDATE_STATUS_ORDERS"
export const UPDATE_STATUS_ORDERS_SUCCESS = "UPDATE_STATUS_ORDERS_SUCCESS"

export const PRINTER_ORDERS = "PRINTER_ORDERS"
export const PRINTER_ORDERS_SUCCESS = "PRINTER_ORDERS_SUCCESS"

export const FIND_ORDERS = "FIND_ORDERS"
export const FIND_ORDERS_SUCCESS = "FIND_ORDERS_SUCCESS"

export const FIND_ORDERS_BY_NUMBER = "FIND_ORDERS_BY_NUMBER"
export const FIND_ORDERS_BY_NUMBER_SUCCESS = "FIND_ORDERS_BY_NUMBER_SUCCESS"


export const ORDERS_API_ERROR = "FIND_OR_EDIT_ORDERS_API_ERROR"
