import React, { Component } from "react"
import {Card, CardBody, CardText, CardTitle, Col, Form, Label, Row} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {AvField, AvForm, AvInput} from "availity-reactstrap-validation"
import PerfectScrollbar from "react-perfect-scrollbar";


class UIResponsiblePickupDelivery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            veritas: false,
            validated: true,
            name: '',
            email: '',
            phone: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleFields = this.handleFields.bind(this);

    }

    componentDidMount() {
        if(this.props.data){
            const {name, email, phone, veritas} = this.props.data;
            this.setState({name, email, phone, veritas})
        }

    }

    handleChange() {
        this.setState({veritas: !this.state.veritas})
    }
    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    render() {

        return (
            <Col sm={12}>
                <PerfectScrollbar style={{height: "640px"}}>
                    <AvForm
                        noValidate
                        onValidSubmit={this.props.funcParamResponsible}>
                        <Row>
                            <Col sm={12}>
                                <div className="text-center  mb-4">
                                    <h4 className="card-title">Preencha com os dados do responsável, pelo pedido</h4>
                                    <small>em Pizzaria Fontanella</small>
                                </div>
                            </Col>
                            {/*<Col sm={12}>
                                <div className="text-start mb-3">
                                    <a onClick={this.props.previousPage}>
                                       <b>
                                           <h4 className="card-title font-bl-12-400h text-danger">
                                               <i className="mdi mdi-arrow-left mdi-16px"/> Voltar
                                           </h4>
                                       </b>
                                    </a>
                                </div>
                            </Col>*/}
                            <Col sm={12}>
                                <p className="card-text font-bl-12-400h">É muito importante que todas informações preenchidas, são verdadeiras, para segurança durante a entrega de sua encomenda.</p>
                            </Col>
                            <Col sm={12} className="mt-3">
                                <AvField
                                    name="name"
                                    value={this.state.name}
                                    label={this.props.t("Name")}
                                    className="form-control"
                                    placeholder="Digite nome"
                                    type="text"
                                    required
                                />
                                {/*<div className="form-group  mt-3">

                                    <label htmlFor="" className="control-label text-muted">Nome</label>
                                    <input className="form-control"
                                           id="name"
                                           onChange={this.handleFields}
                                           value={this.state.name}/>
                                    <div className="invalid-feedback">
                                        Please provide a valid zip.
                                    </div>

                                </div>*/}
                            </Col>
                           {/* <Col sm={12} className="mt-2">
                                <AvField
                                    name="email"
                                    value={this.state.email}
                                    label={this.props.t("E-mail")}
                                    className="form-control"
                                    placeholder="Digite o email"
                                    type="text"
                                    required
                                />
                                
                            </Col>*/}
                            <Col sm={12}>
                                <AvField
                                    name="phone"
                                    value={this.state.phone}
                                    label={this.props.t("Phone")}
                                    className="form-control"
                                    placeholder="Digite o Nº Celular"
                                    type="text"
                                    required
                                />
                                {/*<div className="form-group mt-2">
                                    <label htmlFor="" className="control-label text-muted">Celular <small>Preferencial com WHATSAPP</small></label>
                                    <input className="form-control"
                                           id="phone"
                                           onChange={this.handleFields}
                                           value={this.state.phone}/>

                                </div>*/}
                            </Col>
                            <Col sm={12}>
                                <div className="form-check mt-2">
                                    <AvInput
                                        name="veritas"
                                        type="checkbox"
                                        required/>
                                    <label className="form-check-label font-bl-12-400h" htmlFor="veritas">
                                        Juro que as informações são verdadeiras.
                                    </label>
                                </div>
                                {/*<div className="form-check mt-4">
                                    <input className="form-check-input"
                                           type="checkbox"
                                           checked={this.state.veritas}
                                           onClick={this.handleChange}
                                           id="veritas" />
                                    <label className="form-check-label font-bl-12-400h" htmlFor="veritas">
                                        Juro que as informações são verdadeiras.
                                    </label>
                                </div>*/}
                            </Col>
                            <Col sm={12} className="d-grid gap-2 mt-1">
                                <button type="submit" className="btn btn-sm btn-block btn-dark mt-1"
                               >
                                    <h4 className="font-bl-13-700h text-center pt-1 text-light">Continuar</h4>
                                </button>
                            </Col>

                        </Row>
                    </AvForm>
                </PerfectScrollbar>
            </Col>
        )
    }
}

UIResponsiblePickupDelivery.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    getParamsModal: PropTypes.func,
    rows: PropTypes.array,
    funcParamResponsible: PropTypes.func,
    previousPage: PropTypes.func,
    data: PropTypes.object



}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (UIResponsiblePickupDelivery)
)))
