import {takeEvery, put, call} from "redux-saga/effects"

import {
    LISTENER_WHATSAPP_CHANNEL_BY_ID_COMPANY
} from "./actionTypes"

import {
    findWhatsAppIdChannelListenerSuccess,
    channelCreateOrEditWhatsAppError
} from "./actions"


import {
    findWhatsappIdChannelListener
} from "../../../helpers/channels/whatsapp_listeners"

function* findWhatsAppIdChannel({payload: {params}}) {
    try {

        const {response} = yield call(findWhatsappIdChannelListener, params)
        console.log(response)
        yield put(findWhatsAppIdChannelListenerSuccess({
            sniper_shot: {
                type: response.type,
                message: response.message,
                qrcode: response.qrcode

            }
        }))


    } catch (error) {
        yield put(channelCreateOrEditWhatsAppError(error))
    }
}



function* createOrEditOrdersChannelSaga() {
    yield takeEvery(LISTENER_WHATSAPP_CHANNEL_BY_ID_COMPANY, findWhatsAppIdChannel)
}

export default createOrEditOrdersChannelSaga
