import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const findHomeQueryCompanyAPI = ({value, id}) => get(url.GET_COMPANIES_BY_QUERY, { params: { query: value, company_id: id } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const findHomeHashCompanyAPI = hash => get(url.GET_COMPANIES_BY_HASH, { params: { company_id: hash } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const findProductsGroupByCategory = data => get(url.GET_PRODUCTS_GROUP_BY_CATEGORY, { params: { company_id: data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const findProductsByCategoryAndSizeID = data => get(url.GET_PRODUCTS_GROUP_BY_CATEGORY.concat('/').concat(data.size_id).concat('/').concat(data.category_id))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    findHomeQueryCompanyAPI,
    findHomeHashCompanyAPI,
    findProductsGroupByCategory ,
    findProductsByCategoryAndSizeID
}
