import {
    FIND_ORDERS,
    FIND_ORDERS_SUCCESS,
    FIND_ORDERS_BY_NUMBER,
    FIND_ORDERS_BY_NUMBER_SUCCESS,
    UPDATE_STATUS_ORDERS,
    UPDATE_STATUS_ORDERS_SUCCESS,
    PRINTER_ORDERS,
    PRINTER_ORDERS_SUCCESS,
    ORDERS_API_ERROR
} from "./actionTypes"

export const printerOrders = params => {

    return {
        type: PRINTER_ORDERS,
        payload: {params},
    }
}

export const printerOrdersSuccess = params => {

    return {
        type: PRINTER_ORDERS_SUCCESS,
        payload: params,
    }
}

export const findOrders = params => {

    return {
        type: FIND_ORDERS,
        payload: {params},
    }
}

export const findOrdersSuccess = params => {

    return {
        type: FIND_ORDERS_SUCCESS,
        payload: params,
    }
}

export const findOrdersNumber = params => {
    return {
        type: FIND_ORDERS_BY_NUMBER,
        payload: {params},
    }
}

export const findOrdersNumberSuccess = params => {
    return {
        type: FIND_ORDERS_BY_NUMBER_SUCCESS,
        payload: params,
    }
}

export const updateStatusOrders = params => {
    return {
        type: UPDATE_STATUS_ORDERS,
        payload: {params},
    }
}

export const updateStatusOrdersSuccess = params => {
    return {
        type: UPDATE_STATUS_ORDERS_SUCCESS,
        payload: params,
    }
}

export const apiCreateOrEditOrderError = error => {
    return {
        type: ORDERS_API_ERROR,
        payload: error,
    }
}

