import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

// Login Redux States
import {
    GEO_REVERSE_COORDINATES,
    SEARCH_ADDRESS,
} from "./actionTypes"

import {
    reverseCoordinatesSuccess,
    searchAddressSuccess,
    apiLocationError
} from "./actions"

import {
    getReverseCoordinates,
    getSearchAddress,
} from "../../../helpers/location_helper"

function* reverseCoordinates({payload: {params}}) {
    try {

        const {results} = yield call(getReverseCoordinates, params)
        const {formatted_address, geometry} = results;
        const {location} = geometry;

        yield put(reverseCoordinatesSuccess({
            address: formatted_address,
            coordinates: location
        }))


    } catch (error) {
        yield put(apiLocationError(error))
    }
}

function* searchAddress({payload: {params}}) {
    try {

        const {results} = yield call(getSearchAddress, params)

        const {formatted_address, geometry} = results[0];
        const {location} = geometry;

        yield put(searchAddressSuccess({
            address: formatted_address,
            coordinates: location
        }))

    } catch (error) {
        yield put(apiLocationError(error))
    }
}


function* locationSaga() {
    yield takeEvery(GEO_REVERSE_COORDINATES, reverseCoordinates)
    yield takeEvery(SEARCH_ADDRESS, searchAddress)
}

export default locationSaga
