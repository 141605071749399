import React, {Component} from "react"
import {Card, CardBody, Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import UICardViewProductCart from "./UICardViewProductCart"


class UICardViewBuyCartOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {}

    }

    render() {

        return (
            <div key={this.props.index}>
                <Col sm={12}>
                    <p className="card-title display-4">
                        <small>{/*`${this.props.qtd}x `*/}{ this.props.groupName}</small>
                    </p>
                    <hr/>
                </Col>
                {
                    this.props.rows.map((item, index) => {
                        return (<UICardViewProductCart
                            name={item.name}
                            description={item.description}
                            price={item.price}
                            indexGroup={this.props.index}
                            index={index}
                            type={this.props.type}
                            edges={parseInt(this.props.type) === 1 ? item.edge : []}
                            pastas={parseInt(this.props.type) === 1 ? item.pasta : []}
                            complements={item.complements}
                            key={index}
                            remove={this.props.handleRemoveProd}
                        />)
                    })
                }
            </div>
        )
    }
}

UICardViewBuyCartOrder.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    index: PropTypes.any,
    qtd: PropTypes.any,
    indexGroup: PropTypes.any,
    rows: PropTypes.array,
    groupName: PropTypes.any,
    type: PropTypes.any,
    handleRemoveProd: PropTypes.func


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {})(
    (UICardViewBuyCartOrder)
)))
