import {
    FIND_PAYMENT_METHODS,
    FIND_PAYMENT_METHODS_SUCCESS,
    FIND_PAYMENT_METHODS_API_ERROR,
} from "./actionTypes"


export const findPaymentMethods = query => {
    return {
        type: FIND_PAYMENT_METHODS,
        payload: {query},
    }
}

export const findPaymentMethodsSuccess = data => {
    return {
        type: FIND_PAYMENT_METHODS_SUCCESS,
        payload: {data}
    }
}

export const apiFindPaymentMethodsError = error => {
    return {
        type: FIND_PAYMENT_METHODS_API_ERROR,
        payload: {error},
    }
}

