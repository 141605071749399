import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const findDiscountCouponsAPI = data => get(url.GET_COUPONS_DISCOUNTS)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getCoupomByTypeAPI = data => get(url.GET_COUPONS_DISCOUNTS_BY_TYPE.concat("/").concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })


export {
    findDiscountCouponsAPI,
    getCoupomByTypeAPI
}
