import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    FIND_PAYMENT_METHODS,
} from "./actionTypes"


import {
    findPaymentMethodsSuccess,
    apiFindPaymentMethodsError
} from "./actions"


import {
    findPaymentMethodsAPI
} from "../../../helpers/payment_method_helper"

function* findPaymentMethods() {
    try {

        const response = yield call(findPaymentMethodsAPI)
        const {data} = response;

        console.log('SAGA ',data)
        yield put(findPaymentMethodsSuccess(data))

    } catch (error) {
        yield put(apiFindPaymentMethodsError(error))
    }
}


function* CatalogPaymentMethodsSaga() {
    yield takeEvery(FIND_PAYMENT_METHODS, findPaymentMethods)
}

export default CatalogPaymentMethodsSaga
