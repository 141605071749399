import React from 'react'
import {Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap"
import PropTypes from "prop-types"
import {withRouter} from "react-router-dom"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import NumberFormat from "../../components/NumberFormat"

class UICardListProductType_1 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}

    }

    componentDidMount() {

    }

    render() {

        return (
                <React.Fragment>
                    
                       <Col sm={6}>
                             <h6 className="text-truncate font-size-10 text-muted font-weight-semibold text-start">  
                             {this.props.product_qtd} x {this.props.product_name} <span>{this.props.prod_type === 1 ? this.props.size_name + ' (' + this.props.qtd_pieces + ' PEDAÇOS ) ' : ''}</span>
                             </h6>
                             <p className="px-1  font-size-10 text-muted  text-start ">  
                                 {this.props.product_description}
                             </p>
                                 {
                                    this.props.prod_type === 1 &&
                                         <>
                                             <h6 className="text-truncate font-size-10 text-muted font-weight-semibold text-start"> Borda {this.props.edge_name}</h6>
                                             <h6 className="text-truncate font-size-10 text-muted font-weight-semibold text-start"> Massa {this.props.pasta_name}</h6>
                                         </>
                                 }
                         </Col>
                         <Col sm={6}>
                                 <h6 className="text-truncate font-size-10 text-muted text-end">
                                         <NumberFormat value={this.props.product_price}/>
                                 </h6>
                                     <p></p>
                                 {
                                    this.props.prod_type === 1 &&
                                         <>
                                             <h6 className="text-truncate font-size-10 text-muted text-end">
                                                 <NumberFormat value={this.props.edge_price}/>
                                             </h6>
                                             <h6 className="text-truncate font-size-10 text-muted text-end">
                                                 <NumberFormat value={this.props.pasta_price}/>
                                             </h6>
                                         </>
                                 }

                         </Col>
                </React.Fragment>
            
        )
        
    }
}

UICardListProductType_1.propTypes = {
    
    product_name: PropTypes.any,
    product_qtd: PropTypes.any,
    prod_type: PropTypes.number,
    size_name: PropTypes.any,
    qtd_pieces: PropTypes.any,
    edge_name: PropTypes.any,
    pasta_name: PropTypes.any,
    product_price: PropTypes.any,
    edge_price: PropTypes.any,
    pasta_price: PropTypes.any,
    product_description: PropTypes.any,

}
export default withRouter(withTranslation()(UICardListProductType_1))

