import {
    FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE,
    FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE_SUCCESS,
    FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE_API_ERROR,
} from "./actionTypes"


export const findProductsByCategoryAndSize = query => {
    return {
        type: FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE,
        payload: {query},
    }
}

export const findProductsByCategoryAndSizeSuccess = data => {
    return {
        type: FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE_SUCCESS,
        payload: {data},
    }
}

export const apiFindProductsByCategoryAndSizeError = error => {
    return {
        type: FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE_API_ERROR,
        payload: {error},
    }
}

