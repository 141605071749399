import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const findPromotionByCompaniesAPI = ({ page, limit, company_id }) => get(url.GET_PROMOTIONS_BY_COMPANIES, { params: { page, limit, company_id } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const findPromotionByIdAPI = ({ id }) => get(url.GET_PROMOTION_BY_ID.concat('/').concat(id))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const createPromotionByCompaniesAPI = data => post(data.type == 1 ?url.POST_CREATE_PROMOTION_PRODUCTS : url.POST_CREATE_PROMOTION, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })
const addProductPromotionAPI = data => post(url.POST_ADD_PRODUCT_PROMOTION, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })
const removeProductPromotionAPI = data => del(url.DELETE_PRODUCT_PROMOTION, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })

export {
    findPromotionByCompaniesAPI,
    findPromotionByIdAPI,
    createPromotionByCompaniesAPI,
    addProductPromotionAPI,
    removeProductPromotionAPI
}
