import React, { Component } from "react"
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player"
import ld_tracking_delivery_01  from '../assets/anim/ld_tracking_delivery_01.json';
import ld_tracking_delivery_02  from '../assets/anim/ld_tracking_delivery_02.json';
import ld_qrcode  from '../assets/anim/ld_qrcode.json';
import ld_pulse  from '../assets/anim/ld_pulse_tracking.json';
import ld_payment_methods  from '../assets/anim/ld_payment_methods.json';
import ld_order_success  from '../assets/anim/ld_order_sucess.json';
import ld_dots  from '../assets/anim/ld_dots.json';
import ld_coupom_discount  from '../assets/anim/ld_coupom_discount.json';
import ld_compositor_chief_pizza  from '../assets/anim/ld_compositor_chief_pizza.json';


class UILoadingAnim extends Component {
    constructor(props) {
        super(props)
        this.state = {
            anim_list: [
                {
                    id: 1,
                    file: ld_tracking_delivery_01
                },
                {
                    id: 2,
                    file: ld_tracking_delivery_02
                },
                {
                    id: 3,
                    file: ld_qrcode
                },
                {
                    id: 4,
                    file: ld_pulse
                },
                {
                    id: 5,
                    file: ld_payment_methods
                },
                {
                    id: 6,
                    file: ld_order_success
                },
                {
                    id: 7,
                    file: ld_coupom_discount
                },
                {
                    id: 8,
                    file: ld_compositor_chief_pizza
                },
                {
                    id: 9,
                    file: ld_dots
                },
            ]
        }

    }

    render() {

        return (
            <div className="text-center" style={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                right: 0,
                left: 0,
                transform: 'translate(0, -50%)',

            }}>
                <Player
                    src={this.state.anim_list[this.props.url_anim].file}
                    className="player"
                    loop
                    autoplay
                />
            </div>
        )
    }
}

UILoadingAnim.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    url_anim: PropTypes.any,


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {})(
    (UILoadingAnim)
)))
