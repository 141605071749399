import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    FIND_PROMOTIONS_BY_COMPANIES,
    FIND_PROMOTION_BY_ID,
    CREATE_AND_ADD_PRODUCT_PROMOTION,
    REMOVE_PRODUCT_PROMOTION,
    ADD_PRODUCT_PROMOTION,
} from "./actionTypes"


import {
    createPromotionByCompaniesSuccess,
    addProductPromotionSuccess,
    removeProductPromotionSuccess,
    findPromotionByCompaniesSuccess,
    findPromotionByIdSuccess,
    apiFindPromotionsCompaniesError
} from "./actions"


import {
    findPromotionByCompaniesAPI,
    createPromotionByCompaniesAPI,
    addProductPromotionAPI,
    removeProductPromotionAPI,
    findPromotionByIdAPI

} from "../../helpers/promotions_company_helper"

function* findPromotionById({ payload: { params } }) {
    try {

        const response = yield call(findPromotionByIdAPI, params)
        const { data } = response;

        yield put(findPromotionByIdSuccess(data))

    } catch (error) {
        yield put(apiFindPromotionsCompaniesError(error))
    }
}

function* removeProductPromotion({ payload: { params } }) {
    try {

        const response = yield call(removeProductPromotionAPI, params)
        const { data } = response;

        yield put(removeProductPromotionSuccess(data))

    } catch (error) {
        yield put(apiFindPromotionsCompaniesError(error))
    }
}

function* addProductPromotion({ payload: { params } }) {
    try {

        const response = yield call(addProductPromotionAPI, params)
        const { data } = response;

        yield put(addProductPromotionSuccess(data))

    } catch (error) {
        yield put(apiFindPromotionsCompaniesError(error))
    }
}

function* createPromotionByCompanies({ payload: { params } }) {
    try {

        const response = yield call(createPromotionByCompaniesAPI, params)
        const { message, error } = response;

        yield put(createPromotionByCompaniesSuccess(response))
        NotificationManager.info(message, 'Aviso');
    } catch (error) {
        yield put(apiFindPromotionsCompaniesError(error))
    }
}

function* findPromotionByCompanies({ payload: { params } }) {
    try {

        const response = yield call(findPromotionByCompaniesAPI, params)


        yield put(findPromotionByCompaniesSuccess(response))

    } catch (error) {
        yield put(apiFindPromotionsCompaniesError(error))
    }
}


function* PromotionsCompaniesSaga() {
    yield takeEvery(FIND_PROMOTIONS_BY_COMPANIES, findPromotionByCompanies)
    yield takeEvery(CREATE_AND_ADD_PRODUCT_PROMOTION, createPromotionByCompanies)
    yield takeEvery(ADD_PRODUCT_PROMOTION, addProductPromotion)
    yield takeEvery(REMOVE_PRODUCT_PROMOTION, removeProductPromotion)
    yield takeEvery(FIND_PROMOTION_BY_ID, findPromotionById)
}

export default PromotionsCompaniesSaga
