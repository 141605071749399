import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Col, Row } from "reactstrap"
import "./days_week.css"
import { getDaysWeekByCompanies } from "../store/company/actions"

class WeekDay extends Component {

    constructor(props) {
        super(props);
        this.state = {}

    }

    componentDidMount() {
        this.props.getDaysWeekByCompanies(
            this.props.company_id
        );
    }


    render() {
        const { rows } = this.props;
        return (
            <div className="weekDays-selector">
                {
                    rows && rows.length > 0 &&
                    rows.map((item, index) => {
                        return (
                            <>
                                <input value={item.id} onChange={(e) => {

                                    if (item.is_selected) {
                                        this.props.removeDayWeeks(item)
                                    } else {
                                        this.props.getDayWeeks(item)
                                    }
                                    this.props.rows[index]['is_selected'] = !this.props.rows[index]['is_selected'];
                                    this.props.check_duplicates_weeks(this.props.rows)
                                    
                                }} name={item.name} type="checkbox" id={item.id} className="weekday" />
                                <label htmlFor={item.id}>{item.brand}</label>
                            </>
                        )
                    })}
            </div>
        )
    }
}

WeekDay.propTypes = {
    getDayWeeks: PropTypes.func,
    removeDayWeeks: PropTypes.func,
    check_duplicates_weeks: PropTypes.func,
    getDaysWeekByCompanies: PropTypes.func,
    title: PropTypes.string,
    company_id: PropTypes.any,
    rows: PropTypes.any,
}
const mapStateToProps = state => {
    const {
        loading,
        rows,
        id
    } = state.Company

    return {
        loading,
        rows,
        id
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    getDaysWeekByCompanies
})(WeekDay)
))
