import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Orders from "../pages/Manager/index";
import WhatsApp from "../pages/WhatsApp/index";
import Routes from "../pages/Routes/index";
import Promotions from "../pages/Promotions/index";
import Campaign from "../pages/Campaign/index";
import PDV from "../pages/pos/pdv/index";
import SMFeed from "../pages/SocialMidia/index";

import "../assets/custom-theme.css"

const authProtectedRoutes = [

  { path: "/dashboard", component: Dashboard },
  { path: "/orders", component: Orders },
  { path: "/routes", component: Routes },
  { path: "/pos/pdv", component: PDV },
  { path: "/promotions", component: Promotions },
  { path: "/campaigns", component: Campaign },
  { path: "/whatsapp-bot", component: WhatsApp },
  { path: "/sm-feed", component: SMFeed },

  { path: "/orders", component: Orders },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forget-password", component: ForgetPwd },

];

export { authProtectedRoutes, publicRoutes };
