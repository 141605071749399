import {
    LISTENER_ORDERS_CARD_ID_CHANNEL,
    LISTENER_ORDERS_CARD_ID_CHANNEL_SUCCESS,
    LISTENER_ORDERS_CHANNEL_BY_ID_COMPANY,
    LISTENER_ORDERS_CHANNEL_BY_ID_COMPANY_SUCCESS,
    ORDERS_CHANNEL_ERROR
} from "./actionTypes"


export const findOrderIdChannelListener = params => {
    return {
        type: LISTENER_ORDERS_CARD_ID_CHANNEL,
        payload: {params},
    }
}

export const findOrderIdChannelListenerSuccess = params => {
    return {
        type: LISTENER_ORDERS_CARD_ID_CHANNEL_SUCCESS,
        payload: params,
    }
}

export const findOrderCompanyIdChannelListener = params => {
    return {
        type: LISTENER_ORDERS_CHANNEL_BY_ID_COMPANY,
        payload: {params},
    }
}

export const findOrderCompanyIdChannelListenerSuccess = params => {
    return {
        type: LISTENER_ORDERS_CHANNEL_BY_ID_COMPANY_SUCCESS,
        payload: params,
    }
}

export const channelCreateOrEditOrderError = error => {
    return {
        type: ORDERS_CHANNEL_ERROR,
        payload: error,
    }
}

