import {
    FIND_PAYMENT_METHODS,
    FIND_PAYMENT_METHODS_SUCCESS,
    FIND_PAYMENT_METHODS_API_ERROR,
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    rows: [],
    id: 0,
}

const paymentMethods = (state = initialState, action) => {

    switch (action.type) {
        case FIND_PAYMENT_METHODS:
            state = {
                ...state,
                loading: true
            }
            break
        case FIND_PAYMENT_METHODS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload.data,
                loading: false
            }
            break
        case FIND_PAYMENT_METHODS_API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default paymentMethods
