import React from 'react'
import { CardBody, Card } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Col, Row, Stack } from "react-bootstrap"
import { findProductsByCategory } from "../../../store/catalog/productsByCategory/actions"
import BreadcrumbsCustom from 'components/BreadcrumbCustom'
import ReactDrawer from 'react-drawer';
import Table from './table'
import Autocomplete from 'components/Autocomplete'
import CardProductsSection from './productsSections/index'
import FormBuyCart from './cart/FormBuyCart'

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            products_list: [],
            products_cart: [],
            product: null,
            prod_search: null,
            columns: [
                {
                    id: 1,
                    name: 'PRODUTO',
                    status: 1
                },
                {
                    id: 2,
                    name: 'QTD',
                    status: 1
                },
                {
                    id: 3,
                    name: 'V.Unit',
                    status: 1
                },
                {
                    id: 4,
                    name: 'v.Total',
                    status: 1
                }
            ],


        }
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
        this.getListProds = this.getListProds.bind(this)
        window.addEventListener('storage', function() {
            console.log('storage event ')
        })
    }


    componentDidMount() {
      
    }

    handleAutocomplete(_data, name_entity) {

        if (_data) {
            const { value, name, code, data } = _data

            let newState = {}
            console.log('PDV', data)


            // newState[name] = value
            // newState[name_entity] = code
            newState['product'] = data
            newState['prod_search'] = null
            this.setState(newState)
            //this.setState({ products_list })
        }
    }

    getListProds(products) {

        this.setState({ products_list: products })
        console.log('prods ', products)
        window.localStorage.setItem('pdv', btoa(JSON.stringify(products)))
    }


    render() {
        const { products_list } = this.state
        console.log('products ', products_list)
        return (
            <React.Fragment>
                <div className="page-content">
                    <Row>
                        <Col sm={8}>
                            <Card>
                                <CardBody>
                                    <Row className="align-items-md-center  mb-3">
                                        <Col sm={12} className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <span className="h4 card-title">Ponto de Venda <small className="text-muted h6">Faça o pedido</small></span>
                                            </div>
                                            <div className="text-md-right ms-auto mb-2">
                                                <a onClick={() => {

                                                }} className="btn btn-dark btn-sm text-light">
                                                    <i className="bx bx-sync" />
                                                    {this.props.t("Update")}
                                                </a>
                                            </div>

                                        </Col>
                                        <Col sm={12}>
                                            <hr />
                                        </Col>
                                        <Col sm={12} className="mb-2">
                                            <Autocomplete
                                                onSourceSelect={this.handleAutocomplete}
                                                nameFieldSelect={'company_id'}
                                                limit={10}
                                                inputProps={{
                                                    id: 'company',
                                                    label: "Pesquisar produtos",
                                                    placeholder: "Ex: Coca cola",
                                                    value: this.state.prod_search,
                                                    url: 'search/products'
                                                }} />
                                        </Col>
                                        <Col>
                                            <Row>

                                                <CardProductsSection
                                                    getProducts={this.getListProds}
                                                    row={this.state.product}
                                                />
                                                <Col>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card>
                                <CardBody>

                                    <FormBuyCart />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ReactDrawer
                        open={this.state.open}
                        position={this.state.position}
                        onClose={this.onDrawerClose}
                        noOverlay={this.state.noOverlay}>



                    </ReactDrawer>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
    findProductsByCategory: PropTypes.func,
    rows: PropTypes.any,
}
const mapStateToProps = state => {
    const {
        loading,
        rows,
        id
    } = state.ProductsCategory

    return {
        loading,
        rows,
        id
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    findProductsByCategory
})(Index)
))
// 