import React, {Component} from "react"
import PropTypes from 'prop-types'

import {Card, CardBody, Col, Container, Row} from "reactstrap"
//Import config
import { app } from "../../config"

// Redux
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"

// availity-reactstrap-validation
import {AvField, AvForm} from "availity-reactstrap-validation"

// actions
import {apiError, loginUser} from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import lightlogo from "../../assets/images/logo-light.svg"
import ButtonCustom from "../../components/ButtonCustom";

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {}

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.loginUser(values, this.props.history)

    }

    componentDidMount() {
        this.props.apiError("")
    }

    render() {

        return (
            <React.Fragment>
                <div className="account-pages  pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-dark bg-soft">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-dark p-4">
                                                    <h5 className="text-dark">Bem-Vindo</h5>
                                                    <p>Faça login para continuar no Gestor de Pedidos</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid"/>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="auth-logo">
                                            <Link to="/" className="auth-logo-light">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                      <span className="avatar-title rounded-circle bg-light">
                                                        <img
                                                            src={lightlogo}
                                                            alt=""
                                                            className="rounded-circle"
                                                            height="34"
                                                        />
                                                      </span>
                                                </div>
                                            </Link>
                                            <Link to="/" className="auth-logo-dark">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                      <span className="avatar-title rounded-circle bg-light">
                                                        <img
                                                            src={logo}
                                                            alt=""
                                                            className="rounded-circle"
                                                            height="34"
                                                        />
                                                      </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleValidSubmit}
                                            >

                                                <div className="mb-3">
                                                    <AvField
                                                        name="email"
                                                        label="E-mail"
                                                        className="form-control"
                                                        placeholder="Digite o email"
                                                        type="email"
                                                        required
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <AvField
                                                        name="password"
                                                        label="Senha"
                                                        type="password"
                                                        required
                                                        placeholder="Digite a senha"
                                                    />
                                                </div>

                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customControlInline"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="customControlInline"
                                                    >
                                                        Lembrar de mim
                                                    </label>
                                                </div>

                                                <ButtonCustom
                                                    isLoading={this.props.loading}
                                                    size="sm"
                                                    clazz="btn btn-dark btn-block"
                                                    message_loaging="Aguarde...">
                                                    <button
                                                        className="btn btn-dark btn-block"
                                                        type="submit"
                                                    >
                                                        Entrar
                                                    </button>
                                                </ButtonCustom>


                                                <div className="mt-4 text-center">
                                                    <Link to="/forget-password" className="text-muted">
                                                        <i className="mdi mdi-lock me-1"/>
                                                        Esqueceu sua senha?
                                                    </Link>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        © {new Date().getFullYear()}. Fabricado com muito{" "}
                                        <i className="bx bx-coffee"/> por Insígnia Engenharia de Sofware
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Login.propTypes = {
    apiError: PropTypes.any,
    error: PropTypes.any,
    loading: PropTypes.any,
    history: PropTypes.object,
    loginUser: PropTypes.func,
}

const mapStateToProps = state => {
    const {error, loading} = state.Login
    return {error, loading}
}

export default withRouter(
    connect(mapStateToProps, {loginUser, apiError})(Login)
)
